import React from "react";

const PDFFlowchart = () => {

  return (
    <main>
      <div className="pdfWrap"> 
      <div className="pdfHead">
          <h1>화면흐름도 (Screen Flow)</h1>
          <div>페이지 번호 : <span>3</span></div>
        </div>
        <div className="pdfCon">
          <div className="d-flex gap50 justify-content-center">
            <div>
              <h2>플로우차트 기호</h2>
              <div>
                <img src="../img/FlowChart.png" alt="플로우차트 기호 이미지" />
              </div>
            </div>
            <div>
              <h2>회원가입 플로우</h2>
              <div className="flowchartImg">
                <img src="../img/flowchart_join.png" alt="회원가입 플로우차트" />
              </div>
            </div>
          </div>
        </div>
        <div className="copy">Copyright &copy; 2024 mBaaS all right reserved</div>
      </div>
    </main>
  )

}
export default PDFFlowchart