import React from 'react'
import { Outlet } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import { appProcessState } from '../../recoil/checkListState';
import { useToastPopup } from '../../hooks/useToastPopup';

/**
 * 기본적은로 모든 컴포넌트를 감싸는 Layout
 * @returns 
 */
const TotalLayout = () => {
    const process = useRecoilValue(appProcessState);
    const toastHook = useToastPopup();

    return (
        <>
            {/* 앱생성 프로세스 .on  */}
            <div className={`progress ${process.isBuilding ? "on" : ""}`}>
                <div className='d-flex gap10 align-items-center justify-content-center'>
                    <p>{process.message}</p>  {/* ~ 하는 중 퍼센트마다 무슨 작업중이다는 느낌. */}
                    <img src="../img/cycle.png" alt="로딩이미지" />
                </div>
                <progress className='progress-bar' id="progress" value={process.percent / 100} />
            </div>
            {
                toastHook.getToastPopup()
            }
            <Outlet />
        </>
    )
}

export default TotalLayout