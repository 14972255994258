import { GET_METHOD } from "../../const/const";
import { APP_USE_DEFINE, BASIC_SIMILAR_APP_DEFINE, CUSTOM_CAPABILITY_LIST_DEFINE, CUSTOM_SIMILAR_APP_DEFINE, CUSTOM_TEMPLATE_DEFINE } from "../../const/defineName";

export const checklistDefine = {
    [APP_USE_DEFINE]: {
        method: GET_METHOD,
        url: `/template`
    },
    [CUSTOM_SIMILAR_APP_DEFINE]: {
        method: GET_METHOD,
        url: "/template/tag/ctg"
    },
    [CUSTOM_TEMPLATE_DEFINE]: {
        method: GET_METHOD,
        url: "/template/detail"
    },
    [CUSTOM_CAPABILITY_LIST_DEFINE]: {
        method: GET_METHOD,
        url: "/template/capability"
    },
    [BASIC_SIMILAR_APP_DEFINE]: {
        method: GET_METHOD,
        url: "/template/tag/tmp"
    }
}