import { appDefine } from "./appDefine";
import { checklistDefine } from "./checklistDefine";
import { memberDefine } from "./memberDefine";
import { planDefine } from "./planDefine";

export const define = {
    ...memberDefine,
    ...checklistDefine,
    ...planDefine,
    ...appDefine
}