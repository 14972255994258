import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom';
import { usePreventRefresh } from '../../hooks/usePreventRefresh';

/**
 * 
 * @returns 앱생성 페이지들을 감싸는 Layout
 */
const CheckListLayout = () => {
    const location = useLocation();
    // 새로고침시 경고문구를 띄어주는 Hook
    usePreventRefresh()
    // 화면 표시 애니메이션

    return (
        <div className={"checkListReWrap"}>
            <header>
                <Link to={"/"}><button className='back'><img src="../img/icon_backB.png" alt="뒤로가기 아이콘" />메인홈</button></Link>
                {/* 앱성성시 상단에 앱진행도를 표현해주기위한 로직 */}
                <ul className={`stepWrap step${Number((location.pathname.split("/")[2]))+1}`}>
                    <li className={location.pathname.includes("/checklist") ? 'on' : ""}>
                        <div></div>
                        <h2>정보입력</h2>
                    </li>
                    <li className={location.pathname.split("/")[2] >= 1 ? 'on' : ""}>
                        <div></div>
                        <h2>앱 유형</h2>
                    </li>
                    <li className={location.pathname.split("/")[2] >= 2 ? 'on' : ""}>
                        <div></div>
                        <h2>유사앱 추천</h2>
                    </li>
                    <li className={location.pathname.split("/")[2] >= 3 ? 'on' : ""}>
                        <div></div>
                        <h2>앱 빌드</h2>
                    </li>
                    {/* <li className={location.pathname.split("/")[2] >= 4 ? 'on' : ""}>
                        <div></div>
                        <h2>앱 인증</h2>
                    </li> */}
                </ul>
            </header>
            <Outlet />
        </div>
    )
}

export default CheckListLayout