import React, { useEffect, useState } from "react";
import Input from "../atom/Input";
import { Form, redirect, useActionData, useSearchParams } from "react-router-dom";
import { fn_Debug, makeParamsFromFormData } from "../../util/util";
import { POST_METHOD } from "../../const/const";
import { resetPwQuery } from "../../queries/memberQueries";

/**
 * router.js에 등록시키는 action. POST 요청이 들어올 시 해당 액션이 실행된다.
 * @returns error를 return 시킴으로써 useActionData()를 통해 action에서 발생한 에러를 받을 수 있다.
 */
export const resetPwAction = 
  (queryClient) => async({ request }) => {
    const param = makeParamsFromFormData(await request.formData());
    const error = {}

    if(!param.newPassword) {
      return;
    }

    const response = await queryClient.fetchQuery(resetPwQuery(param));

    fn_Debug(response);

    if(response.result === "SUCCESS") {
      return redirect("/findpwCom");
    } else {
      error.commonError = response.message;
    }

    return error;
  }


const FindPasswordRe = () => {
  const [searchParams,] = useSearchParams();
  const [showPw, setShowPw] = useState(false);
  const [showPwRe, setShowPwRe] = useState(false);
  const errors = useActionData();

  useEffect(() => {
  
  }, [searchParams])

  return (
    <main>
      <div className="joinWrap">
        <div className="joinCon">
          <h1>Find Password</h1>
          <p className="center bold">비밀번호를 재설정해주세요. </p>
          <Form method={POST_METHOD}>
            <Input type={"hidden"} name={"email"} value={searchParams.get("email")}/>
            <Input type={"hidden"} name={"code"} value={searchParams.get("code")}/>
            <div>
              <div className="sbox">
                <h4>비밀번호</h4>
                <Input
                  type={showPw ? "text" : "password"} 
                  name="newPassword"
                  placeholder="비밀번호를 입력해주세요."
                />
                <button 
                  className={showPw ? "view off" : "view"} 
                  tabIndex={-1}
                  onClick={(e) => {e.preventDefault(); setShowPw(!showPw)}}></button>
                <p>영문+숫자+특수문자 8자 이상(특수문자: !@#$%^&*)</p>
                {/* view 버튼 클릭시 .off 붙엇다가 없어졋다가 */}
              </div>
              <div className="sbox">
                <h4>비밀번호 재확인</h4>
                <Input
                  type={showPwRe ? "text" : "password"} 
                  name="reNewPassword"
                  placeholder="비밀번호를 다시 입력해주세요."
                />
                <button 
                  className={showPwRe ? 'view off' : "view" } tabIndex={-1}
                  onClick={(e) => {e.preventDefault(); setShowPwRe(!showPwRe)}}></button>
                {/* <p className='warning'>비밀번호가 일치하지 않습니다.</p> */}
              </div>
            </div>
            <button className="mt50">비밀번호 재설정</button>
            <Input type={"hidden"} error={errors?.commonError}/>
          </Form>
          <div className="or">
            <p className="center">아이디를 잃어버리셨나요?</p>
            <button>아이디 찾기</button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default FindPasswordRe;
