import React from 'react'
import { Link } from 'react-router-dom'

const JoinComplete = () => {
    return (
        <main>
            <div className="joinWrap bg1">
                <div className="joinCon">
                    <div>
                        <h1>Join</h1>
                        <ul>
                            <li>
                                <div>1</div>
                                <p>약관동의</p>
                            </li>
                            <li className='etc'></li>
                            <li>
                                <div>2</div>
                                <p>추가정보 입력</p>
                            </li>
                            <li className='etc'></li>
                            <li>
                                <div className='on'>3</div>
                                <p>회원가입 완료</p>
                            </li>
                        </ul>
                        <div>
                            <div className='joincomplete'>
                                <h2>회원가입을 축하드립니다! </h2>
                                <p>로그인을 하고 나만의 앱을 생성해 보세요.</p>
                            </div>
                            <Link to={"/login"}><button>로그인하러가기</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default JoinComplete