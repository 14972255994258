import React, { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Header from '../components/header';
import Footer from '../components/footer';
import { Outlet, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { appProcessState } from '../../recoil/checkListState';
import { useToastPopup } from '../../hooks/useToastPopup';
import { toastPopupState } from '../../recoil/toastPopupState';

/**
 * 로그인 이후 페이지들을 감싸는 Layout
 * @returns 
 */
const Layout = () => {
    /* 모바일 메뉴 */
    const [isToggle, setIsToggle] = useState(false);
    const location = useLocation();
    const onClickToggleBtn = () => {
        setIsToggle(!isToggle)
    }

    // 화면 표시 애니메이션
    useEffect(() => {
        AOS.init();
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [location.pathname])

    return (
        <div className={isToggle ? 'scrollLock' : ''}>
            <Header isToggle={isToggle} onClickToggleBtn={onClickToggleBtn} />
            {/* router에 children으로 등록되어있는 컴포넌트들을 랜더링 시켜줌 */}
            <Outlet /> 
            <Footer />
            {/* <Link to={"inquiry"}>
            <button id="inquiryBtn"><img src={`${process.env.PUBLIC_URL}/img/icon_inquiry.png`} alt="문의하기 이미지"/></button>
        </Link> */}
            {/* <button onClick={backToTop} id="topBtn" title="위로 가기"><img src={`${process.env.PUBLIC_URL}/img/icon_arrow_topW.png`} alt="탑버튼 이미지"/></button> */}
        </div>
    )
}

export default Layout