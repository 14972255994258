import React, { useEffect } from 'react'
import { Link, Outlet, useLoaderData, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { planTemplateListQuery } from '../../queries/planQueries';
import { useToastPopup } from '../../hooks/useToastPopup';

export const planLoader =
  (queryClient) => async ({ request }) => {
    const url = new URL(request.url);
    const appCode = url.searchParams.get("appCode");

    // if(!queryClient.getQueryData(planTemplateListQuery().queryKey)) {
    //   return await queryClient.fetchQuery(planTemplateListQuery());
    // }
    // return queryClient.getQueryData(planTemplateListQuery().queryKey);
    return await queryClient.fetchQuery(planTemplateListQuery({ appCode: appCode }));
  }

const PlanLayout = () => {
    const location = useLocation();
    const [searchParams,] = useSearchParams();
    const { data } = useLoaderData();
    const navigate = useNavigate();
    // layout이 랜더링 되면 불러온 데이터에서 가장 첫 템플릿으로 바로 이동
    // 현재 templateProposalCode는 사용하는 곳 없음. 추후에 사용가능성이 있어서 미리 전달.
    // 쿼리스트링으로 해당 인덱스를 넘겨 Outlet을 통해 해당 페이지의 데이터를 전달
    useEffect(() => {
        navigate(`/plan/${data.appProposalListDto[0].templateProposalCode}&i=${0}?appCode=${searchParams.get("appCode")}`, {replace: true})
    }, [])

    const openPDFPopup = () => {
        const popup = window.open(`/pdf?appCode=${searchParams.get("appCode")}`, "기획서", "popup=yes")
    }
    
    return (
        <>
            <div className="appplanWrap">
                <div className="sidebarWrap">
                    <div className="sidebar">
                        <button className="back d-flex align-items-center gap10">
                            <Link to={"/info"}><img src={`${process.env.PUBLIC_URL}/img/icon_back.png`} alt="뒤로가기 아이콘" /></Link>
                            온라인 기획서
                        </button>

                        <button className="guideBtn">기획서 가이드 보러가기</button>

                        <div>
                            <h2>개발 문서</h2>
                            <ul>
                                <Link to={`development?appCode=${searchParams.get("appCode")}`}>
                                    <li className={location.pathname.includes("development") ? "tab_item on" : "tab_item"}>개발 가이드</li>
                                </Link>
                            </ul>
                        </div>
                        <div>
                            <h2>페이지 목록</h2>
                            <ul className="pageList"> {/* tab menu */}
                                {
                                    data.appProposalListDto.map((p, idx) => (
                                        <Link to={`/plan/${p.templateProposalCode}?i=${idx}&appCode=${searchParams.get("appCode")}`} key={p.templateProposalCode}>
                                            <li 
                                                className={location.pathname.includes(p.templateProposalCode) ? "tab_item on" : "tab_item"}>
                                                    {p.appProposalName}
                                            </li>
                                        </Link>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="sidebarBtn">
                        <button onClick={openPDFPopup} className="pdfBtn">PDF 파일 다운로드<img src={`${process.env.PUBLIC_URL}/img/icon_download.png`} alt="다운로드이미지" /></button>
                        <button>제출하기</button>
                    </div>
                </div>
                
                <Outlet context={ { data: data.appProposalListDto[searchParams.get("i") ?? 0], appCode: data.appCode } }/>
            </div>
        </>
    )
}

export default PlanLayout