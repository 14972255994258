import React, { useEffect, useState } from 'react'
import { Form, Link, useActionData } from 'react-router-dom';
import Input from '../atom/Input';
import { fetchData } from '../../util/util';
import { FIND_SMS_AUTH_DEFINE, FINE_SMS_AUTH_CHECK_DEFINE } from '../../const/defineName';

// ID 찾기는 Action 패턴 예외(캐싱이 필요하지 않고 요청에 비해 다른 작업량이 더 많아짐)
const FindId = () => {
    const [minutes, setMinutes] = useState(parseInt(3));
    const [seconds, setSeconds] = useState(parseInt(0));
    const [startTimer, setStartTimer] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [error, setError] = useState({})
    const [isAuth, setIsAuth] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const [token, setToken] = useState("");
    const [findEmail, setFindEmail] = useState("");

    const sendAuthMail = async (e) => {
        e.preventDefault();
        setMinutes(3);
        setSeconds(0);

        // 아이디찾기 이름 및 핸드폰 유효성 검사
        if (!name) {
            setError({
                name: "이름을 입력해주세요."
            })
            return;
        } 
        else if(!phone) {
            setError({
                phone: "전화번호를 입력해주세요."
            })
            return;
        }
        else if(phone.includes("-")) {
            setError({
                phone: "전화번호 형식을 확인해주세요."
            })
            return;
        }
        else {
            setError({});
        }

        setStartTimer(true);
        setIsSend(true);
        const response = await fetchData(FIND_SMS_AUTH_DEFINE, { phone: phone, name: name });
        
        if (response.result === "FAIL") {
            setStartTimer(false);
            setMinutes(3);
            setSeconds(0);
            setError({
                commonError: response.message
            })
        } else {
            setToken(response.data.token)
        }
    }

    // 문자전송을 성공했을때 넘어오는 token을 request header에 넣어줘야함. Email-Authorization
    const checkAuthMail = async (e) => {
        e.preventDefault();
        setError({})
        const response = await fetchData(FINE_SMS_AUTH_CHECK_DEFINE, { code: authCode }, { "Email-Authorization":  `Bearer ${token}`});

        if (response.result === "SUCCESS") {
            setStartTimer(false);
            setMinutes(3);
            setSeconds(0);
            setIsAuth(true);
            setFindEmail(response.data.email);
            setError({
                commonError: response.message
            })
        } else {
            setError({
                code: response.message
            })
        }
    }

    useEffect(() => {
        if (!startTimer) return;

        const countdown = setInterval(() => {
            if (parseInt(seconds) > 0) {
                setSeconds(parseInt(seconds) - 1);
            }
            if (parseInt(seconds) === 0) {
                if (parseInt(minutes) === 0) {
                    clearInterval(countdown);
                } else {
                    setMinutes(parseInt(minutes) - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds, startTimer]);

    return (
        <main>
            <div className="joinWrap">
                <div className="joinCon">
                    <div>
                        <h1>Find ID</h1>
                        <p className='center bold'>가입시 등록한 휴대폰 번호를 인증해 주세요.</p>
                        <div>
                            <div>
                                <div className='sbox'>
                                    <h4>이름</h4>
                                    <Input 
                                        type="text" 
                                        name={"name"} 
                                        onChange={(e) => {setName(e.target.value)}} 
                                        value={name}
                                        placeholder='이름을 입력해주세요.' />
                                </div>
                                <div className='emailWrap sbox'>
                                    <h4>휴대폰 번호</h4>
                                    <div className='d-flex gap10'>
                                        <Input
                                            type="text"
                                            name={"phone"}
                                            onChange={(e) => {setPhone(e.target.value)}}
                                            value={phone}
                                            placeholder='휴대폰 번호를 입력해주세요.(- 제외) ' />
                                        <button className='resend'>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</button>
                                        <button className='resend'></button>
                                        <button onClick={isSend ? (e) => {e.preventDefault()} : (e) => {sendAuthMail(e)}}>인증번호 발송</button>
                                    </div>
                                    <div className='d-flex gap10'>
                                        <Input
                                            type="text"
                                            placeholder='인증번호를 입력해주세요.'
                                            name={"code"}
                                            value={authCode}
                                            onChange={(e) => {setAuthCode(e.target.value)}} />
                                        {isSend ? <button className='resend' onClick={(e) => {sendAuthMail(e)}}>재발송</button> : ""}
                                        <button onClick={(e) => {checkAuthMail(e)}}>인증번호 확인</button>
                                    </div>
                                </div>
                                { error.commonError ? <div>{ error.commonError }</div> : "" }
                                { findEmail ? <div>{findEmail}</div> : "" }
                                <button>아이디 찾기</button>
                            </div>
                            <div className='or'>
                                <p className='center'>비밀번호를 잃어버리셨나요?</p>
                                <Link to={"/findpw"}><button>비밀번호 찾기</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default FindId