import React from 'react'
import { Link, useLoaderData, useRevalidator, useSearchParams } from 'react-router-dom';

/**
 * 
 * @returns 화면에 나오는 "교회"라는 문구는 현재 하드코딩 되어있음. 앱유형을 파라미터로 넘기려고하니 인코딩 에러남.
 */
const CheckList2 = () => {
  const [searchParams,] = useSearchParams();
  const { data } = useLoaderData();
  const revalidator = useRevalidator();

  return (
    <div className='checkListReWrap'>
      <main className='checkListCon'>
        <div className='wrap'>
          <div className='centerCon'>
            <h2>교회와/과 비슷한 앱 정보가 궁금하셨나요?<br />Ai가 추천해드리는 유사앱 정보를 참고해보세요.</h2>
            <ul className='similarWrap'>
              {
                data.map(d => (
                  <li key={d.code}>
                    <img src={d.icon} alt="앱아이콘 이미지" />
                    <h3>{d.name}</h3>
                    <p>{d.content}</p>
                    <div className='link'>
                      <button>앱 보러가기</button>
                      <img src="../img/icon_linkB.png" alt="링크 아이콘" />
                    </div>
                  </li>
                ))
              }
            </ul>
            <button className='moreBtn' onClick={() => { revalidator.revalidate() }}>더보기 <img src="../img/icon_refreshB.png" alt="새로고침 아이콘" /></button>
          </div>
        </div>
      </main>
      <footer>
        <div className='btnWrap'>
          <Link to={-1}><button className='off'>이전</button></Link>
          <Link to={`/checklist/3?${searchParams.toString()}`}><button>다음</button></Link>
        </div>
      </footer>
    </div>
  )
}


export default CheckList2