import { APP_USE_DEFINE, BASIC_SIMILAR_APP_DEFINE, CUSTOM_CAPABILITY_LIST_DEFINE, CUSTOM_SIMILAR_APP_DEFINE, CUSTOM_TEMPLATE_DEFINE } from "../const/defineName"
import { fetchData, fetchDataPathVar } from "../util/util"

export const appUseQuery = () => ({
    queryKey: ["appUses"],
    queryFn: async() => {
        const json = await fetchData(APP_USE_DEFINE)
        return json;
    }
})

export const customSimilarAppQuery = (param) => ({
    queryKey: ["customSimilarApp"],
    queryFn: async() => {
        const json = await fetchData(CUSTOM_SIMILAR_APP_DEFINE, param);
        return json;
    }
})

export const customTemplateQuery = (param) => ({
    queryKey: ["customTemplate"],
    queryFn: async() => {
        const json = await fetchDataPathVar(CUSTOM_TEMPLATE_DEFINE, param);
        return json;
    }
})

export const customCapabilityListQuery = (param) => ({
    queryKey: ["customCapabilityList"],
    queryFn: async() => {
        const json = await fetchData(CUSTOM_CAPABILITY_LIST_DEFINE, param);
        return json;
    }
})

export const basicSimilarAppQuery = (param) => ({
    queryKey: ["basicSimilarApp"],
    queryFn: async() => {
        const json = await fetchData(BASIC_SIMILAR_APP_DEFINE, param);
        return json;
    }
})