import React from 'react'

const MyPageUseroutPopup = ({ isOpen, setIsOpen }) => {
    return (
        <div className="popupWrap" style={{display: isOpen ? "block" : "none"}}>
            <div className="popupBox">
                <div className="popTit d-flex justify-content-between align-items-center">
                    <h2>탈퇴하기</h2>
                    <div className="closeBtn" onClick={() => {setIsOpen(false)}}><img src="../../img/icon_closeB.png" alt="닫기 버튼"/></div>
                </div>
                <div>
                    <p>정말 탈퇴하시나요? <br />
                        탈퇴하시면 모든 데이터가 삭제되어 복구하실 수 없습니다. </p>
                </div>
                <div className="agreeWrap d-flex align-items-center gap10">
                    <input type="checkbox" name="" id=""/>
                        <p>안내사항을 확인하고 동의하십니까?</p>
                </div>
                <div className="popBtnWrap d-flex align-items-center justify-content-center gap20">
                    <button onClick={() => {setIsOpen(false)}}>취소</button>
                    <button className="on">확인</button>
                </div>
            </div>
        </div>
    )
}

export default MyPageUseroutPopup