import React, { useEffect, useRef } from 'react'
import Bookcover from './bookcover'
import PDFFlowchart from './pdf_flowchart'
import PDFHistory from './pdf_history'
import PDFMessage from './pdf_message'
import PDFScreenflow from './pdf_screenflow'
import PDFScreenplan from './pdf_screenplan'
import generatePDF, { Resolution } from 'react-to-pdf'
import { getMyInfoQuery } from '../../../queries/memberQueries'
import { getAppDetailInfo } from '../../../queries/appQueries'
import { useLoaderData } from 'react-router-dom'
import { planTemplateListQuery } from '../../../queries/planQueries'
import { useQueries } from '@tanstack/react-query'

export const pdfLoader = 
    (queryClient) => async({ request }) => {
        const url = new URL(request.url);
        const appCode = url.searchParams.get("appCode");
        let myInfo = {};

        // 앱정보에 memberCode가 필요해서 내 정보가져오기 호출
        if(!queryClient.getQueryData(getMyInfoQuery())) {
            myInfo = await queryClient.fetchQuery(getMyInfoQuery());
        } else {
            myInfo = queryClient.getQueryData(getMyInfoQuery())
        }

        if(!queryClient.getQueryData(getAppDetailInfo({ memberCode: myInfo.data.memberCode, appCode: appCode, }))) {
            await queryClient.fetchQuery(getAppDetailInfo({ memberCode: myInfo.data.memberCode, appCode: appCode, }));
        }

        if(!queryClient.getQueryData(planTemplateListQuery({ appCode: appCode }))) {
            await queryClient.fetchQuery(planTemplateListQuery({ appCode: appCode }));
        }
        
        return {
            memberCode: myInfo.data.memberCode, 
            appCode
        };
    }

const Pdf = () => {
    const ref = useRef();
    const options = { 
        filename: 'page.pdf'
        , method: 'open'
        , resolution: Resolution.LOW
        , page : {
            format: 'A4',
            orientation: "landscape"
        }
        // , overrides: {
        //     pdf: {
        //         compress: true
        //     },
        //     canvas: {
        //         useCORS: true
        //     }
        // }
    };
    const { appCode, memberCode } = useLoaderData();
    const [appInfo, templateData] = useQueries({
        queries: [
            getAppDetailInfo({ memberCode: memberCode, appCode: appCode, }), 
            planTemplateListQuery({ appCode: appCode })
        ]
    })

    useEffect(async() => {
        const createPDF = () => {
            const promise = new Promise((resolve, reject) => {
                generatePDF(ref, options);
            })
    
            return promise
        }
        await createPDF();
        window.close();
    }, [])

    const height = "90%"
    return (
        <>
            {/* <h1 onClick={() => toPDF()}>Down</h1> */}
            <div id="pdf-id" ref={ref}>
                <Bookcover appName={appInfo.data.data.appName}/>
                <div style={{height: height}}></div>
                <PDFHistory />
                <div style={{height: height}}></div>
                <PDFScreenflow />
                <div style={{height: height}}></div>
                <PDFFlowchart />
                <div style={{height: height}}></div>
                {
                    templateData.data.data.appProposalListDto.map((d, idx) => (
                        <PDFScreenplan 
                            key={idx}
                            name={d.appProposalName}
                            content={d.appProposalContent}
                            img={d.appProposalImageUrl}
                            pageNum={idx+4}
                            modi={d.modificationRequirements ?? ""}
                        />
                    ))
                }
                <div style={{height: height}}></div>
                <PDFMessage pageNum={templateData.data.data.appProposalListDto.length+4}/>
            </div>
        </>
    )
}

export default Pdf