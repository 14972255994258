import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ACCESS_TOKEN } from '../../const/const';

/**
 * 소셜로그인 이후 로그인 로직을 처리하기위한 페이지
 * @returns 
 */
const SocialLoginProc = () => {
    const [searchParams,] = useSearchParams();
    const navigator = useNavigate();

    useEffect(() => {
        localStorage.setItem(ACCESS_TOKEN, searchParams.get("at"));
        navigator("/", {replace: true})
    }, [])

    return (
        <></>
    )
}

export default SocialLoginProc