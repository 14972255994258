import { JOIN_DEFINE, LOGIN_DEFINE, MEMBER_APP_DEFINE, MY_INFO_DEFINE, MY_INFO_UPDATE_DEFINE, RESET_PW_DEFINE, SOCIAL_JOIN_DEFINE } from "../const/defineName";
import { fetchData } from "../util/util";

export const loginQuery = (param) => ({
    queryKey: ["login"],
    queryFn: async() => {
        const json = await fetchData(LOGIN_DEFINE, param);
        return json;
    }
})

export const getMyInfoQuery = () => ({
    queryKey: ["myInfo"],
    queryFn: async() => {
        const json = await fetchData(MY_INFO_DEFINE);
        return json;
    }
})

export const updateMyInfoQuery = (param) => ({
    queryKey: ["updateMyInfo"],
    queryFn: async() => {
        const json = await fetchData(MY_INFO_UPDATE_DEFINE, param);
        return json;
    }
})

export const joinMemberQuery = (param) => ({
    queryKey: ["join"],
    queryFn: async() => {
        const json = await fetchData(JOIN_DEFINE, param);
        return json;
    }
})

export const memberAppQuery = () => ({
    queryKey: ["memberApp"],
    queryFn: async() => {
        const json = await fetchData(MEMBER_APP_DEFINE);
        return json;
    }
})

export const socialJoinQuery = (param) => ({
    queryKey: ["socialJoin"],
    queryFn: async() => {
        const json = await fetchData(SOCIAL_JOIN_DEFINE, param);
        return json;
    }
})

export const resetPwQuery = (param) => ({
    queryKey: ["resetPw"],
    queryFn: async() => {
        const json = await fetchData(RESET_PW_DEFINE, param);
        return json;
    }
})