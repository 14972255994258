import React, { useEffect, useState } from 'react'
import { Form, redirect, useActionData, useNavigate, useSearchParams } from 'react-router-dom';
import { fetchData, fn_Debug, makeParamsFromFormData } from '../../util/util';
import { AUTH_MAIL_DEFINE, SEND_MAIL_DEFINE } from '../../const/defineName';
import Input from '../atom/Input';
import { PHONE_REX, POST_METHOD, PW_REX } from '../../const/const';
import { joinMemberQuery } from '../../queries/memberQueries';

// 회원가입시 유효성검사
const checkValid = (param) => {
    let key;
    const error = {}
    const suffix = "필수값 입니다.";
    for (key in param) {
        if (!param[key]) {
            let msg = "";
            switch (key) {
                case "memberEmail":
                    msg = "이메일은";
                    error.memberEmail = msg + suffix
                    return error;
                case "memberPassword":
                    msg = "비밀번호는";
                    error.memberPassword = msg + suffix
                    return error;
                case "memberName":
                    msg = "이름은";
                    error.memberName = msg + suffix
                    return error;
                case "memberPhone":
                    msg = "사업자전화번호는";
                    error.memberPhone = msg + suffix
                    return error;
                case "businessType":
                    msg = "사업자형태는";
                    error.businessType = msg + suffix
                    return error;
                default:
                    break;
            }
        }
    }

    if (!PW_REX.test(param["memberPassword"])) {
        error.memberPassword = "비밀번호 형식이 맞지 않습니다.";
    }

    if (param.memberPassword !== param.reMemberPassword) {
        error.memberPassword = "비밀번호가 일치하지 않습니다.";
    }
    if (!PHONE_REX.test(param.memberPhone)) {
        error.memberPhone = "전화번호 형식을 확인해주세요.('-'제외)";
    }
    if (!param.code) {
        error.code = "이메일 인증을 진행해주세요";
    }

    return error;
}

/**
 * router.js에 등록시키는 action. POST 요청이 들어올 시 해당 액션이 실행된다.
 * @returns redirect를 return 시킴으로써 해당 URL로 바로 이동시킨다.
 */
export const joinAction = 
    (queryClient) => async({request}) => {
        const param = makeParamsFromFormData(await request.formData());

        const error = checkValid(param);

        if(!error) {
            return error
        }

        const response = await queryClient.fetchQuery(joinMemberQuery(param));

        fn_Debug(response);

        if(response.result === "SUCCESS") {
            return redirect("/joinc")
        } else {
            error.commonError = response.message;
        }

        return error
    }

const JoinInput = () => {
    const navigator = useNavigate();
    const [searchParams,] = useSearchParams();
    const [minutes, setMinutes] = useState(parseInt(3));
    const [seconds, setSeconds] = useState(parseInt(0));
    const [startTimer, setStartTimer] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [error, setError] = useState({})
    // 인증이 진행되었는지 확인
    const [isAuth, setIsAuth] = useState(false);
    // 메일이 최초로 발송되었는지 확인
    const [isSend, setIsSend] = useState(false);
    // 비밀번호 보여주기
    const [showPw, setShowPw] = useState(false);
    const [showPwRe, setShowPwRe] = useState(false);
    const errors = useActionData();

    /**
     * 메일 발송시 타이머 작동 hook
     */
    useEffect(() => {
        if (!startTimer) return;

        const countdown = setInterval(() => {
            if (parseInt(seconds) > 0) {
                setSeconds(parseInt(seconds) - 1);
            }
            if (parseInt(seconds) === 0) {
                if (parseInt(minutes) === 0) {
                    clearInterval(countdown);
                } else {
                    setMinutes(parseInt(minutes) - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds, startTimer]);

    /**
     * 인증 이메일 발송 함수
     * @param {*} e 이벤트 매개변수
     * @returns 
     */
    const sendAuthMail = async (e) => {
        e.preventDefault();
        setMinutes(3);
        setSeconds(0);
        if (!userEmail) {
            setError({
                userEmail: "이메일을 입력해주세요."
            })
            return;
        } else {
            setError({});
        }

        setStartTimer(true);
        setIsSend(true);
        const response = await fetchData(SEND_MAIL_DEFINE, { memberEmail: userEmail });
        if (response.result === "FAIL") {
            setStartTimer(false);
            setMinutes(3);
            setSeconds(0);
        }
    }

    /**
     * 인증이메일 확인 함수
     * @param {*} e 이벤트 매개변수
     */
    const checkAuthMail = async (e) => {
        e.preventDefault();
        setError({})
        const response = await fetchData(AUTH_MAIL_DEFINE, { memberEmail: userEmail, code: authCode });

        if (response.result === "SUCCESS") {
            setStartTimer(false);
            setMinutes(3);
            setSeconds(0);
            setIsAuth(true);
        } else {
            setError({
                code: response.message
            })
        }
    }

    return (
        <main>
            <div className="joinWrap bg2">
                <div className="joinCon">
                    <div>
                        <h1>Join</h1>
                        <ul>
                            <li>
                                <div>1</div>
                                <p>약관동의</p>
                            </li>
                            <li className='etc'></li>
                            <li>
                                <div className='on'>2</div>
                                <p>추가정보 입력</p>
                            </li>
                            <li className='etc'></li>
                            <li>
                                <div>3</div>
                                <p>회원가입 완료</p>
                            </li>
                        </ul>
                        <div>
                            <Form method={POST_METHOD}>
                                <div>
                                    <h3>필수사항</h3>
                                    <ul className='list'>
                                        <input type='hidden' name='memberMarketingAgree' defaultValue={searchParams.get("mm") != "undefined" ? "Y" : "N"} />
                                        <input type='hidden' name='memberServiceAgree' defaultValue={"Y"} />
                                        <input type='hidden' name='memberProfileAgree' defaultValue={"Y"} />
                                        {/* <li>
                                            <label htmlFor="user01">
                                                <input type="radio" name="businessType" id="user01"  />
                                                <p>개인</p>
                                            </label>
                                        </li> */}
                                        <li>
                                            <label htmlFor="user03">
                                                <input type="radio" name="businessType" id="user03" defaultValue={"PRIVATE"} defaultChecked/>
                                                <p>개인사업자</p>
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="user02">
                                                <input type="radio" name="businessType" id="user02" defaultValue={"FOUNDATION"} />
                                                <p>예비창업자</p>
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="user04">
                                                <input type="radio" name="businessType" id="user04" defaultValue={"INCORPORATE"} />
                                                <p>법인사업자</p>
                                            </label>
                                        </li>
                                    </ul>
                                    <div className='emailWrap sbox'>
                                        <h4>이메일 주소</h4>
                                        <div className='d-flex gap10'>
                                            <Input
                                                type="email"
                                                value={userEmail}
                                                onChange={(e) => { setUserEmail(e.target.value) }}
                                                placeholder='이메일 주소를 입력해주세요. '
                                                name={"memberEmail"}
                                                readOnly={isAuth} />
                                            <button className='resend'>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</button>
                                            <button onClick={isSend ? () => { } : (e) => {sendAuthMail(e)}}>이메일 인증</button>
                                        </div>
                                        { isSend ? <span className='f14'>인증번호 발송완료</span> : "" }
                                        <div className='d-flex gap10'>
                                            <Input
                                                type="text"
                                                value={authCode}
                                                onChange={(e) => { setAuthCode(e.target.value) }}
                                                placeholder='인증번호를 입력해주세요. '
                                                readOnly={isAuth}
                                                name={"code"} />
                                            {isSend ? <button className='resend resend2' onClick={sendAuthMail}>재발송</button> : ""}
                                            <button onClick={(e) => {checkAuthMail(e)}}>확인</button>
                                        </div>
                                        { isAuth ? <span className='f14'>인증이 완료되었습니다.</span> : "" }
                                        <div className='warning'>{error.userEmail || errors?.memberEmail}</div>
                                        <div className='warning'>{error.code || errors?.code}</div>
                                        <div className='warning'>{errors?.commonError}</div>
                                        {/* <Input type={"hidden"} error={error.userEmail || errors?.memberEmail}/>
                                        <Input type={"hidden"} error={error.code || errors?.code} />
                                        <Input type={"hidden"} error={errors?.commonError}/> */}
                                    </div>
                                    <div className='sbox'>
                                        <h4>비밀번호</h4>
                                        <Input 
                                            type={showPw ? "text" : "password"} 
                                            name='memberPassword' 
                                            placeholder='비밀번호를 입력해주세요.'/>
                                        <button 
                                            className={showPw ? "view off" : "view"} 
                                            tabIndex={-1}
                                            onClick={(e) => {e.preventDefault(); setShowPw(!showPw)}}></button>
                                        <p>영문+숫자+특수문자 8자 이상(특수문자: !@#$%^&*)</p>
                                        {/* view 버튼 클릭시 .off 붙엇다가 없어졋다가 */}
                                    </div>
                                    <div className='sbox'>
                                        <h4>비밀번호 재확인</h4>
                                        <input 
                                            type={showPwRe ? "text" : "password"} 
                                            name='reMemberPassword' 
                                            placeholder='비밀번호를 다시 입력해주세요.'/>
                                        <button 
                                            className={showPwRe ? 'view off' : "view" } tabIndex={-1}
                                            onClick={(e) => {e.preventDefault(); setShowPwRe(!showPwRe)}}></button>
                                        <div className='warning'>{errors?.memberPassword}</div>
                                    </div>
                                    <div className='sbox'>
                                        <h4>이름</h4>
                                        <Input 
                                            type="text" 
                                            name='memberName' 
                                            placeholder='이름을 입력해주세요.' 
                                            error={errors?.memberName}/>
                                    </div>
                                    <div className='sbox'>
                                        <h4>연락처</h4>
                                        <Input 
                                            type="text" 
                                            name='memberPhone' 
                                            placeholder='휴대폰 번호를 입력해주세요. (- 제외)' 
                                            error={errors?.memberPhone}/>
                                    </div>
                                    <div className='btnWrap'>
                                        <button className='off' onClick={(e) => {e.preventDefault(); navigator(-1)}}>이전</button>
                                        <button>다음</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default JoinInput