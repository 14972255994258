import React, { useEffect, useRef } from 'react'
import { Form, Link, redirect, useActionData, useNavigate } from 'react-router-dom';
import { MARKETING_AGREE, PERSONAL_AGREE, POST_METHOD, SERVICE_AGREE } from '../../const/const';
import { encrypt, makeParamsFromFormData } from '../../util/util';

/**
 * router.js에 등록시키는 action. POST 요청이 들어올 시 해당 액션이 실행된다.
 * @returns redirect를 return 시킴으로써 해당 URL로 바로 이동시킨다.
 */
export const socialTermAction = 
    () => async({ request }) => {
        const url = new URL(request.url);
        const memberName = url.searchParams.get("mb_name");
        const memberEmail = url.searchParams.get("mb_email");
        const provider = url.searchParams.get("mb_provider");

        const param = makeParamsFromFormData(await request.formData());
        const errors = {};

        if(!param.memberServiceAgree) {
            errors.memberServiceAgree = "서비스이용 약관은 필수 입니다."
            return errors;
        } else if(!param.memberProfileAgree) {
            errors.memberServiceAgree = "개인정보이용동의 약관은 필수 입니다."
            return errors;
        }

        const suri = `memberName=${encrypt(memberName)}&memberEmail=${memberEmail}&provider=${provider}`
        return redirect(`/sjoin?ms=${param.memberServiceAgree}&mp=${param.memberProfileAgree}&mm=${param.memberMarketingAgree}&${suri}`);
    }

const SocialTerms = () => {
  const inputRef = useRef([]);
  const errors = useActionData();

  const onClickCheckAll = (e) => {
    inputRef.current.forEach(c => {
      c.checked = e.target.checked
    })
  }

  useEffect(() => {
    if (errors?.memberServiceAgree) {
      inputRef.current[0].focus();
      inputRef.current[0].style.scrollMargin = "90px";
      inputRef.current[0].scrollIntoView();
    } else if (errors?.memberProfileAgree) {
      inputRef.current[1].focus();
      inputRef.current[1].style.scrollMargin = "90px";
      inputRef.current[1].scrollIntoView();
    }
  }, [errors])

  return (
    <main>
      <div className="joinWrap bg2">
        <div className="joinCon">
          <div>
            <h1>Join</h1>
            <ul>
              <li>
                <div className='on'>1</div>
                <p>약관동의</p>
              </li>
              <li className='etc'></li>
              <li>
                <div>2</div>
                <p>추가정보 입력</p>
              </li>
              <li className='etc'></li>
              <li>
                <div>3</div>
                <p>회원가입 완료</p>
              </li>
            </ul>
            <div className='termWrap'>
              <div>
                <div className='all checkboxArea'>
                  <label htmlFor="all" onClick={onClickCheckAll}>
                    <input type="checkbox" id="all" />
                    <p>전체 약관에 동의합니다.</p>
                  </label>
                </div>
                <Form method={POST_METHOD}>
                  <div>
                    <div className='checkboxArea'>
                      <label htmlFor="check1">
                        <input
                          ref={el => inputRef.current[0] = el}
                          type="checkbox"
                          id="check1"
                          name='memberServiceAgree' />
                        <p>[필수] 서비스 이용약관에 동의합니다.</p>
                      </label>
                    </div>
                    <div className='terms'>{SERVICE_AGREE}</div>
                  </div>
                  <div>
                    <div className='checkboxArea'>
                      <label htmlFor="check2">
                        <input
                          ref={el => inputRef.current[1] = el}
                          type="checkbox"
                          id="check2"
                          name='memberProfileAgree' />
                        <p>[필수] 개인정보 처리방침에 동의합니다.</p>
                      </label>
                    </div>
                    <div className='terms'>{PERSONAL_AGREE}</div>
                  </div>
                  <div>
                    <div className='checkboxArea'>
                      <label htmlFor="check3">
                        <input
                          ref={el => inputRef.current[2] = el}
                          type="checkbox"
                          id="check3"
                          name='memberMarketingAgree' />
                        <p>[선택] 마케팅 활용방침에 동의합니다.</p>
                      </label>
                    </div>
                    <div className='terms'>{MARKETING_AGREE}</div>
                  </div>
                  <div className='d-flex'>
                    <button>다음</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SocialTerms;
