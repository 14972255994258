import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";

/**
 * 
 * @returns Story만 감싸는 Component
 */
const StoryLayout = () => {
      // 화면 표시 애니메이션
    useEffect(() => {
        AOS.init();
    },[])

  return (
    <>
        <Outlet/>
    </>
  )
}

export default StoryLayout