export function RemoveNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
        className={className}
        style={{ ...style, display: "none", background: "red" }}
        onClick={onClick}
        />
    );
}

export function RemovePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
        className={className}
        style={{ ...style, display: "none", background: "green" }}
        onClick={onClick}
        />
    );
}