
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const copyEvent = (setIsCopied, appApiKey) => {
    navigator.clipboard.writeText(appApiKey).then(() => {
        setIsCopied(true);
        toast.success('API key copied!');
        setTimeout(() => setIsCopied(false), 1000); 
    }).catch(err => {
        console.error('Failed to copy: ', err);
        toast.error('Failed to copy API key');
    });
}