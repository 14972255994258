import React from "react";

const PDFMessage = ({ pageNum }) => {

  return (
    <main>
      <div className="pdfWrap"> 
      <div className="pdfHead">
          <h1>Alert 메세지</h1>
          <div>페이지 번호 : <span>{pageNum}</span></div>
        </div>
        <div className="pdfCon">
          <table className="history">
            <thead>
              <th>구분</th>
              <th>Alert Title</th>
              <th>Alert 메세지</th>
              <th>비고</th>
            </thead>
            <tbody>
              <tr>
                <td>아이디 또는 패스워드 미입력 시</td>
                <td>로그인 실패</td>
                <td>아이디 또는 패스워드를 입력 후 로그인 하십시오 [확인]</td>
                <td>-</td>
              </tr>
              <tr>
                <td>아이디 또는 패스워드 오류 시</td>
                <td>로그인 실패</td>
                <td>아이디 또는 패스워드를 잘못 입력하셨습니다. [확인]</td>
                <td>아이디 또는 패스워드를 오류 각각을 체크하여 오류메세지를 출력하지는 않음.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="copy">Copyright &copy; 2024 mBaaS all right reserved</div>
      </div>
    </main>
  )

}
export default PDFMessage