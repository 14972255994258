import React, { useRef } from 'react'
import Slider from 'react-slick';
import { RemoveNextArrow, RemovePrevArrow } from '../atom/removeSlickArrow';

const Home = () => {
    // slick 설정
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <RemoveNextArrow/>,
        prevArrow: <RemovePrevArrow/>,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    return (
        <main>
            <section className='sect1Re bg1'>
                <div className='mainTit'>
                    <h2 data-aos="fade-up" data-aos-duration="2000">아이디어에서 앱 출시까지,<br/>모두 한 번에 해결!</h2>
                    {/* <h2 data-aos="fade-up" data-aos-duration="2000">앱 제작의 첫걸음 기획부터, <br/>앱 개발까지 모두 한번에</h2> */}
                    <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기</p>
                </div>
                <div className='subTit d-flex align-items-center gap30' data-aos="fade-up" data-aos-duration="2000">
                    <p>Android / iOS app</p>
                    <span>Create and Develop</span>
                </div>
            </section>
            <section className='sect2Re'>
                <div className='mainTit'>
                    <h2 data-aos="fade-up" data-aos-duration="2000">시중의 무료 앱생성 플랫폼이 가진 어려움</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">앱 제작은 목적성과 유연성을 가져야하는데 비해 기존의 앱 제작 서비스<br/> 방식은 <span className='gradientB'>‘고객의 커스텀 제작’ </span> 니즈를 제대로 해결하지 못합니다.</p>
                </div>
                <ul>
                    <li className='left' data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">
                        <img src="../img/face01.png" alt="아이콘" />
                        <p>조금 사용하다 보면 전환해야하는 유료화 정책</p>
                    </li>
                    <li className='right' data-aos="fade-up" data-aos-duration="2000">
                        <img src="../img/face02.png" alt="아이콘" />
                        <p>기획없는 자체 앱 제작으로 목적 및 방향성의 부재</p>
                    </li>
                    <li className='left' data-aos="fade-up" data-aos-duration="2000">
                        <img src="../img/face03.png" alt="아이콘" />
                        <p>기존 틀의 기능적 한계, 자유로운 커스텀 제작의 부재</p>
                    </li>
                    <li className='right' data-aos="fade-up" data-aos-duration="2000">
                        <img src="../img/face04.png" alt="아이콘" />
                        <p>원하는 기능을 쉽게 요청할 수 있는 소통 창구의 부재</p>
                    </li>
                </ul>
            </section>
            {/* <div className="animated-title">
                <div className="track">
                    <div className="content">&nbsp;앱을 만들려면 어떻게 해야하지?&nbsp;관리는 어떻게 해야하는걸까?&nbsp;앱 만들 아이디어는 있는데..&nbsp;비용은 얼마나 들지?&nbsp;앱을 만들려면 어떻게 해야하지?&nbsp;관리는 어떻게 해야하는걸까?&nbsp;앱 만들 아이디어는 있는데..&nbsp;비용은 얼마나 들지?&nbsp;앱을 만들려면 어떻게 해야하지?&nbsp;관리는 어떻게 해야하는걸까?&nbsp;앱 만들 아이디어는 있는데..&nbsp;비용은 얼마나 들지?&nbsp;앱을 만들려면 어떻게 해야하지?&nbsp;관리는 어떻게 해야하는걸까?&nbsp;앱 만들 아이디어는 있는데..&nbsp;비용은 얼마나 들지?</div>
                </div>
            </div> */}
            <section className='sect3Re bg2'>
                <div className='mainTit'>
                    <h2 data-aos="fade-up" data-aos-duration="2000">앱 개발의 가장 중요한 첫걸음<br/><span className='gradientB'>무료 온라인 앱 기획서</span></h2>
                    <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">실제 앱 개발 현장에서의 방식을 그대로 도입했습니다.</p>
                </div>
                <ul>
                    <li data-aos="fade-right" data-aos-duration="2000" data-aos-delay="200">
                        <h3><span>01</span> 사용자 정의</h3>
                        <div className='d-flex align-items-center'>
                            <p>사용자가 자신의 앱 아이디어를 구체화하고 정리해보며, 스스로가 원하는 앱의 목적과 기능을 뚜렷하게 설정할 수 있게 해줍니다.</p>
                            <img src="../img/Objects01.png" alt="아이콘 이미지" />
                        </div>
                    </li>
                    <li data-aos="fade-left" data-aos-duration="2000" data-aos-delay="200">
                        <h3><span>02</span> 소통 및 피드백의 강화</h3>
                        <div className='d-flex align-items-center'>
                            <p>기획서를 공유하고 피드백을 주고받음으로써 팀원이나 외부와의 소통이 강화됩니다.</p>
                            <img src="../img/Objects02.png" alt="아이콘 이미지" />
                        </div>
                    </li>
                    <li data-aos="fade-right" data-aos-duration="2000" data-aos-delay="200">
                        <h3><span>03</span> 프로젝트 관리 용이성</h3>
                        <div className='d-flex align-items-center'>
                            <p>기획서를 통해 프로젝트를 관리하고 추적할 수 있으며, 필요할 때마다 업데이트하고 수정할 수 있습니다.</p>
                            <img src="../img/Objects03.png" alt="아이콘 이미지" />
                        </div>
                    </li>
                    <li data-aos="fade-left" data-aos-duration="2000" data-aos-delay="200">
                        <h3><span>04</span> 시간과 비용 절감</h3>
                        <div className='d-flex align-items-center'>
                            <p>명확한 기획서를 통해 프로젝트의 방향성을 미리 정의하고 변경을 최소화함으로써 시간과 비용을 절감할 수 있습니다.</p>
                            <img src="../img/Objects04.png" alt="아이콘 이미지" />
                        </div>
                    </li>
                </ul>
                <div className='nextarrow' data-aos="fade-up" data-aos-duration="1000"><img src="../img/nextarrow.png" alt="아이콘" /></div>
            </section>
            <section className='sect4Re'>
                <div className="mainTit">
                    <h2 data-aos="fade-up" data-aos-duration="2000">비대면 시대 AiApp만의 <br/><span className='gradientB'>차별화된 스마트 앱제작 솔루션 3가지</span></h2>
                </div>
                <ul>
                    <li data-aos="fade-up" data-aos-duration="2000">
                        <h3><span>No.01</span><br/>비대면 온라인기획서</h3>
                        <div className='xCon'>
                            <div className='circleImg'>
                                <img src="../img/serviceWhy02.png" alt="이미지" />
                            </div>
                            <p>앱 창작자의 요구사항 파악으로 이해도 상승 구체적이고 정확한 개발 진행 AiApp의 지속적인 피드백으로 완성도 업!</p>
                        </div>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">
                        <h3><span>No.02</span><br/>Ai 프롬프트로 쉽고 빠른 제작</h3>
                        <div className='xCon'>
                            <div className='circleImg'>
                                <img src="../img/circleImg02.png" alt="이미지" />
                            </div>
                            <p>Ai 명령어 입력 방식으로, 셀프 커스터마이징 가능! 쉽게 명령하고, 빠른 적용을 통한 나만의 앱 구축</p>
                        </div>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400">
                        <h3><span>No.03</span><br/>AiApp 디자인 템플릿</h3>
                        <div className='xCon'>
                            <div className='circleImg'>
                                <img src="../img/serviceWhy01.png" alt="이미지" />
                            </div>
                            <p>템플릿으로 아이디어 구체화 디자인 시간 절감 및 개발 범위 오류 최소화, 셀프 앱 제작으로 직접 개발 검증 가능</p>
                        </div>
                    </li>
                </ul>
            </section>
            <section className="sect05Re bg3">
                <div className="mainTit">
                    <h2  data-aos="fade-up" data-aos-duration="2000">AiApp은 고객의 성향에 따라 유연하게<br/> 대응할 수 있습니다.</h2>
                    <div  data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">
                        <p>어떤 앱을 만들고 싶나요?</p>
                    </div>
                </div>
                <div className='xBoxWrap'>
                    <div className='xBox'  data-aos="fade-right" data-aos-duration="2000">
                        <div className='d-flex'>
                            <div>
                                <p>기존의 템플릿 앱을 활용해 빠르게 편집 후 바로 런칭을 하려는 고객을 대상</p>
                                <div className='appTit'>
                                    <h3>베이직 앱</h3>
                                </div>
                            </div>
                            <img src="../img/basic.png" alt="베이직앱 아이콘 이미지" />
                        </div>
                        <ul>
                            <li>
                                <h4>교회 유형</h4>
                                <p>교회의 소식, 교회의 행사 일정, 교우끼리의 소통 등을 쉽게 할 수 있습니다.</p>
                            </li>
                            <li>
                                <h4>협회 유형</h4>
                                <p>협회, 회사, 브랜드 등 홍보에 적합한 템플릿들이 있습니다. </p>
                            </li>
                        </ul>
                    </div>
                    <div className='xBox' data-aos="fade-left" data-aos-duration="2000">
                        <div className='d-flex'>
                            <div >
                                <p>원하는 기능을 자유롭게 적용해 맞춤형 앱을 제작하고 싶어하는 고객을 대상</p>
                                <div className='appTit'>
                                    <h3>커스터마이징 앱</h3>
                                </div>
                            </div>
                            <img src="../img/custom.png" alt="베이직앱 아이콘 이미지" />
                        </div>
                        <ul>
                            <li>
                                <h4>결제 유형</h4>
                                <p>앱내 결제가 기능이 포함되어 카페, 식당 등에 적합합니다.</p>
                            </li>
                            <li>
                                <h4>홍보 유형</h4>
                                <p>협회, 회사, 브랜드 등 홍보에 적합한 템플릿들이 있습니다. </p>
                            </li>
                            <li>
                                <h4>커뮤니티 유형</h4>
                                <p>다중 게시판을 활용한 커뮤니티형에 적합한 템플릿들이 있습니다. </p>
                            </li>
                            <li>
                                <h4>예약 유형</h4>
                                <p>원데이 클래스, 행사 예약, 장소 예약등에 적합합니다.  </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className='sect06Re'>
                <div className="mainTit">
                    <h2 data-aos="fade-up" data-aos-duration="2000">OUR STORY</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">다양한 기업의 스토리, 뉴스, 시사정보를 제공해 커뮤니티 활성을 지원합니다.</p>
                </div>
                <div className="indicaotr d-flex"  data-aos="fade-up" data-aos-duration="2000">
                    <span className="prev cursor" onClick={previous}></span>
                    <span className="next cursor" onClick={next}></span>
                </div>
                <div className="storyWrap"  data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">
                    <Slider 
                        ref={slider => {
                            sliderRef = slider;
                        }}
                        {...settings}>
                            <div className="storyCon cursor">
                                <p className="tag">Community</p>
                                <div className="storyImg">
                                <img src="img/story1.png" alt="스토리 이미지" />
                                </div>
                                <div>
                                <h3>"M뮤"앱을 성공으로 이끈 1인 CEO의 이야기</h3>
                                <p>
                                    앱 "M뮤"가 독보적인 성공을 거두어 그 주인공이 되고 있다. 이
                                    성과를 이끈 주인은 AiApp을 통해 처음 앱을 만들어 성공한 1인
                                    CEO로, 그 독특한 경험과 업적이 많은 이들의 주목을 받고 있다.
                                </p>
                                </div>
                            </div>
                            <div className="storyCon cursor">
                                <p className="tag">News</p>
                                <div className="storyImg">
                                <img src="img/story2.png" alt="스토리 이미지" />
                                </div>
                                <div>
                                <h3>1인 1앱 시대 온다…엠바스, 앱 개발 플랫폼 'AiApp' 출시</h3>
                                <p>
                                    부산창조경제혁신센터는 스타트업 전용 공간 B.Cube 입주기업인
                                    '엠바스'가 최근 앱 개발 플랫폼 서비스 'AiApp'을 출시했다고
                                    13일 밝혔다.
                                </p>
                                </div>
                            </div>
                            <div className="storyCon cursor">
                                <p className="tag">News</p>
                                <div className="storyImg">
                                <img src="img/story3.png" alt="스토리 이미지" />
                                </div>
                                <div>
                                <h3>부산 벤처&창업 ESG선도기업 엠바스 선정</h3>
                                <p>
                                    부산시는 '부산 먼저 지속 가능한 미래로, 아시아 창업도시
                                    부산’의 비전을 향해 한 발짝 더 나아갔다. 이 도시는
                                    '벤처ㆍ창업기업 ESG선도기업 지원사업...
                                </p>
                                </div>
                            </div>
                            <div className="storyCon cursor">
                                <p className="tag">Notice</p>
                                <div className="storyImg">
                                <img src="img/story4.png" alt="스토리 이미지" />
                                </div>
                                <div>
                                <h3>정기 정검 안내 (매주 목요일 11:00 ~ 12:00)</h3>
                                <p>
                                    매주 목요일 11:00 ~ 12:00에 서버 점검이 있을 예정이니 앙해
                                    부탁드립니다. 해당 시간에 앱생성 및 수정이 불가능 하오니
                                    참고바랍니다.
                                </p>
                                </div>
                            </div>
                        
                    </Slider>
                    <div className="link cursor">more</div>
                </div>
            </section>
            {/* <section className="sect1">
                <div className="width14 d-flex justify-content-between">
                <div className="sectTit">
                    <h2 className="focus-in-expand">Ai.App</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">토탈 앱 개발 서비스 플랫폼</p>
                    <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500" >#앱 개발이 어려운 분들을 위한 전문 솔루션</div>
                </div>
                <div className="partial"></div>
                </div>
            </section>

            <section className="sect2">
                <div className="sectTit" data-aos="fade-up" data-aos-duration="2000">
                <h2>시작이 어려우신 분들, <br/> <span className="gradient">Ai.App</span>에서는 어렵지 않습니다.</h2>
                </div>
                <div className="animated-title">
                <div className="track">
                    <div className="content">&nbsp;앱을 만들려면 어떻게 해야하지?&nbsp;관리는 어떻게 해야하는걸까?&nbsp;앱 만들 아이디어는 있는데..&nbsp;비용은 얼마나 들지..?&nbsp;앱을 만들려면 어떻게 해야하지?&nbsp;관리는 어떻게 해야하는걸까?&nbsp;앱 만들 아이디어는 있는데..&nbsp;비용은 얼마나 들지..?</div>
                </div>
                </div>
            </section>
            
            <section className="sect3">
                <div className="width14 inner">
                <div className="sectTit" data-aos="fade-up" data-aos-duration="2000">
                    <h2><span className="gradient">Ai.App</span>은 <span className="bold">창업자와 개인이 쉽게 앱을 만들고 발전시킬 수 있는 혁신적인 플랫폼</span>입니다.</h2>
                </div>
                <div className="sect3Img">
                    <img src="img/mainImg01.png" alt="핸드폰 목업 이미지" />
                    <div className="bgimg">
                    <div className="circle1"></div>
                    <div className="circle2"></div>
                    <div className="circle3" id="circle3"></div>
                    </div>
                </div>
                </div>
            </section>

            <section className="sect4">
                <div className="width14 inner">
                <div className="sectTit">
                    <h2 data-aos="fade-up" data-aos-duration="1000">시작이 어려우신 분들, <br/><span className="gradient">Ai.App</span>에서는 어렵지 않습니다.</h2>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" >기획, 디자인, 개발, 컨설팅까지 모두 제공</p>
                </div>
                <div className="sect4Img">
                    <ul>
                    <li data-aos="fade-right" data-aos-duration="500">
                        <div className="serviceImg">
                        <img src="img/serviceImg1.png" alt="서비스 설명 아이콘" />
                        </div>
                        <div className="serviceTxt" data-aos="fade-up" data-aos-duration="1000">
                        <h3>다양한 앱 템플릿</h3>
                        <p>디자인 개발 시간과 비용, 그리고 개발 범위 오류를 줄일 수 있어요.</p>
                        </div>
                    </li>
                    <li data-aos="fade-left" data-aos-duration="500">
                        <div className="serviceImg">
                        <img src="img/serviceImg2.png" alt="서비스 설명 아이콘" />
                        </div>
                        <div className="serviceTxt" data-aos="fade-up" data-aos-duration="1000">
                        <h3>기획서 자동생성</h3>
                        <p> 템플릿 시반 기획서를 통해 더욱 완벽한 앱 제작을 할 수 있어요.</p>
                        </div>
                    </li>
                    <li className="result" data-aos="fade-right" data-aos-duration="500">
                        <div className="serviceImg">
                        <img src="img/serviceImg3.png" alt="서비스 설명 아이콘" />
                        </div>
                        <div className="serviceTxt" data-aos="fade-up" data-aos-duration="1000">
                        <h3>앱 제작 관련 인프라 지원</h3>
                        <p> 서버, DB, cloud, 부가서비스 등 다양하게 앱 제작관련인프라들을 누리세요.</p>
                        </div>
                    </li>
                    </ul>
                </div>
                </div>
            </section>

            <section className="sect5">
                <div className="width">
                <div className="sectTit">
                    <h2>STORY</h2>
                    <p>AiApp의 다양한 소식을 알려드리니다.</p>
                </div>
                <div className="indicaotr d-flex" data-aos="fade-up" data-aos-duration="2000">
                    <span className="prev cursor" onClick={previous}></span>
                    <span className="next cursor" onClick={next}></span>
                </div>
                <div className="storyWrap" data-aos="fade-up" data-aos-duration="2000">
                    <Slider 
                        ref={slider => {
                            sliderRef = slider;
                        }}
                        {...settings}>
                        <div>
                            <div className="storyCon cursor">
                                <p className="tag">Community</p>
                                <div className="storyImg">
                                <img src="img/story1.png" alt="스토리 이미지" />
                                </div>
                                <div>
                                <h3>"M뮤"앱을 성공으로 이끈 1인 CEO의 이야기</h3>
                                <p>
                                    앱 "M뮤"가 독보적인 성공을 거두어 그 주인공이 되고 있다. 이
                                    성과를 이끈 주인은 AiApp을 통해 처음 앱을 만들어 성공한 1인
                                    CEO로, 그 독특한 경험과 업적이 많은 이들의 주목을 받고 있다.
                                </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="storyCon cursor">
                                <p className="tag">News</p>
                                <div className="storyImg">
                                <img src="img/story2.png" alt="스토리 이미지" />
                                </div>
                                <div>
                                <h3>1인 1앱 시대 온다…엠바스, 앱 개발 플랫폼 'AiApp' 출시</h3>
                                <p>
                                    부산창조경제혁신센터는 스타트업 전용 공간 B.Cube 입주기업인
                                    '엠바스'가 최근 앱 개발 플랫폼 서비스 'AiApp'을 출시했다고
                                    13일 밝혔다.
                                </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="storyCon cursor">
                                <p className="tag">News</p>
                                <div className="storyImg">
                                <img src="img/story3.png" alt="스토리 이미지" />
                                </div>
                                <div>
                                <h3>부산 벤처&창업 ESG선도기업 엠바스 선정</h3>
                                <p>
                                    부산시는 '부산 먼저 지속 가능한 미래로, 아시아 창업도시
                                    부산’의 비전을 향해 한 발짝 더 나아갔다. 이 도시는
                                    '벤처ㆍ창업기업 ESG선도기업 지원사업...
                                </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="storyCon cursor">
                                <p className="tag">Notice</p>
                                <div className="storyImg">
                                <img src="img/story4.png" alt="스토리 이미지" />
                                </div>
                                <div>
                                <h3>정기 정검 안내 (매주 목요일 11:00 ~ 12:00)</h3>
                                <p>
                                    매주 목요일 11:00 ~ 12:00에 서버 점검이 있을 예정이니 앙해
                                    부탁드립니다. 해당 시간에 앱생성 및 수정이 불가능 하오니
                                    참고바랍니다.
                                </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="storyCon cursor">
                                <p className="tag">Community</p>
                                <div className="storyImg">
                                <img src="img/story1.png" alt="스토리 이미지" />
                                </div>
                                <div>
                                <h3>"M뮤"앱을 성공으로 이끈 1인 CEO의 이야기</h3>
                                <p>
                                    앱 "M뮤"가 독보적인 성공을 거두어 그 주인공이 되고 있다. 이
                                    성과를 이끈 주인은 AiApp을 통해 처음 앱을 만들어 성공한 1인
                                    CEO로, 그 독특한 경험과 업적이 많은 이들의 주목을 받고 있다.
                                </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="storyCon cursor">
                                <p className="tag">News</p>
                                <div className="storyImg">
                                <img src="img/story2.png" alt="스토리 이미지" />
                                </div>
                                <div>
                                <h3>1인 1앱 시대 온다…엠바스, 앱 개발 플랫폼 'AiApp' 출시</h3>
                                <p>
                                    부산창조경제혁신센터는 스타트업 전용 공간 B.Cube 입주기업인
                                    '엠바스'가 최근 앱 개발 플랫폼 서비스 'AiApp'을 출시했다고
                                    13일 밝혔다.
                                </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="storyCon cursor">
                                <p className="tag">News</p>
                                <div className="storyImg">
                                <img src="img/story3.png" alt="스토리 이미지" />
                                </div>
                                <div>
                                <h3>부산 벤처&창업 ESG선도기업 엠바스 선정</h3>
                                <p>
                                    부산시는 '부산 먼저 지속 가능한 미래로, 아시아 창업도시
                                    부산’의 비전을 향해 한 발짝 더 나아갔다. 이 도시는
                                    '벤처ㆍ창업기업 ESG선도기업 지원사업...
                                </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="storyCon cursor">
                                <p className="tag">Notice</p>
                                <div className="storyImg">
                                <img src="img/story4.png" alt="스토리 이미지" />
                                </div>
                                <div>
                                <h3>정기 정검 안내 (매주 목요일 11:00 ~ 12:00)</h3>
                                <p>
                                    매주 목요일 11:00 ~ 12:00에 서버 점검이 있을 예정이니 앙해
                                    부탁드립니다. 해당 시간에 앱생성 및 수정이 불가능 하오니
                                    참고바랍니다.
                                </p>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
                <div className="link cursor" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                    more
                </div>
                </div>
            </section> */}
        </main>
  )
}

export default Home