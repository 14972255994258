import React from 'react'

const Guide = () => {
  return (
    <main className='bg2'>
      <section class="guideWrap">
        <div class="width14">
          <div class="sectTit">
            <h2 data-aos="fade-up" data-aos-duration="2000">AiApp 에서 어떻게 앱을 만들면되나요? </h2>
            <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500"></p>
          </div>

          <ul class="guideCon" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">
            <li class="guideBox">
              <div class="guideTit">
                <img src="../img/icon_guide01.png" alt="가이드 아이콘"/>
                <h3>무료 <span>회원가입</span></h3>
                <p>무료앱 생성은 회원 전용 서비스로, 회원가입을<br/> 먼저 진행해주세요. </p>
              </div>
              <div className='step'>STEP. 1</div>
            </li>
            <li class="guideBox">
              <div class="guideTit">
                <img src="../img/icon_guide02.png" alt="가이드 아이콘"/>
                <h3><span>앱생성</span>하러 가기</h3>
                <p>앱생성 메뉴에서 만들고싶은 앱의 유사앱과 템플릿을 <br/> 추천받고 나만의 앱을 만들어보세요.</p>
              </div>
              <div className='step'>STEP. 2</div>
            </li>
            <li class="guideBox">
              <div class="guideTit">
                <img src="../img/icon_guide03.png" alt="가이드 아이콘"/>
                <h3>앱<span>다운로드</span> 및 <span>테스트</span></h3>
                <p>생성한 앱을 앱관리자를 통해 관리하거나, 수정 후 <br/>
                  QR코드를 통해 다운로드 받아 직접 앱을 테스트 해보세요!</p>
              </div>
              <div className='step'>STEP. 3</div>
            </li>
            <li class="guideBox">
              <div class="guideTit">
                <img src="../img/icon_guide04.png" alt="가이드 아이콘"/>
                <h3>앱 기획서 <span>수정 및 보완</span></h3>
                <p>자동 생성된 기획서를 수정하여 앱의 수정사항이나 보완을<br/> 통해 앱을 더 정교하게 만들어 보세요.</p>
              </div>
              <div className='step'>STEP. 4</div>
            </li>
            <li class="guideBox">
              <div class="guideTit">
                <img src="../img/icon_guide05.png" alt="가이드 아이콘"/>
                <h3>유료전환 및 <span>스토어 출시</span></h3>
                <p>유료로 전환하여 생성한 앱을 스토어에 출시해 보세요!</p>
              </div>
              <div className='step'>STEP. 5</div>
            </li>
            <li class="guideBox">
              <div class="guideTit">
                <img src="../img/icon_guide06.png" alt="가이드 아이콘"/>
                <h3><span>플랫폼</span> 서비스 및 <span>프로모션</span> 지원</h3>
                <p>부가서비스 계약 대행으로 쉽고 저렴하게 이용하고, <br/>서비스 오픈 이후에도 이어지는 후속지원!</p>
              </div>
              <div className='step'>STEP. 6</div>
            </li>
          </ul>
        </div>
      </section>
    </main>
  )
}

export default Guide