import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Error404 = () => {
    const navigator = useNavigate();

    // useEffect(() => {
    //     navigator("/")
    // }, [])

    return (
        <div>Error404</div>
    )
}

export default Error404