import { GET_METHOD, POST_METHOD, PUT_METHOD } from "../../const/const";
import { AUTH_MAIL_DEFINE, FIND_SMS_AUTH_DEFINE, FINE_SMS_AUTH_CHECK_DEFINE, JOIN_DEFINE, LOGIN_DEFINE, MEMBER_APP_DEFINE, MY_INFO_DEFINE, MY_INFO_UPDATE_DEFINE, RESET_PW_DEFINE, SEND_MAIL_DEFINE, SOCIAL_JOIN_DEFINE } from "../../const/defineName";

export const memberDefine = {
    [LOGIN_DEFINE]: {
        url: "/member/p_login",
        method: POST_METHOD
    },
    [MY_INFO_DEFINE]: {
        url: "/member",
        method: GET_METHOD
    },
    [MY_INFO_UPDATE_DEFINE]: {
        url: "/member/update",
        method: PUT_METHOD,
    },
    [SEND_MAIL_DEFINE]: {
        url: "/member/auth/send",
        method: POST_METHOD
    },
    [AUTH_MAIL_DEFINE]: {
        url: "/member/auth/code",
        method: POST_METHOD
    },
    [JOIN_DEFINE]: {
        url: "/member",
        method: POST_METHOD
    }, 
    [FIND_SMS_AUTH_DEFINE]: {
        url: "/member/auth/email",
        method: POST_METHOD
    },
    [FINE_SMS_AUTH_CHECK_DEFINE]: {
        url: "/member/auth/email",
        method: GET_METHOD,
    },
    [MEMBER_APP_DEFINE]: {
        url: "/member/list/app",
        method: GET_METHOD
    },
    [SOCIAL_JOIN_DEFINE]: {
        url: "/member/oauth",
        method: POST_METHOD
    },
    [RESET_PW_DEFINE]: {
        url: "/member/auth/password",
        method: POST_METHOD
    }
}