import React from 'react'

const Inquiry = () => {
    return (
        <main>
            <section className="storyWrap">
                <div className="width14">
                    <div className="sectTit">
                        <h2 data-aos="fade-up" data-aos-duration="2000">1:1 문의</h2>
                        <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                            AiApp의 서비스가 궁금하신가요?<br />
                            문의 남겨주시면 확인 후 성실히 답변해 드리겠습니다.
                        </p>
                    </div>

                    <div className="inquiryWrap" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="700">
                        <div className="inBoxWrap">
                            <div className="inBox">
                                <h3>유형</h3>
                                <select name="" id="">
                                    <option value="">앱 생성 문의</option>
                                    <option value="">앱 기능 관련 문의</option>
                                    <option value="">기획서 관련 문의</option>
                                    <option value="">관리자 기능 관련 문의</option>
                                    <option value="">마케팅 관련 문의</option>
                                    <option value="">제휴 관련 문의</option>
                                    <option value="">광고 관련 문의</option>
                                    <option value="">기타 문의</option>
                                </select>
                            </div>
                            <div className="inBox"></div>
                        </div>
                        <div className="inBoxWrap">
                            <div className="inBox">
                                <h3>제목</h3>
                                <input type="text" placeholder="제목을 입력해 주세요."/>
                            </div>
                            <div className="inBox">
                                <h3>첨부파일</h3>
                                <input type="file" id="file"/>
                                <span>* 첨부파일은 .png, .jpg 유형의 파일만 첨부하실수 있습니다.</span>
                            </div>
                        </div>
                        <div className="inBox">
                            <h3>내용</h3>
                            <textarea name="" id="" cols="30" rows="10" placeholder="내용을 입력해 주세요."></textarea>
                        </div>
                    </div>
                    <div className="BtnWrap" data-aos="fade-up" data-aos-duration="2000" >
                        <button className="Btn">문의하기</button>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Inquiry