import React, { useState } from 'react'
import { customCapabilityListQuery } from '../../../queries/checklistQueries';
import { Form, Link, redirect, useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { POST_METHOD } from '../../../const/const';
import { makeParamsFromFormData } from '../../../util/util';

/**
 * 해당 페이지가 랜더링 되기전 Loader가 실행되어 데이터 통신을 마친후 페이지를 랜더링 해준다.
 * @param {QueryClient} queryClient router.js에서 받아온 React-query Client
 * @returns 
 */
export const customCapabiliyListLoader =
  (queryClient) => async ({ request }) => {
    const url = new URL(request.url);
    const category = url.searchParams.get("n");
    const template = url.searchParams.get("t");

    if (!queryClient.getQueryData(customCapabilityListQuery({ category: category }))) {
      return queryClient.fetchQuery(customCapabilityListQuery({ category: category }));
    }

    return queryClient.getQueryData(customCapabilityListQuery({ category: category }));
  }

/**
 * router.js에 등록시키는 action. POST 요청이 들어올 시 해당 액션이 실행된다.
 * @returns redirect를 return 시킴으로써 해당 URL로 바로 이동시킨다.
 */
export const customCapabilityListAction =
  () => async({ request }) => {
    const url = new URL(request.url);
    const param = makeParamsFromFormData(await request.formData());
    return redirect(`/checklistcustom/5?${url.searchParams.toString()}&c=${param.appusefunction? param.appusefunction.join(";") : ""}`);
  }

const CheckList4_1 = () => {
  const { data } = useLoaderData();
  const [desc, setDesc] = useState("");
  const [searchParams,] = useSearchParams();

  const onClickCapa = (idx) => {
    setDesc(data[idx].content);
  }

  return (
    <div className='checkListReWrap'>
      <Form method={POST_METHOD}>
        <main className='checkListCon'>
          <div className='wrap'>
            <div className='leftCon'>
              <div className='sbox'>
                <span>(선택한 유형)을 선택하셨습니다.</span>
                <h2>추가기능을 선택해주세요.</h2>
                <p>추가기능은 앱 생성 후에도 수정하실 수 있습니다.</p>
                <div className='custom addfunction'>
                  <ul>
                    {
                      data.map((d, idx) => (
                        <li key={idx} onChange={(e) => { onClickCapa(idx) }}>
                          <input type="checkbox" id={d.code} value={d.code} name={"appusefunction"} />
                          <label htmlFor={d.code}>
                            <img src={d.fileUrl[0]} alt={d.name} />
                            <h4>{d.name}</h4>
                          </label>
                        </li>
                      ))
                    }
                    {/* <li>
                      <input type="radio" id="google" name='addfunction'/>
                      <label htmlFor="google">
                        <img src="../img/addfunction_google.png" alt="추가기능 이미지" />
                        <h4>구글 소셜로그인</h4>
                      </label>
                    </li>
                    <li>
                      <input type="radio" id="naver" name='addfunction'/>
                      <label htmlFor="naver">
                        <img src="../img/addfunction_naver.png" alt="추가기능 이미지" />
                        <h4>네이버 소셜로그인</h4>
                      </label>
                    </li>
                    <li>
                      <input type="radio" id="kakao" name='addfunction'/>
                      <label htmlFor="kakao">
                        <img src="../img/addfunction_kakao.png" alt="추가기능 이미지" />
                        <h4>카카오 소셜로그인</h4>
                      </label>
                    </li>
                    <li>
                      <input type="radio" id="apple" name='addfunction'/>
                      <label htmlFor="apple">
                        <img src="../img/addfunction_apple.png" alt="추가기능 이미지" />
                        <h4>애플 소셜로그인</h4>
                      </label>
                    </li>
                    <li>
                      <input type="radio" id="join" name='addfunction'/>
                      <label htmlFor="join">
                        <img src="../img/addfunction_join.png" alt="추가기능 이미지" />
                        <h4>회원가입&로그인</h4>
                      </label>
                    </li>
                    <li>
                      <input type="radio" id="call" name='addfunction'/>
                      <label htmlFor="call">
                        <img src="../img/addfunction_call.png" alt="추가기능 이미지" />
                        <h4>전화걸기</h4>
                      </label>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className='rightCon'>
              <div className='sboxWrap'>
                <div className="sbox">
                  {parse(desc)}
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div className='btnWrap'>
            <Link to={-1}><button className='off'>이전</button></Link>
            <button>다음</button>
          </div>
        </footer>
      </Form>
    </div>
  )
}


export default CheckList4_1