import React, { useState } from 'react'
import Slider from 'react-slick';
import { Form, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { customCapabilityListQuery, customTemplateQuery } from '../../../queries/checklistQueries';
import { ACCESS_TOKEN, KOR_REX, POST_METHOD, SPC_REX, START_NUMBER_REX, UPPER_CASE_REX } from '../../../const/const';
import { useQuery } from '@tanstack/react-query';
import AppCreateTutoPopup from '../../components/popup/appCreateTutoPopup';
import { makeParamsFromFormData } from '../../../util/util';
import { EventSource, EventSourcePolyfill } from 'event-source-polyfill';
import { useRecoilState } from 'recoil';
import { appProcessState } from '../../../recoil/checkListState';
import { useToastPopup } from '../../../hooks/useToastPopup';
import { BUILD_HOST } from '../../../server/host';

export const appCreateLoader =
  (queryClient) => async ({ request }) => {
    const url = new URL(request.url);
    const category = url.searchParams.get("n");
    const templateCode = url.searchParams.get("t");
    let capaList = url.searchParams.get("c").split(";");

    // 호출한 전체 기능 목록
    let allCapaList = {}

    if (!queryClient.getQueryData(customCapabilityListQuery({ category: category }))) {
      allCapaList = await queryClient.fetchQuery(customCapabilityListQuery({ category: category }));
    } else {
      allCapaList = await queryClient.getQueryData(customCapabilityListQuery({ category: category }));
    }

    let selectList = [];

    capaList.forEach(c => {
      selectList = [...selectList, ...allCapaList.data.filter(d => d.code === c)];
    });

    let convertList = [];
    selectList.forEach(s => {
      const param = {
        capabilityName: s.name,
        capabilityCode: s.code,
        capabilityContent: s.content,
        capabilityImgUrl: s.fileUrl[0],
      }
      convertList = [...convertList, param];
    })

    // 템플릿 상세 호출
    if (!queryClient.getQueryData(customTemplateQuery({ param: templateCode }))) {
      await queryClient.fetchQuery(customTemplateQuery({ param: templateCode }));
    }

    return convertList;
  }

// action을 통해 받아온 capaCode로 다시 기능을 호출해서 선택한 기능에 대한 데이터를 찾음
export const appCreateAction =
  (queryClient) => async ({ request }) => {
    const param = makeParamsFromFormData(await request.formData());
    return param
  }


const CheckList5_1 = () => {
  const [searchParams,] = useSearchParams();
  const { data: { data: { fileUrl } } } = useQuery(customTemplateQuery({ param: searchParams.get("t") }));
  const navigator = useNavigate();
  const toastHook = useToastPopup();
  const [tutoOpen, setTutoOpen] = useState(false);
  const [appName, setAppName] = useState("");
  const [projectName, setProjectName] = useState("");
  // 파라미터 형식변환 해줘야함.
  const [appProcess, setAppProcess] = useRecoilState(appProcessState);

  /**
   * 앱을 생성하는 함수. Event-stream을 사용.
   * @param {*} e 이벤트 매개변수
   * @returns 
   */
  const onClickCreateApp = async(e) => {
    e.preventDefault();
    if(SPC_REX.test(appName)) {
      toastHook.addToastPopup("앱 이름에 특수기호는 불가능 합니다.", "error");
      return;
    }
    if(!appName) {
      toastHook.addToastPopup("앱 이름은 필수입니다.", "error");
      return;
    }
    if(!projectName) {
      toastHook.addToastPopup("프로젝트이름은 필수입니다.", "error");
      return;
    }
    if(SPC_REX.test(projectName)) {
      toastHook.addToastPopup("프로젝트명에 특수기호는 불가능 합니다.", "error");
      return;
    }
    if(UPPER_CASE_REX.test(projectName)) {
      toastHook.addToastPopup("프로젝트명에 대문자는 불가능 합니다.", "error");
      return;
    }
    if(KOR_REX.test(projectName)) {
      toastHook.addToastPopup("프로젝트명에 한글은 불가능 합니다.", "error");
      return;
    }
    if(START_NUMBER_REX.test(projectName)) {
      toastHook.addToastPopup("프로젝트명 첫번째 글자는 영어로 구성되야합니다.", "error");
      return;
    }

    /**
     * [NOTICE] build server는 HOST가 다르기 때문에 이점 유의해야함.
     * event-stream 객체 생성 후 서버 통신.
     * 참고: https://github.com/Yaffle/EventSource
     */
    const eventSource = new EventSourcePolyfill(
      `${BUILD_HOST}/build/start2?templateCode=${searchParams.get("t")}&appName=${appName}&projectName=${projectName}`,
      { 
        headers: { 
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
        heartbeatTimeout: 10 * 60 * 1000,
        withCredentials: true, 
      }
    );

    eventSource.onopen = () => {
      // 연결 시 할 일
      const newVal = {
        ...appProcessState,
        isBuilding: true
      }
      setAppProcess(newVal);
      navigator("/checklistcustom/appcreate")
    };

    // event-stream으로 부터 메세지가 도착했을 시.
    eventSource.onmessage = async (e) => {
      let message = await e.data;
      message = message.split("^");
      const newVal = {
        ...appProcess,
        isBuilding: true,
        message: message[0],
        percent: Number(message[1])
      }

      setAppProcess(newVal);

      if (message[0] === 'Complete') {
        const completeVal = {
          ...appProcess,
          isBuilding: false,
          message: "앱 생성이 완료되었습니다.",
          percent: 100
        };

        setAppProcess(completeVal);
        toastHook.addToastPopup("앱 생성이 완료되었습니다.", "success");
        eventSource.close(); // 작업 완료 후 EventSource 닫기
      }
    };

    // eventSource.onerror = (e) => {
    //   // 종료 또는 에러 발생 시 할 일
    //   // console.log(e);
    //   // console.log(e.status);
    //   eventSource.close();
    //   if (e.status !== 200) {
    //     // 에러 발생 시 할 일
    //     const newVal = {
    //       isBuilding: false,
    //       message: "",
    //       percent: 0
    //     }
    //
    //     setAppProcess(newVal);
    //     toastHook.addToastPopup("앱 생성에 문제가 생겼습니다.", "error");
    //     return;
    //   }
    //
    //   if (e.target.readyState === EventSource.CLOSED) {
    //     // 종료 시 할 일
    //     const newVal = {
    //       isBuilding: false,
    //       message: "",
    //       percent: 0
    //     }
    //
    //     setAppProcess(newVal);
    //     toastHook.addToastPopup("앱 생성이 완료되었습니다.", "success");
    //   }
    // };
    eventSource.onerror = (e) => {
      console.log('error:', e);

      eventSource.close();

      // readyState가 CLOSED인 경우에만 처리
      if (e.readyState === EventSource.CLOSED) {
        const newVal = {
          isBuilding: false,
          message: "",
          percent: 0
        };

        setAppProcess(newVal);
        toastHook.addToastPopup("앱 생성에 문제가 생겼습니다.", "error");
        return;
      }

      // 기타 에러 처리
      const newVal = {
        isBuilding: false,
        message: "",
        percent: 0
      };

      setAppProcess(newVal);
      toastHook.addToastPopup("앱 생성에 문제가 생겼습니다.", "error");
    };

  }

  // slick 설정
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const onClickTuto = (e) => {
    e.preventDefault();
    setTutoOpen(!tutoOpen);
  }

  return (
    <div className='checkListReWrap'>
      {tutoOpen ? <AppCreateTutoPopup onClick={onClickTuto} /> : ""}
      <Form method={POST_METHOD}>
        <input type='hidden' value={searchParams.get("t")} name='templateCode' />
        <main className='checkListCon'>
          <div className='wrap'>
            <div className='leftCon'>
              <div className='sbox'>
                <span>{searchParams.get("n")}을 선택하셨습니다.</span>
                <h2>앱 이름과 프로젝트 명을 작성해주세요.</h2>
                <div>
                  <h3>앱 이름</h3>
                  <input type="text" 
                    value={appName} 
                    onChange={(e) => {setAppName(e.target.value)}} 
                    name='appName' 
                    placeholder='프로젝트명을 입력해주세요.' />
                  <p className='description'>앱 이름은 추후 수정이 가능합니다.</p>
                </div>
              </div>
              <div className='sbox'>
                <div>
                  <div className='tit d-flex align-items-center justify-content-between'>
                    <h3>프로젝트 명</h3>
                    <button onClick={(e) => { onClickTuto(e) }}>프로젝트 명이란?</button>
                  </div>
                  <input 
                    type="text" 
                    value={projectName}
                    onChange={(e) => {setProjectName(e.target.value)}}
                    name='projectName' 
                    placeholder='프로젝트명을 입력해주세요.' />
                  <p className='description'>영문 소문자와 숫자로만 가능하며, 추후 변경이 불가능하니 신중히 작성해주세요. (최소 5 ~12글자) <br />관리자 페이지 로그인 시 프로젝트명이 필요합니다.</p>
                </div>
              </div>
            </div>
            <div className='rightCon'>
              <div className='templateImg'>
                <Slider {...settings} dotsClass="test-css">
                  {
                    fileUrl.map(f => (
                      <img key={f} src={f} alt='이미지' />
                    ))
                  }
                </Slider>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div className='btnWrap'>
            <Link to={-1}><button className='off'>이전</button></Link>
            <button onClick={(e) => {onClickCreateApp(e)}}>앱 생성하기</button>
          </div>
        </footer>
      </Form>
    </div>
  )
}


export default CheckList5_1