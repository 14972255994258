import { atom } from "recoil";

export const checkListState = atom({
    key: "checkListState",
    default: {},
})

export const appProcessState = atom({
    key: "appProcessState",
    default: {
        isBuilding: false,
        message: "",
        percent: 0,
    }
})