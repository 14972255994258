import { useRecoilState, useRecoilValue } from "recoil"
import { toastPopupState } from "../recoil/toastPopupState";
import { randomNumber } from "../util/util";
import ToastPopup from "../views/components/popup/toastPopup";

export const useToastPopup = () => {
    const [toastList, setToastList] = useRecoilState(toastPopupState);

    const getToastPopup = () => {
        return toastList
    }

    const addToastPopup = (message, type) => {
        setToastList((currArr) => [
            ...currArr,
            <ToastPopup key={randomNumber()} id={randomNumber()} message={message} type={type}/>
        ])
    }

    const removeToastPopup = (id) => {
        let newArr = [...toastList];
        newArr = newArr.filter(a => a.id === id);
        setToastList(newArr);
    }

    return {
        addToastPopup,
        removeToastPopup,
        getToastPopup,
    }
}