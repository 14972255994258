import React, { useState } from 'react'
import MyPageUseroutPopup from '../../components/popup/myPageUseroutPopup'
import { usePreventRefresh } from '../../../hooks/usePreventRefresh'
import { Form, redirect, useActionData, useLoaderData } from 'react-router-dom';
import Input from '../../atom/Input';
import { makeParamsFromFormData } from '../../../util/util';
import { POST_METHOD } from '../../../const/const';
import { updateMyInfoQuery } from '../../../queries/memberQueries';

/**
 * router.js에 등록시키는 action. POST 요청이 들어올 시 해당 액션이 실행된다.
 * @returns error를 return 시킴으로써 useActionData()를 통해 action에서 발생한 에러를 받을 수 있다.
 */
export const updateMyInfoAction = 
    (queryClient) => async({ request }) => {
        const param = makeParamsFromFormData(await request.formData());
        const errors = {};
        
        if(!param.memberName) {
            errors.memberName = "이름을 입력해주세요.";
            return errors
        } else if(!param.memberPhone) {
            errors.memberPhone = "휴대폰번호를 입력해주세요.";
            return errors;
        }

        const response = await queryClient.fetchQuery(updateMyInfoQuery(param));

        if(response.result === "SUCCESS") {
            return redirect("/info")
        }

        return errors
    }

const MypageUser = () => {
    const { data } = useLoaderData();
    const errors = useActionData();
    const [isOpen, setIsOpen] = useState(false)

    // 새로고침시 경고문구를 띄어주는 Hook
    usePreventRefresh()

    return (
        <>
            <MyPageUseroutPopup isOpen={isOpen} setIsOpen={setIsOpen}/>
            <main className='bg1'>
                <div className="mypageWrap height10">
                    <Form method={POST_METHOD}>
                        <div className="width14">
                            <h2 data-aos="fade-up" data-aos-duration="2000">My Page</h2>                            
                            <div className="userWrap" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100">
                                <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">
                                    <h3>프로필사진</h3>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center gap30">
                                            <div className="profile"></div>
                                            <div>
                                                <input type="file" id="file" />
                                                <label htmlFor="file">파일찾기</label>
                                                <div className="description">* 정사각형의 이미지 파일을 첨부해주세요. (확장자 .png, .jpg)</div>
                                            </div>
                                        </div>
                                        <button className="editBtn">내정보 수정완료</button>
                                    </div>
                                </div>
                                <div className="userInfo d-flex gap30" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400">
                                    <div className="userLeft">
                                        <div>
                                            <h3>이메일</h3>
                                            <div className='bBox'>{ data.memberEmail }</div>
                                        </div>
                                        <div className='white'>
                                            <h3>이름</h3>
                                            <Input 
                                                type="text" 
                                                name={"memberName"}
                                                placeholder="이름을 입력해주세요." 
                                                defaultValue={ data.memberName }
                                                error={errors?.memberName}/>
                                        </div>
                                        <div className='white'>
                                            <h3>연락처</h3>
                                            <Input 
                                                type="text" 
                                                placeholder="연락처를 입력해주세요." 
                                                name={"memberPhone"}
                                                defaultValue={ data.memberPhone }
                                                error={errors?.memberPhone}/>
                                        </div>
                                    </div>
                                    <div className="userRight">
                                        <button onClick={(e) => {setIsOpen(true); e.preventDefault()}}>탈퇴하기 {">"} </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </main>
        </>
    )
}

export default MypageUser