import React, { useEffect, useState } from 'react'
import { Form, Link, useNavigate } from 'react-router-dom';
import Input from '../atom/Input';
import { fetchData } from '../../util/util';
import { AUTH_MAIL_DEFINE, SEND_MAIL_DEFINE } from '../../const/defineName';

// PW 찾기는 Action 패턴 예외(캐싱이 필요하지 않고 요청에 비해 다른 작업량이 더 많아짐)
const FindPassword = () => {
    const navigator = useNavigate();
    const [minutes, setMinutes] = useState(parseInt(3));
    const [seconds, setSeconds] = useState(parseInt(0));
    const [startTimer, setStartTimer] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [error, setError] = useState({})
    const [isAuth, setIsAuth] = useState(false);
    const [isSend, setIsSend] = useState(false);

    const sendAuthMail = async (e) => {
        e.preventDefault();
        setMinutes(3);
        setSeconds(0);
        if (!userEmail) {
            setError({
                userEmail: "이메일을 입력해주세요."
            })
            return;
        } else {
            setError({});
        }

        setStartTimer(true);
        setIsSend(true);
        const response = await fetchData(SEND_MAIL_DEFINE, { memberEmail: userEmail });
        if (response.result === "FAIL") {
            setStartTimer(false);
            setMinutes(3);
            setSeconds(0);
            setError({
                commonError: response.message
            })
        }
    }

    const checkAuthMail = async (e) => {
        e.preventDefault();
        setError({})
        const response = await fetchData(AUTH_MAIL_DEFINE, { memberEmail: userEmail, code: authCode });

        if (response.result === "SUCCESS") {
            setStartTimer(false);
            setMinutes(3);
            setSeconds(0);
            setIsAuth(true);
        } else {
            setError({
                code: response.message
            })
        }
    }

    const goToResetPw = () => {
        if(!userEmail) {
            setError({
                userEmail: "이메일을 입력해주세요"
            })
            return;
        }
        if(!authCode) {
            setError({
                code: "인증코드가 존재하지 않습니다."
            })
            return;
        }
        if(!isAuth) {
            setError({
                code: "이메일인증이 완료되지 않았습니다."
            })
            return;
        }

        navigator(`/findpwRe?email=${userEmail}&code=${authCode}`);
    }

    useEffect(() => {
        if (!startTimer) return;

        const countdown = setInterval(() => {
            if (parseInt(seconds) > 0) {
                setSeconds(parseInt(seconds) - 1);
            }
            if (parseInt(seconds) === 0) {
                if (parseInt(minutes) === 0) {
                    clearInterval(countdown);
                } else {
                    setMinutes(parseInt(minutes) - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds, startTimer]);

    return (
        <main>
            <div className="joinWrap">
                <div className="joinCon">
                    <div>
                        <h1>Find Password</h1>
                        <p className='center bold'>가입시 입력한 메일을 인증해주세요. </p>
                        <div>
                            <div>
                                <div className='emailWrap sbox'>
                                    <h4>이메일</h4>
                                    <div className='d-flex gap10'>
                                        <Input
                                            type="text"
                                            name={"memberEmail"}
                                            onChange={(e) => {setUserEmail(e.target.value)}}
                                            value={userEmail}
                                            placeholder='이메일을 입력해주세요. ' 
                                            error={error.userEmail}/>
                                        <button className='resend'>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</button>
                                        <button className='resend'></button>
                                        <button onClick={isSend ? (e) => {e.preventDefault()} : (e) => {sendAuthMail(e)}}>인증번호 발송</button>
                                    </div>
                                    <div className='d-flex gap10'>
                                        <Input
                                            type="text"
                                            name={"code"}
                                            value={authCode}
                                            onChange={(e) => {setAuthCode(e.target.value)}}
                                            placeholder='인증번호를 입력해주세요. ' 
                                            error={error.code}/>
                                        <button className='resend' onClick={sendAuthMail}>재발송</button>
                                        <button onClick={(e) => {checkAuthMail(e)}}>인증번호 확인</button>
                                    </div>
                                </div>
                                { error.commonError ? <div>{ error.commonError }</div> : "" }
                                <button onClick={goToResetPw}>비밀번호 찾기</button>
                            </div>
                            <div className='or'>
                                <p className='center'>이메일을 잃어버리셨나요?</p>
                                <Link to={"/findid"}><button>이메일 찾기</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default FindPassword