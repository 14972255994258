import React from 'react'

const InquiryUser = () => {
    return (
        <main>
            <section className="storyWrap">
                <div className="width14">
                    <div className="sectTit">
                        <h2 data-aos="fade-up" data-aos-duration="2000">내 문의</h2>
                    </div>

                    <div className="inquiryWrap user" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="700">
                        <h2>내 문의상세</h2>
                        <div className="inBoxWrap">
                            <div className="inBox">
                                <h3>유형</h3>
                                <div>앱생성 문의</div>
                            </div>
                            <div className="inBox">
                                <h3>답변상태</h3>
                                <div>답변완료</div>
                            </div>
                        </div>
                        <div className="inBoxWrap">
                            <div className="inBox">
                                <h3>제목</h3>
                                <div>문의드립니다.</div>
                            </div>
                            <div className="inBox">
                                <h3>첨부파일</h3>
                                <div className="cursor">asd.png</div>
                            </div>
                        </div>
                        <div className="inBox">
                            <h3>내용</h3>
                            <div className="answer">문의 내용입니다. </div>
                        </div>
                        <div className="BtnWrap" data-aos="fade-up" data-aos-duration="2000" >
                            <button className="Btn">마이페이지로</button>
                        </div>
                    </div>

                    <div className="inquiryWrap user" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="700">
                        <h2>문의 답변</h2>
                        <div className="inBoxWrap">
                            <div className="inBox">
                                <h3>제목</h3>
                                <div>문의드립니다.</div>
                            </div>
                        </div>
                        <div className="inBox">
                            <h3>내용</h3>
                            <div className="answer">답변내용입니다.</div>
                        </div>
                    </div>

                </div>
            </section>
        </main>
    )
}

export default InquiryUser