import QRCode from 'qrcode.react'
import React from 'react'

const QrCodePopup = ({ isOpen, setIsOpen, value }) => {
    return (
        <div className="popupWrap" style={{display: isOpen ? "block" : "none"}}>
            <div className="popupBox QrPop">
                <div className="popTit d-flex  justify-content-between  align-items-center">
                    <h2>앱 다운로드 QR</h2>
                    <div className="closeBtn" onClick={() => {setIsOpen(false)}}><img src="../../img/icon_closeB.png" alt="닫기 버튼"/></div>
                </div>
                <div className='d-flex justify-content-center'>
                    <QRCode value={value}/>
                </div>
            </div>
        </div>
    )
}

export default QrCodePopup