import React from 'react'

/**
 * 아이디 찾기 완료 페이지
 * @returns 
 */
const FindIdCom = () => {

  return (
    <main>
      <div className="joinWrap">
        <div className="joinCon">
          <h1>Find ID</h1>
          <div className='result'>
            <p>입력하신 정보와 일치하는 아이디 입니다.</p>
            <div>aa***@aaa.com</div>
            {/* <div>일치하는 정보가 없습니다.</div> */}
          </div>
          <button>로그인 하러가기</button>
          <div className='or'>
            <p className='center'>비밀번호를 잃어버리셨나요?</p>
            <button>비밀번호 찾기</button>
          </div>
        </div>
      </div>
    </main>
  )

}

export default FindIdCom