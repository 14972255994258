export const LOGIN_DEFINE = "LOGIN_DEFINE";

// 회원가입 및 아이디 비밀번호 찾기 내정보 수정 관련 Define name
export const MY_INFO_DEFINE = "MY_INFO_DEFINE";
export const MY_INFO_UPDATE_DEFINE = "MY_INFO_UPDATE_DEFINE";
export const SEND_MAIL_DEFINE = "SEND_MAIL_DEFINE";
export const AUTH_MAIL_DEFINE = "AUTH_MAIL_DEFINE";
export const JOIN_DEFINE = "JOIN_DEFINE";
export const FIND_SMS_AUTH_DEFINE = "FIND_SMS_AUTH_DEFINE";
export const FINE_SMS_AUTH_CHECK_DEFINE = "FINE_SMS_AUTH_CHECK_DEFINE";
export const MEMBER_APP_DEFINE = "MEMBER_APP_DEFINE";
export const SOCIAL_JOIN_DEFINE = "SOCIAL_JOIN_DEFINE";
export const RESET_PW_DEFINE = "RESET_PW_DEFINE";

// 기획서 관련 Define name
export const PLAN_TEMPLATE_LIST_DEFINE = "PLAN_TEMPLATE_LIST_DEFINE";
export const SAVE_PLAN_DEFINE = "SAVE_PLAN_DEFINE";

// 앱 생성관련 Define
export const APP_USE_DEFINE = "APP_USE_DEFINE";
export const CUSTOM_SIMILAR_APP_DEFINE = "CUSTOM_SIMILAR_APP_DEFINE";
export const CUSTOM_TEMPLATE_DEFINE = "CUSTOM_TEMPLATE_DEFINE"
export const CUSTOM_CAPABILITY_LIST_DEFINE = "CUSTOM_CAPABILITY_LIST_DEFINE";

export const BASIC_SIMILAR_APP_DEFINE = "BASIC_SIMILAR_APP_DEFINE";

export const APP_DETAIL_INFO = "APP_DETAIL_INFO";
export const APP_CREATE_DEFINE = "APP_CREATE_DEFINE";