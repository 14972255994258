import { APP_CREATE_DEFINE, APP_DETAIL_INFO, MEMBER_APP_DEFINE } from "../const/defineName";
import { fetchData, fetchDataPathVar } from "../util/util";

export const getAppDetailInfo = (param) => ({
    queryKey: ["appDetailInfo"],
    queryFn: async() => {
        const json = await fetchDataPathVar(APP_DETAIL_INFO, param);
        return json;
    }
})
