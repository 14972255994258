import React from 'react'

/**
 * 앱생성 튜토리얼 팝업
 * @param {event} 닫기 버튼 클릭시 적용시킬 이벤트 매개변수
 * @returns 
 */
const AppCreateTutoPopup = ({ onClick }) => {
    return (
        <div className='tutorialPopWrap on'>
            <div className='tutorialCon'>
                <div className='top d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <img src="../img/icon_tutorial.png" alt="튜토리얼 아이콘" />
                        <h2>AiApp 튜토리얼</h2>
                    </div>
                    <button className='closeBtn' onClick={onClick}><img src="../img/icon_closeB.png" alt="닫기 아이콘" /></button>
                </div>
                <div className='contentsWrap'>
                    {/* 클릭시 .list에 .on 토글 부여 / 클릭시 해당 tab 이동 후 .list 감추기 */}
                    <button className='momenu'>튜토리얼 리스트</button>
                    <div className='list'>
                        <div>
                            <h3>관리자</h3>
                            <div>
                                <h4>- 앱 관리</h4>
                                <ul>
                                    <li className='on complete'>&middot; 대표정보 수정하기</li>
                                    <li className='complete'>&middot; 회원 그룹 생성하기</li>
                                    <li>&middot; 게시판 생성하기</li>
                                    <li>&middot; 앱 관리자 권한 부여하기</li>
                                </ul>
                            </div>
                            <div>
                                <h4>- CMS</h4>
                                <ul>
                                    <li>&middot; 공지사항 작성하기</li>
                                    <li>&middot; 문의 답변하기</li>
                                    <li>&middot; 회원 관리하기</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h3>기획서</h3>
                            <div>
                                <h4>- 기획서란?</h4>
                                <ul>
                                    <li>&middot; 대표정보 수정하기</li>
                                    <li>&middot; 회원 그룹 생성하기</li>
                                    <li>&middot; 게시판 생성하기</li>
                                    <li>&middot; 앱 관리자 권한 부여하기</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='contents'>
                        <div className='imgWrap'>
                            <img src="../img/tutorial_notice.png" alt="튜토리얼 가이드 이미지" />
                        </div>
                        <div className='textWrap'>
                            <h2>대표정보 수정하기</h2>
                            <p>앱의 기초 정보를 수정하는 단계입니다. <br />상업적 목적으로 사용할 경우, 사업자 정보가 필요로 합니다. </p>
                        </div>
                        <div className='btnWrap'>
                            <button>대표정보 수정하러 가기<img src="../img/icon_link.png" alt="링크 아이콘" /></button>
                            <button className='off'>다음에 하기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppCreateTutoPopup