import React from "react";

const PersonalTerm = () => {
  return (
    <main className="bg1">
      <section>
        <div class="width14">
          <div class="sectTit">
            <h2 data-aos="fade-up" data-aos-duration="2000">
              AiApp 개인정보처리방침
            </h2>
          </div>

          <div class="boxWrap3">
            <p>
              &lt;(주)엠바스&gt;('www.aiapp.help'이하 'Ai.app')는 「개인정보
              보호법」 제30조에 따라 정보 주체의 개인정보를 보호하고 이와 관련한
              고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
              개인정보 처리방침을 수립·공개합니다.
            </p>
            <p>본 개인정보처리방침의 목차는 아래와 같습니다.</p>
            <br />
            관계법령이 요구하는 개인정보처리방침의 필수 사항과 Ai.app 자체적으로
            이용자 개인정보 보호에 있어 중요하게 판단하는 내용을 포함하였습니다.
            <br />
            <br />
            제1조(개인정보의 처리 목적)
            <br />
            제2조(개인정보의 처리 및 보유 기간)
            <br />
            제3조(처리하는 개인정보의 항목)
            <br />
            제4조(개인정보의 제3자 제공)
            <br />
            제5조(개인정보의 파기절차 및 파기방법)
            <br />
            제6조(미이용자의 개인정보 파기 등에 관한 조치)
            <br />
            제7조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)
            <br />
            제8조(개인정보의 안전성 확보조치에 관한 사항)
            <br />
            제9조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)
            <br />
            제10조(추가적인 이용·제공 판단기준)
            <br />
            제11조(개인정보 보호책임자에 관한 사항)
            <br />
            제12조(개인정보의 열람청구를 접수·처리하는 부서)
            <br />
            제13조(정보 주체의 권익침해에 대한 구제 방법)
            <br />
            제14조(개인정보 처리방침 변경)
            <br />
            <br />○ 이 개인정보처리방침은 2023년 3월 31일부터 적용됩니다.
            <br />
            <br />
            <h3>제1조(개인정보의 처리 목적)</h3>
            <br />
            &lt; (주)엠바스 &gt;('www.mbaas.kr'이하 'Ai.app')는 다음의 목적을
            위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적
            이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는
            「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한
            조치를 이행할 예정입니다.
            <br />
            <br />
            <h4>1. 홈페이지 회원가입 및 관리</h4>
            <br />
            회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증,
            회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의
            개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지,
            고충처리 목적으로 개인정보를 처리합니다.
            <br />
            <br />
            <h4>2. 민원사무 처리</h4>
            <br />
            민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지,
            처리결과 통보 목적으로 개인정보를 처리합니다.
            <br />
            <br />
            <h4>3. 재화 또는 서비스 제공</h4>
            <br />
            서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공,
            본인인증, 연령인증, 요금결제·정산, 채권추심을 목적으로 개인정보를
            처리합니다.
            <br />
            <br />
            <br />
            <h3>제2조(개인정보의 처리 및 보유 기간)</h3>
            <br />① &lt; (주)엠바스 &gt;는 법령에 따른 개인정보 보유·이용 기간
            또는 정보 주체로부터 개인정보를 수집시에 동의받은 개인정보 보유·이용
            기간 내에서 개인정보를 처리·보유합니다.
            <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
            <br />
            <br />
            <br />
            <h4>1. &lt;홈페이지 회원가입 및 관리&gt;</h4>
            <br />
            &lt;홈페이지 회원가입 및 관리&gt;와 관련한 개인정보는 수집·이용에
            관한 동의일로부터 &lt;3년&gt;까지 위 이용목적을 위하여
            보유·이용됩니다.
            <br />
            보유근거 : 신용정보의 수집/처리 및 이용 등에 관한 기록 법령에 의거
            <br />
            관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
            <br />
            예외사유 : 계약/또는 청약 철회 등에 관한 기록 법령에 의거해 계약 및
            철회 기록 5년 보유
            <br />
            <br />
            <h4>2. &lt;민원사무 처리&gt;</h4>
            <br />
            &lt;민원사무 처리&gt;와 관련한 개인정보는 수집·이용에 관한
            동의일로부터 &lt;3년&gt;까지 위 이용목적을 위하여 보유·이용됩니다.
            <br />
            보유근거 : 소비자의 불만 또는 분쟁 처리에 관한 기록 법령에 의거
            <br />
            관련법령 : 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
            <br />
            <br />
            <h4>3. &lt;재화 또는 서비스 제공&gt;</h4>
            <br />
            &lt;재화 또는 서비스 제공&gt;와 관련한 개인정보는 수집·이용에 관한
            동의일로부터 &lt;5년&gt;까지 위 이용목적을 위하여 보유·이용됩니다.
            <br />
            보유근거 : 대금결제 및 재화 등의 공급에 관한 기록 법령에 의거
            <br />
            관련법령 : 대금결제 및 재화 등의 공급에 관한 기록 : 5년
            <br />
            <br />
            <h3>제3조(처리하는 개인정보의 항목)</h3>
            <br />
            &lt; (주)엠바스 &gt;는 다음의 개인정보 항목을 처리하고 있습니다.
            <br />
            &lt; 홈페이지 회원가입 및 관리 &gt;
            <br />
            필수항목 : 이메일, 로그인ID, 비밀번호, 휴대전화번호, 이름
            <br />
            선택항목 : 회사명,직책, 부서, 회사 주소, 회사 대표 이메일,
            신용카드정보, 은행계좌정보
            <br />
            <br />
            <h3>제4조(개인정보의 제3자 제공)</h3>
            <br />① &lt; (주)엠바스 &gt;는 원칙적으로 정보주체의 개인정보를
            수집수집·이용 목적으로 명시한 범위 내에서 처리하며, 다음의 경우를
            제외하고는 정보주체의 사전 동의 없이는 본래의 목적 범위를 초과하여
            처리하거나 제3자에게 제공하지 않습니다.
            <br />
            개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만
            처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」
            제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게
            제공합니다.
            <br />② &lt; (주)엠바스 &gt;는 다음과 같이 개인정보를 제3자에게
            제공하고 있습니다.
            <br />
            1. 개인정보를 제공받는 자 : ㈜갤럭시아, ㈜오픈모바일 ㈜ OOO
            <br />
            제공받는 자의 개인정보 이용목적 : 이메일, 휴대전화번호, 로그인ID,
            생년월일, 이름, 회사전화번호, 회사명
            <br />
            제공받는 자의 보유·이용기간: 3년
            <br />
            <br />
            <h4>
              보유근거 : 소비자의 불만 또는 분쟁 처리에 관한 기록 법령에 의거
            </h4>
            <br />① &lt; (주)엠바스 &gt;는 개인정보 보유기간의 경과, 처리목적
            달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
            파기합니다.
            <br />② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나
            처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
            보존하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로
            옮기거나 보관장소를 달리하여 보존합니다.
            <br />
            1. 법령 근거 :<br />
            2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜
            <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
            <br />
            1. 파기절차
            <br />
            &lt; (주)엠바스 &gt; 은(는) 파기 사유가 발생한 개인정보를 선정하고,
            <br />
            &lt; (주)엠바스 &gt; 의 개인정보 보호책임자의 승인을 받아 개인정보를
            파기합니다.
            <br />
            2. 파기방법
            <br />
            전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
            사용합니다.
            <br />
            종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
            파기합니다.
            <br />
            <br />
            <br />
            <h3>제6조(미이용자의 개인정보 파기 등에 관한 조치)</h3>
            <br />① &lt;(주)엠바스&gt;은(는) 1년간 서비스를 이용하지 않은
            이용자는 휴면계정으로 전환하고, 개인정보를 별도로 분리하여
            보관합니다. 분리 보관된 개인정보는 1년간 보관 후 지체없이
            파기합니다.
            <br />② &lt;(주)엠바스&gt;은(는) 휴면 전환 30일 전까지 휴면예정
            회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는
            개인정보 항목을 이메일, 문자 등 이용자에게 통지 가능한 방법으로
            알리고 있습니다.
            <br />③ 휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전
            서비스 로그인을 하시면 됩니다. 또한, 휴면계정으로 전환되었더라도
            로그인을 하는 경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인
            서비스를 이용할 수 있습니다.
            <br />
            <br />
            <h3>
              제7조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한
              사항)
            </h3>
            <br />① 정보주체는 (주)엠바스에 대해 언제든지 개인정보
            열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
            <br />② 제1항에 따른 권리 행사는 (주)엠바스에 대해 「개인정보
            보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX)
            등을 통하여 하실 수 있으며 (주)엠바스는 이에 대해 지체 없이
            조치하겠습니다.
            <br />③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을
            받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리
            방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을
            제출하여야 합니다.
            <br />④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조
            제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
            <br />⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가
            수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
            <br />⑥ (주)엠바스는 정보주체 권리에 따른 열람의 요구, 정정·삭제의
            요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
            대리인인지를 확인합니다.
            <br />
            <br />
            <h3>제8조(개인정보의 안전성 확보조치에 관한 사항)</h3>
            <br />
            &lt; (주)엠바스 &gt;는 개인정보의 안전성 확보를 위해 다음과 같은
            조치를 취하고 있습니다.
            <br />
            <br />
            1. 정기적인 자체 감사 실시
            <br />
            개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체
            감사를 실시하고 있습니다.
            <br />
            <br />
            2. 개인정보 취급 직원의 최소화 및 교육
            <br />
            개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여
            개인정보를 관리하는 대책을 시행하고 있습니다.
            <br />
            <br />
            3. 내부 관리 계획의 수립 및 시행
            <br />
            개인정보의 안전한 처리를 위하여 내부 관리 계획을 수립하고 시행하고
            있습니다.
            <br />
            <br />
            4. 해킹 등에 대비한 기술적 대책
            <br />
            &lt;(주)엠바스&gt;('Ai.app')는 해킹이나 컴퓨터 바이러스 등에 의한
            개인정보 유출 및 훼손을 막기 위하여 보안 프로그램을 설치하고
            주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을
            설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
            <br />
            <br />
            5. 개인정보의 암호화
            <br />
            이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어,
            본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를
            암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을
            사용하고 있습니다.
            <br />
            <br />
            6. 접속기록의 보관 및 위변조 방지
            <br />
            개인정보 처리시스템에 접속한 기록을 최소 1년 이상 보관·관리하고
            있으며 다만, 5만 명 이상의 정보 주체에 관하여 개인정보를 추가하거나
            고유 식별정보 또는 민감정보를 처리하는 경우에는 2년 이상
            보관·관리하고 있습니다.
            <br />
            또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안 기능을
            사용하고 있습니다.
            <br />
            <br />
            7. 개인정보에 대한 접근 제한
            <br />
            개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경,
            말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고
            있으며 침입차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고
            있습니다.
            <br />
            <br />
            <h3>제9조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)</h3>
            <br />
            서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보,
            위치정보가 생성되어 수집될 수 있습니다. 또한 이미지 및 음성을 이용한
            검색 서비스 등에서 이미지나 음성이 수집될 수 있습니다.
            <br />
            구체적으로 1&#41; 서비스 이용 과정에서 이용자에 관한 정보를 자동화된
            방법으로 생성하거나 이용자가 입력한 정보를 저장(수집)하거나, 2&#41;
            이용자 기기의 고유한 정보를 원래의 값을 확인하지 못 하도록 안전하게
            변환하여 수집합니다. 또한 서비스 이용 과정 중 추후 위치정보서비스
            개설 시 위치정보가 수집될 수 있으며, 엠바스에서 제공 예정인 위치기반
            서비스에 대해서는 '위치기반서비스 이용약관'에서 자세하게 규정할
            예정입니다.
            <br />
            이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에
            해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.
            <br />
            <br />
            <h4>정보 수집에 대한 추가 설명</h4>
            <br />
            IP(Internet Protocol) 주소란?
            <br />
            IP 주소는 인터넷 망 사업자가 인터넷에 접속하는 이용자의 PC 등 기기에
            부여하는 온라인 주소정보 입니다. IP 주소가 개인정보에 해당하는지
            여부에 대해서는 각국마다 매우 다양한 견해가 있습니다.
            <br />
            서비스 이용기록이란?
            <br />
            네이버 접속 일시, 이용한 서비스 목록 및 서비스 이용 과정에서
            발생하는 정상 또는 비정상 로그 일체,메일 수발신 과정에서 기록되는
            이메일주소, 친구 초대하기 또는 선물하기 등에서 입력하는
            휴대전화번호, 스마트스토어 판매자와 구매자간 상담내역(네이버톡톡 및
            상품 Q&amp;A 게시글) 등을 의미합니다.
            <br />
            기기정보란?
            <br />본 개인정보처리방침에 기재된 기기정보는 생산 및 판매 과정에서
            기기에 부여된 정보뿐 아니라, 기기의 구동을 위해 사용되는 S/W를 통해
            확인 가능한 정보를 모두 일컫습니다. OS(Windows, MAC OS 등) 설치
            과정에서 이용자가 PC에 부여하는 컴퓨터의 이름, PC에 장착된
            주변기기의 일련번호, 스마트폰의 통신에 필요한 고유한 식별값(IMEI,
            IMSI), AAID 혹은 IDFA, 설정언어 및 설정 표준시, USIM의 통신사 코드
            등을 의미합니다. 단, 네이버는 IMEI와 같은 기기의 고유한 식별값을
            수집할 필요가 있는 경우, 이를 수집하기 전에 네이버도 원래의 값을
            알아볼 수 없는 방식으로 암호화 하여 식별성(Identifiability)을 제거한
            후에 수집합니다.
            <br />
            쿠키(cookie)란?
            <br />
            쿠키는 이용자가 웹사이트를 접속할 때에 해당 웹사이트에서 이용자의
            웹브라우저를 통해 이용자의 PC에 저장하는 매우 작은 크기의 텍스트
            파일입니다. 이후 이용자가 다시 웹사이트를 방문할 경우 웹사이트
            서버는 이용자 PC에 저장된 쿠키의 내용을 읽어 이용자가 설정한 서비스
            이용 환경을 유지하여 편리한 인터넷 서비스 이용을 가능케 합니다. 또한
            방문한 서비스 정보, 서비스 접속 시간 및 빈도, 서비스 이용 과정에서
            생성된 또는 제공(입력)한 정보 등을 분석하여 이용자의 취향과 관심에
            특화된 서비스(광고 포함)를 제공할 수 있습니다. 이용자는 쿠키에 대한
            선택권을 가지고 있으며, 웹브라우저에서 옵션을 설정함으로써 모든
            쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
            쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할
            경우에는 로그인이 필요한 네이버 일부 서비스의 이용에 불편이 있을 수
            있습니다.
            <br />
            <br />
            <h3>제10조(추가적인 이용·제공 판단기준)</h3>
            <br />
            &lt; (주)엠바스 &gt;는 ｢개인정보 보호법｣ 제15조 제3항 및 제17조
            제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을
            고려하여 정보 주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수
            있습니다.
            <br />
            이에 따라 &lt; (주)엠바스 &gt; 가(이) 정보 주체의 동의 없이 추가적인
            이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.
            <br />▶ 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집
            목적과 관련성이 있는지 여부
            <br />▶ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때
            추가적인 이용·제공에 대한 예측 가능성이 있는지 여부
            <br />▶ 개인정보의 추가적인 이용·제공이 정보 주체의 이익을 부당하게
            침해하는지 여부
            <br />▶ 가명 처리 또는 암호화 등 안전성 확보에 필요한 조치를
            하였는지 여부
            <br />※ 추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체
            스스로 자율적으로 판단하여 작성·공개함
            <br />
            <br />
            <h3>제11조 (개인정보 보호책임자에 관한 사항)</h3>
            <br />① &lt;(주)엠바스&gt;는 개인정보 처리에 관한 업무를 총괄해서
            책임지고, 개인정보 처리와 관련한 정보 주체의 불만 처리 및 피해구제
            등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
            <br />▶ 개인정보 보호 책임자
            <br />
            성명 : 최승태
            <br />
            직책 : CISO
            <br />
            직급 : 대표
            <br />
            연락처 :
            &lt;mbaas.help@gmail.com&ZeroWidthSpace;&gt;,&lt;010-7640-4707&gt;&lt;FAX
            : 050-4466-0300&gt;
            <br />※ 개인정보 보호 담당 부서로 연결됩니다.
            <br />
            <br />▶ 개인정보 보호 담당부서
            <br />
            부서명 : 개발 1팀
            <br />
            담당자 : 안준형
            <br />
            연락처 :
            &lt;mbaas.help@gmail.com&ZeroWidthSpace;&gt;,&lt;010-7640-4707&gt;&lt;FAX
            : 050-4466-0300&gt;
            <br />② 정보 주체께서는 (주)엠바스 의 서비스(또는 사업)을
            이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만 처리,
            피해구제 등에 관한 사항을 개인정보 보호 책임자 및 담당 부서로
            문의하실 수 있습니다. (주)엠바스는 정보 주체의 문의에 대해 지체없이
            답변 및 처리해드릴 것입니다.
            <br />
            <br />
            <h3>제12조(개인정보의 열람청구를 접수·처리하는 부서)</h3>
            <br />
            정보 주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를
            아래의 부서에 할 수 있습니다.
            <br />
            &lt; (주)엠바스 &gt;는 정보 주체의 개인정보 열람청구가 신속하게
            처리되도록 노력하겠습니다.
            <br />
            <br />▶ 개인정보 열람청구 접수·처리 부서
            <br />
            부서명 : 개발 1팀
            <br />
            담당자 : 안준형
            <br />
            연락처 : 010-7640-4707
            <br />
            <br />
            <h3>제13조(정보 주체의 권익침해에 대한 구제 방법)</h3>
            <br />
            정보 주체는 개인 정보 침해로 인한 구제를 받기 위하여 개인정보
            분쟁조정위원회, 한국인터넷진흥원 개인정보 침해신고센터 등에 분쟁
            해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보 침해의
            신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
            <br />
            <br />
            1. 개인정보 분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
            <br />
            2. 개인정보 침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
            <br />
            3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
            <br />
            4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
            <br />
            <br />
            「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의
            정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에
            대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는
            이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
            청구할 수 있습니다.
            <br />※ 행정심판에 대해 자세한 사항은
            중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
            <br />
            <br />
            <h3>제14조(개인정보 처리방침 변경)</h3>
            <br />① 이 개인정보처리방침은 2023년 3월 31일부터 적용됩니다
            <br />
          </div>
        </div>
      </section>
    </main>
  );
};

export default PersonalTerm;
