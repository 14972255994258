import React, { useLayoutEffect, useRef } from 'react'
import { randomNumber } from '../../util/util'

/**
 * 
 * @param {string} label 해당 input의 라벨을 작성.
 * @param {string} name 해당 input의 name을 작성해준다.
 * @param {string} type 해당 input의 type을 작성해준다.
 * @param {string} defaultValue 해당 input의 defaultValue를 작성해준다.
 * @param {string} placeholder 해당 input의 placeholder를 작성해준다.
 * @param {string} error 해당 input의 error값이 있을 경우 표출해준뒤 해당 영역으로 focus 이동.
 * @param {string} id 해당 input의 id를 작성해준다.
 * @param {boolean} readonly 해당 input의 readonly를 설정해준다.
 * @param {string} value 해당 input의 value 설정해준다.
 * @param {any} onChange 해당 input의 onChange를 설정해준다.ㅌ
 * @returns 
 */
const Input = ({label, name, type, defaultValue, placeholder, comment, error, id, readOnly, required=false, value, onChange}) => {
    const ranId = randomNumber();
    const ref = useRef(null);

    useLayoutEffect(() => {
        if(error) {
            ref.current.focus();
            ref.current.style.scrollMargin = "90px";
            ref.current.scrollIntoView();
        }
    }, [error])

    return (
        <>
        {/* 필수값은 label에 classname = required 를 붙이기 */}
            <div className='inputWrap'>
                <div className='d-flex'>
                    <input 
                        ref={ref}
                        type={type} 
                        name={name} 
                        className="form-control" 
                        id={id} 
                        placeholder={placeholder} 
                        defaultValue={defaultValue} 
                        readOnly={readOnly}
                        value={value}
                        onChange={onChange}/>
                </div>
                {error ? <p className="warning">{error}</p> : ""}
            </div>

        </>
    )
}

export default Input