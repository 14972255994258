import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ACCESS_TOKEN } from '../../const/const'

const Header = ({ isToggle, onClickToggleBtn }) => {
    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem(ACCESS_TOKEN);
        navigate("/")
    }

    
    return (
        <header>
            <nav
                className="navbar d-flex justify-content-between align-items-center width14"
            >
                <div className="navbar_logo">
                    <Link to={"/"}><img src={`${process.env.PUBLIC_URL}/img/aiapp_logoW.svg`} alt="aiapp 로고이미지" /></Link>
                </div>
                <div className="navbar_wrap d-flex gap30">
                    <ul className={`navbar_menu d-flex align-items-center gap40 ${isToggle} : 'active' : ''`}>
                        <li><Link to={"/service"}><p>서비스소개</p></Link></li>
                        <li><Link to={"/guide"}><p>앱생성가이드</p></Link></li>
                        {/* <li><Link to={"/applist"}><p>#생성앱List</p></Link></li> */}
                        <li><Link to={"/story"}><p>Story</p></Link></li>
                        {/* <li><p>요금안내</p></li> */}
                        {/* <li>
                            <Link to={"/checklist"}><button className="simple cursor">앱생성 하러가기</button></Link>
                        </li> */}
                    </ul>
                </div>
                <div className='right justify-content-end d-flex gap20'>
                    {
                        localStorage.getItem(ACCESS_TOKEN) ? <Link to={"/checklist"}><button className="simple cursor">앱생성 하러가기</button></Link> : ""
                    }
                    <div className={`navbar_icon d-flex align-items-center gap30 `}>
                        {
                            localStorage.getItem(ACCESS_TOKEN) ?
                                <>
                                    <Link to={"/info"}>
                                        <div>
                                            <img src={`${process.env.PUBLIC_URL}/img/icon_profileW.png`} alt="마이페이지 아이콘" />
                                        </div>
                                    </Link>
                                    <button onClick={logout}>
                                        <img src={`${process.env.PUBLIC_URL}/img/icon_logout.png`} alt="로그아웃 아이콘" />
                                    </button>
                                </> :
                                <Link to={"/login"}>
                                    <button className='loginbtn'>로그인</button>
                                </Link>
                            }
                        <div onClick={onClickToggleBtn} className={`navbar_toggleBtn ${isToggle ? 'active' : ''} ` }>
                            {/* 모바일 네비게이션 navbar_toggleBtn 클릭시 .active */}
                            <div className='mHeader'>
                                <div className='mTop d-flex justify-content-between align-items-center'>
                                    <div className="navbar_logo">
                                        <Link to={"/"}><img src={`${process.env.PUBLIC_URL}/img/aiapp_logoW.svg`} alt="aiapp 로고이미지" /></Link>
                                    </div>
                                    <div className='d-flex gap20'>
                                        {
                                            localStorage.getItem(ACCESS_TOKEN) ? <Link to={"/checklist"}><button className="simple cursor">앱생성 하러가기</button></Link> : ""
                                        }
                                        <div className={`navbar_icon d-flex align-items-center gap30 ${isToggle ? 'active' : ''}`}>
                                            {
                                                localStorage.getItem(ACCESS_TOKEN) ?
                                                    <>
                                                        <Link to={"/info"}>
                                                            <div>
                                                                <img src={`${process.env.PUBLIC_URL}/img/icon_profileW.png`} alt="마이페이지 아이콘" />
                                                            </div>
                                                        </Link>
                                                        <button onClick={logout}>
                                                            <img src={`${process.env.PUBLIC_URL}/img/icon_logout.png`} alt="로그아웃 아이콘" />
                                                        </button>
                                                    </> :
                                                    <Link to={"/login"}>
                                                        <button className='loginbtn'>로그인</button>
                                                    </Link>
                                            }
                                        {/* toggleBtn 누르면 .active 부여 -> 닫기 버튼 */}
                                        <div onClick={onClickToggleBtn} className={`navbar_toggleBtn ${isToggle ? 'active' : ''}`}></div>
                                    </div>
                                    </div>
                                </div>
                                <ul className='mMenu'>
                                    <li><Link to={"/service"}><p>서비스소개</p></Link></li>
                                    <li><Link to={"/guide"}><p>앱생성가이드</p></Link></li>
                                    {/* <li><Link to={"/applist"}><p>#생성앱List</p></Link></li> */}
                                    <li><Link to={"/story"}><p>Story</p></Link></li>
                                    {/* <li><p>요금안내</p></li> */}
                                    {/* <li>
                                        <Link to={"/checklist"}><button className="simple cursor">앱생성 하러가기</button></Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            </nav>
        </header>
    )
}

export default Header