import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ACCESS_TOKEN } from '../../const/const';

/**
 * 
 * @returns 로그인 이후 사용가능한 페이지들을 감싸는 컴포넌트. 해당 컴포넌트를 이용해 로그인을 하지않고 페이지에 접속하는 유저를 걸러낼 수 있다.
 */
const AuthLayout = () => {
    const navigator = useNavigate();

    // useEffect(() => {
    //     if(!localStorage.getItem(ACCESS_TOKEN)) {
    //         navigator("/")
    //     }
    // }, [])

    return (
        <>
            <Outlet />
        </>
    )
}

export default AuthLayout