import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { usePreventRefresh } from '../../hooks/usePreventRefresh';

const CheckListCustomLayout = () => {
    const location = useLocation();
    const navigator = useNavigate();
    usePreventRefresh()
    // 화면 표시 애니메이션

    return (
        <div className={"checkListReWrap"}>
            <header>
                <Link to={"/"}><button className='back'><img src="../img/icon_backB.png" alt="뒤로가기 아이콘" />메인홈</button></Link>
                <ul className={`stepWrap step${Number((location.pathname.split("/")[2]))+1}`}>
                    <li className={location.pathname.includes("/checklist") ? 'on' : ""}>
                        <div></div>
                        <h2>정보입력</h2>
                    </li>
                    <li className={location.pathname.split("/")[2] >= 1 ? 'on' : ""}>
                        <div></div>
                        <h2>앱 유형</h2>
                    </li>
                    <li className={location.pathname.split("/")[2] >= 2 ? 'on' : ""}>
                        <div></div>
                        <h2>유사앱 추천</h2>
                    </li>
                    <li className={location.pathname.split("/")[2] >= 3 ? 'on' : ""}>
                        <div></div>
                        <h2>템플릿 선택</h2>
                    </li>
                    <li className={location.pathname.split("/")[2] >= 4 ? 'on' : ""}>
                        <div></div>
                        <h2>추가기능 선택</h2>
                    </li>
                    <li className={location.pathname.split("/")[2] >= 5 ? 'on' : ""}>
                        <div></div>
                        <h2>앱 정보 입력</h2>
                    </li>
                </ul>
            </header>
            <Outlet />
        </div>
    )
}

export default CheckListCustomLayout