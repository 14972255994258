import React from "react";

const PDFScreenplan = ({ name, content, pageNum, img, modi }) => {
  return (
    <main>
      {/* 앱 페이지별 요청사항 */}
      <div className="pdfWrap"> 
        <div className="pdfHead">
          <h1>화면이름 : <span>{name}</span></h1>
          <div>페이지 번호 : <span>{pageNum}</span></div>
        </div>
        <div className="pdfCon">
          <div className="plan d-flex gap30">
            <div className="appImg">
              <img src={img} alt="앱화면  이미지" />
            </div>
            <div>
              <h2>화면 및 기능 설명</h2>
              <div>{ content }</div>
            </div>
            <div>
              <h2>수정 요청사항</h2>
              <div>{ modi }</div>
            </div>
          </div>
        </div>
        <div className="copy">Copyright &copy; 2024 mBaaS all right reserved</div>
      </div>
    </main>
  )

}
export default PDFScreenplan