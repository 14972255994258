import { PLAN_TEMPLATE_LIST_DEFINE, SAVE_PLAN_DEFINE } from "../const/defineName"
import { fetchData, fetchDataPathVar } from "../util/util"

export const planTemplateListQuery = (param) => ({
    queryKey: ["planTemplateList"],
    queryFn: async() => {
        const json = await fetchDataPathVar(PLAN_TEMPLATE_LIST_DEFINE, param);
        return json;
    }
})

export const savePlanQuery = (param) => ({
    queryKey: ["savePlan"],
    queryFn: async() => {
        const json = await fetchData(SAVE_PLAN_DEFINE, param);
        return json;
    }
})