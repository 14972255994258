import React from "react";
import { Link } from "react-router-dom";

/**
 * 비밀번호 재설정 완료 페이지
 * @returns 
 */
const FindPasswordCom = () => {
  return (
    <main>
      <div className="joinWrap">
        <div className="joinCon">
          <h1>Find Password</h1>
          <p className="nodata">비밀번호 재설정이 완료되었습니다.</p>
          <Link to={"/login"}><button>로그인 하러가기</button></Link>
          <div className="or">
            <p className="center">아이디를 잃어버리셨나요?</p>
            <Link to={"/findId"}><button>아이디 찾기</button></Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default FindPasswordCom;
