import React, { useState } from 'react'

const Service = () => {
  const [tab, setTab] = useState(0)

  const onClickTab = (idx) => {
    setTab(idx)
  }
    
  return (
    <main class="serviceInfo">
      <section class="infoWrap bg1">
        <div class="width14">
          <div class="sectTit">
            <h2 data-aos="fade-up" data-aos-duration="2000">창업자와 개인이 쉽게 앱을 만들고 발전시킬 수 있는 <br/>혁신적인 플랫폼</h2>
            <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">IT 개발 지식이 부족한 분들도 쉽게 앱을 생성가능하고, <br/>
              앱 창업을 위한 개발 대행 서비스로 <br/>
              모바일 앱 개발에 필요한 모든 과정을 도와 드립니다.</p>
          </div>
          <ul>
            <li class="container" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">
              <div class="card card1">
                <div class="imgBx">
                  <img src={`${process.env.PUBLIC_URL}/img/service01.png`} alt="서비스 이미지"/>
                </div>
                <div class="contentBx">
                  <h2>Ai 알고리즘</h2>
                  <div class="txt">
                    Aiapp의 Ai 알고리즘을 통한 앱 스토어 내 <br/>유사한 앱과 기능 분석
                  </div>
                </div>
              </div>
            </li>
            <li class="container" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1200">
              <div class="card card2">
                <div class="imgBx">
                  <img src={`${process.env.PUBLIC_URL}/img/service02.png`} alt="서비스 이미지"/>
                </div>
                <div class="contentBx">
                  <h2>전문 컨설팅</h2>
                  <div class="txt">
                    사업 분석이 어려우신 분들에게 길잡이가 되어줄 전문 컨설팅
                  </div>
                </div>
              </div>
            </li>
            <li class="container" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1400">
              <div class="card card3">
                <div class="imgBx">
                  <img src={`${process.env.PUBLIC_URL}/img/service03.png`} alt="서비스 이미지"/>
                </div>
                <div class="contentBx">
                  <h2>앱 서비스 플랫폼</h2>
                  <div class="txt">
                    정확한 분석과 성공적인 앱 개발을 위한 Ai.App의 App Service Platform
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section class="whyWrap">
        <div class="width14">
          <div class="sectTit" data-aos="fade-up" data-aos-duration="2000">
            <h2>왜 AiApp을 사용해야 할까요?</h2>
          </div>
          <div class="grid">
            <div class="item item-1">
              <div>
                <h3 data-aos="fade-up" data-aos-duration="2000">No.1 AiApp 디자인 템플릿</h3>
                <p data-aos="fade-up" data-aos-duration="2000">템플릿으로 아이디어 구체화<br/>
                디자인 시간 절감 및 개발 범위 오류 최소화<br/>
                셀프 앱 제작으로 직접 개발 검증 가능</p>
              </div>
            </div>
            <div class="item item-2" data-aos="fade-left" data-aos-duration="2000">
              <img src={`${process.env.PUBLIC_URL}/img/serviceWhy01.png`} alt="소개 이미지"/>
            </div>
            <div class="item item-3" data-aos="fade-right" data-aos-duration="2000">
              <img src={`${process.env.PUBLIC_URL}/img/serviceWhy02.png`} alt="소개 이미지"/>
            </div>
            <div class="item item-4">
              <div>
                <h3 data-aos="fade-up" data-aos-duration="2000">No.2 비대면 온라인기획서</h3>
                <p data-aos="fade-up" data-aos-duration="2000">앱 창작자의 요구사항 파악으로 이해도 상승<br/>
                구체적이고 정확한 개발 진행<br/>
                AiApp의 지속적인 피드백으로 완성도 업!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="planWrap">
        <div class="sectTit" data-aos="fade-up" data-aos-duration="2000">
          <h2>기획서가 <span>왜 필요할까요?</span></h2>
        </div>
        <ul>
          <li data-aos="fade-up" data-aos-duration="2000">첫번째. 기획서를 통해 구체적으로 어떤 기능이 필요한지 알 수 있고, 이를 토대로 개발 범위가 정해져 개발 성공 확률이 높아져요</li>
          <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100">두번째. 회원님의 아이디어와 저희의 피드백이 더해져 앱을 셀프로 검증할 수 있고, 이 과정에서 앱의 완성도가 한층 더 올라가요! </li>
          <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">세번째. 이 모든게 비대면으로 이루어져 언제든지, 어디서든 가능해요</li>
        </ul>
      </section>
      <section class="privilegeWrap bg2">
        <div class="width14">
          <div class="sectTit">
            <h2 data-aos="fade-up" data-aos-duration="2000">AiApp 회원만의 특혜</h2>
            <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">AiApp 과 함께하면 누릴 수 있는 특별한 혜택</p>
          </div>
          <ul>
            <li data-aos="fade-up" data-aos-duration="2000">
              <div class="tag">혜택 첫번째</div>
              <h3>AiApp 기반의 Backend 제공</h3>
              <p>클라우드 기반으로 한 앱 개발의 backend 영역 지원과 개발 완료 후 마켓 등록 서비스</p>
            </li>
            <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100">
              <div class="tag">혜택 두번째</div>
              <h3>다양한 부가서비스</h3>
              <p>일일히 찾아볼 필요없이 AiApp과 제휴를 맺은 전문업체의 부가서비스를 저비용, 고품질에 이용</p>
            </li>
            <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">
              <div class="tag">혜택 세번째</div>
              <h3>교차 프로모션 지원</h3>
              <p>AiApp을 통해 서비스 오픈한 앱들간의 교차 프로모션을 지원해 적은 금액으로 빠른 가입자 유도</p>
            </li>
            <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">
              <div class="tag">혜택 네번째</div>
              <h3>자유로운 앱 개발 환경 지원 <span>(예정)</span></h3>
              <p>향후 Front-end를 개방하여 유능한 개발자 분들의 자유로운 앱 개발 환경 지원</p>
            </li>
          </ul>
        </div>
      </section>
      <section class="additionWrap">
        <div class="width14">
          <div class="sectTit">
            <h2 data-aos="fade-up" data-aos-duration="2000">부가서비스도 <span>ONE-STOP</span>으로 해결</h2>
            <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">서비스 계약에서부터 개발 운영까지 모두 대신해 드리는 저렴한 one-stop 서비스 !</p>
          </div>

          <div class="tabWrap d-flex gap20 align-items-start justify-content-center">

          <div class="d-flex align-items-center justify-content-between">
            <ul class="tab" data-aos="fade-right" data-aos-duration="2000">
              <li class={`tab__item ${tab === 0 ? 'active' : ''}`} onClick={() => {onClickTab(0)}}>
                <a>카카오 비즈톡</a>
              </li>
              <li class={`tab__item ${tab === 1 ? 'active' : ''}`} onClick={() => {onClickTab(1)}}>
                <a>SMS/MMS</a>
              </li>
              <li class={`tab__item ${tab === 2 ? 'active' : ''}`} onClick={() => {onClickTab(2)}}>
                <a>PG 결제</a>
              </li>
              <li class={`tab__item ${tab === 3 ? 'active' : ''}`} onClick={() => {onClickTab(3)}}>
                <a>간편인증</a>
              </li>
              <li class={`tab__item ${tab === 4 ? 'active' : ''}`} onClick={() => {onClickTab(4)}}>
                <a>소셜로그인</a>
              </li>
              <li class={`tab__item ${tab === 5 ? 'active' : ''}`} onClick={() => {onClickTab(5)}}>
                <a>위치기반서비스</a>
              </li>
            </ul>
          </div>

          <div class="tab__content-wrapper" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="600">
            <div id="tab1" class={`tab__content ${tab === 0 ? 'active' : ''}`}>
              <div class="addBox">
                <div class="addTit" data-aos="fade-left" data-aos-duration="2000">
                  <div>
                    <h4>카카오 비즈톡</h4>
                    <p>앱 운영 시 고객에게 제공하는 정보를 카카오 메세지를 통해 빠르고 안전하게 발송할 수 있는 서비스입니다.</p>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/img/icon_kakao.svg`} alt="카카오 이미지"/>
                </div>
                <div class="addCon" data-aos="fade-left" data-aos-duration="2000">
                  <div class="conBox">
                    <h5>1. 카카오 알림톡</h5>
                    <p>주문, 예약, 결제, 배송 정보 등 정보통신망 이용 촉진 및 정보보호 등에 관한 법률 및 한국인터넷진흥원 지침상 광고성 정보의 예외로 분류되는 정보 중 일부를 카카오톡 친구 추가 여부와 관계없이 발송 가능한 ‘카카오톡 비즈메시지’</p>
                  </div>
                  <div class="conBox">
                    <h5>2. 카카오 친구톡</h5>
                    <p>전화번호로 수신자를 특정하여, 수신자가 ‘App 창작자’의 발신프로필과 친구관계인 경우에 한하여 ‘카카오톡’을 통해 메시지를 전달하는 서비스
                      <br/>1&#41; 친구톡 텍스트형 <br/>2&#41; 친구톡 이미지형 <br/>3&#41; 친구톡 와이드형</p>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab2" class={`tab__content ${tab === 1 ? 'active' : ''}`}>
              <div class="addBox">
                <div class="addTit" data-aos="fade-left" data-aos-duration="2000">
                  <div>
                    <h4>비즈메세지 서비스</h4>
                    <p>앱 운영 시 고객에게 제공하는 정보를 문자로 빠르고 아전하게 발송할 수 있는 서비스입니다.</p>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/img/sms.svg`} alt="카카오 이미지"/>
                </div>
                <div class="addCon" data-aos="fade-left" data-aos-duration="2000">
                  <div class="conBox">
                    <h5>1. SMS (Short Messaging Service) 서비스</h5>
                    <p>이동전화 단문 메세지서비스의 데이터 통신기능을 활용하여 컴퓨터 등 정보처리 능력을 가지고 있는 장치와 무선단말기 사이에 메세지를 전송해주는 서비스</p>
                  </div>
                  <div class="conBox">
                    <h5>2. LMS (Long Messaing Service) 서비스</h5>
                    <p>이동전화 장문 메시지서비스의 데이터 통신기능을 활용하여 컴퓨터 등 능력을 가지고 있는 장치와 무선단말기 사이에 "2,000Byte"이하의 장문메시지를 전송해 주는 서비스</p>
                  </div>
                  <div class="conBox">
                    <h5>3. MMS (Multimedia Messaging Service) 서비스</h5>
                    <p>이동전화 장문 메시지서비스의 데이터 통신기능을 활용하여 컴퓨터 등 능력을 가지고 있는 장치와 무선단말기 사이에 "2,000Byte"이하의 장문메시지와 이미지를 전송해 주는 서비스</p>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab3" class={`tab__content ${tab === 2 ? 'active' : ''}`}>
              <div class="addBox">
                <div class="addTit" data-aos="fade-left" data-aos-duration="2000">
                  <div>
                    <h4>빌게이트 서비스</h4>
                    <p>신용카드 결제, 휴대폰 결제, 계좌이체 결제 등 인터넷 전자상거래에 필요한 모든 지불 수단을 종합적으로 제공하는 지불 솔루션입니다.</p>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/img/card.svg`} alt="메세지 이미지"/>
                </div>
                <div class="addCon" data-aos="fade-left" data-aos-duration="2000">
                  <div class="conBox">
                    <h5>1. 신용카드 결제 서비스</h5>
                    <p>인터넷 상에서 신용카드 결제 시 발급받은 인증서를 이용하고 신용카드 정보를 암호화하여 결제하고 결제금액은 카드 이용 요금과 함께 카드 결제일에 청구되는 결제 서비스</p>
                  </div>
                  <div class="conBox">
                    <h5>2. 휴대폰 결제 서비스</h5>
                    <p>휴대폰 번호와 생년월일, 성별 입력 후 휴대폰으로 전공되는 SMS 또는 휴대폰으로 걸려오는 ARS의 인증번호를 입력하여 즉시 결제하고 결제요금은 이동통신 요금과 함께 익월 합산 청구되는 결제 서비스</p>
                  </div>
                  <div class="conBox">
                    <h5>3. 계좌이체 결제 서비스</h5>
                    <p>인터넷 상에서 은행에서 발금 받은 보안카드 및 인증서를 이용하고 결제하고 결제금액은 고객의 은행계ㅘ에서 즉시 출금되는 결제 서비스</p>
                  </div>
                  <div class="conBox">
                    <h5>4. 가상계좌 결제 서비스</h5>
                    <p>실계좌와 동일한 고객 전용 온라인 입금 계좌를 부여하고, 해당 계좌로 결제 금액을 입금하여 결제하고 결제금액을 고객의 은행계좌에서 즉시 출금되는 결제 서비스</p>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab4" class={`tab__content ${tab === 3 ? 'active' : ''}`}>
              <div class="addBox">
                <div class="addTit" data-aos="fade-left" data-aos-duration="2000">
                  <div>
                    <h4>간편인증 서비스</h4>
                    <p>공인인증서 없이 본인 확인 전화번호와 생체인증/핀 인증 등으로 간편하게 본인 인증이 가능한 인증서 서비스 (네이버, PASS, 카카오, TOSS, 페이코 등)</p>
                  </div>
                </div>
                <div class="addCon" data-aos="fade-left" data-aos-duration="2000">
                  <div class="conBox">
                    <div class="nodata">현재 서비스 준비 중 입니다.</div>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab5" class={`tab__content ${tab === 4 ? 'active' : ''}`}>
              <div class="addBox">
                <div class="addTit" data-aos="fade-left" data-aos-duration="2000">
                  <div>
                    <h4>소셜 로그인 서비스</h4>
                    <p>소셜 네트워킹 사이트의 계정 정보를 이용해 타사 애플리케이션과 플랫폼에 쉽게 로그인 또는 회원가입이 가능한 기능으로 계정 생성 필요 없이 편리한 로그인 및 등록 간소화</p>
                  </div>
                </div>
                <div class="addCon" data-aos="fade-left" data-aos-duration="2000">
                  <div class="conBox">
                    <div class="nodata">현재 서비스 준비 중 입니다.</div>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab6" class={`tab__content ${tab === 5 ? 'active' : ''}`}>
              <div class="addBox">
                <div class="addTit" data-aos="fade-left" data-aos-duration="2000">
                  <div>
                    <h4>위치 기반 서비스</h4>
                    <p>위치기반 검색, 이용자 현재 위치 주변 정보 제공, 친구 찾기, 위치기반 광고 등을 제공하는 서비스</p>
                  </div>
                </div>
                <div class="addCon" data-aos="fade-left" data-aos-duration="2000">
                  <div class="conBox">
                    <div class="nodata">현재 서비스 준비 중 입니다.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Service