import React from 'react'
import { Link } from 'react-router-dom'

const StoryDetail = () => {
  return (
    <main>
      <section class="storyConWrap bg3">
        <div class="width14">
          <div class="storydetail">
            <p>
              Notice
            </p>
            <h2 >Aiapp에 오신걸 환영합니다.</h2>
            <div class="storyTxt">
              <div className='storyImg'>
                <img src="../../img/story0.png" alt="스토리 이미지" />
              </div>
              <pre>
                <h2>앱 제작의 첫걸음 기획부터, 앱 개발까지 모두 한번에!</h2>
                <p>안녕하세요, 고객 여러분. <br/>저희 Aiapp에서 새롭게 서비스를 오픈하게 되어 기쁜 마음으로 안내드립니다.</p><br/><br/>
                <p className='bold'>서비스 오픈 일시:</p>
                <p>&#45; 2024년 5월 27일 </p><br/>
                <p className='bold'>서비스 소개 :  </p>
                <p>&#45; AiApp은 고객 여러분께 간편하고 쉽게 앱을 생성할 수 있는 서비스로, 다른 앱빌드 서비스와는 다르게 기획서를 통해 앱을 수정하여 자신만의 앱을 가지실 수 있습니다.</p> <br/>
                <p className='bold'>주요기능 :  </p>
                <ul>
                  <li>&#45; 쉽고 간편한 무료 앱생성</li>
                  <li>&#45; 다양한 템플릿</li>
                  <li>&#45; 기획서를 통한 나만의 앱제작</li>
                  <li>&#45; 앱에 필요한 다양한 부가서비스 지원</li>
                </ul>
                <br/>
                <p className='bold'>이용방법 :  </p>
                <p>홈페이지에 접속하신 후, 회원가입하고 '앱생성 하러가기' 탭을 클릭하세요.</p><br/>
                <p className='bold'>문의사항 :  </p>
                <p>서비스 이용에 관한 문의는 mbaas.help@gmail.com 으로 연락해주시기 바랍니다</p><br/><br/>
                <p className='bold'>고객 여러분의 많은 관심과 성원을 부탁드리며, AiApp을 통해 더 나은 서비스를 제공할 수 있도록 노력하겠습니다.<br/>감사합니다.</p>

              </pre>
            </div>
            <Link to={-1}><div class="listBtn" ><button>목록으로</button></div></Link>
          </div>
        </div>
      </section>
    </main>
  )
}

export default StoryDetail