import { GET_METHOD, POST_METHOD } from "../../const/const";
import { APP_CREATE_DEFINE, APP_DETAIL_INFO } from "../../const/defineName";

export const appDefine = {
    [APP_DETAIL_INFO]: {
        // /app/${memberCode}/${appCode}
        url: "/api/app",
        method: GET_METHOD,
    },
    [APP_CREATE_DEFINE]: {
        url: "/build/start",
        method: POST_METHOD
    }
}