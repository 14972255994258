import React from 'react'
import { Link, useLoaderData, useRevalidator, useSearchParams } from 'react-router-dom'
import { basicSimilarAppQuery, customSimilarAppQuery } from '../../../queries/checklistQueries'

/**
 * 해당 페이지가 랜더링 되기전 Loader가 실행되어 데이터 통신을 마친후 페이지를 랜더링 해준다.
 * @param {QueryClient} queryClient router.js에서 받아온 React-query Client
 * @returns 
 */
export const similarAppLoader =
  (queryClient) => async({ request }) => {
    const url = new URL(request.url);
    const param = { category: url.searchParams.get("n") };

    if(!url.searchParams.get("n")) {
      return await queryClient.fetchQuery(basicSimilarAppQuery({ templateCode: url.searchParams.get("s") }));
    }

    return await queryClient.fetchQuery(customSimilarAppQuery(param));
  }

const CheckList2_1 = () => {
  const [ searchParams, ] = useSearchParams();
  const { data } = useLoaderData();
  const revalidator = useRevalidator();

  return (
      <div className='checkListReWrap'>
        <main className='checkListCon'>
          <div className='wrap'>
            <div className='centerCon'>
              <h2>{ searchParams.get("n") }와/과 비슷한 앱 정보가 궁금하셨나요?<br/>Ai가 추천해드리는 유사앱 정보를 참고해보세요.</h2>
              <ul className='similarWrap'>
                {
                  data.map(d => (
                    <li key={d.code}>
                      <img src={d.icon} alt="앱아이콘 이미지" />
                      <h3>{d.name}</h3>
                      <p>{d.content}</p>
                      <div className='link'>
                        <button>앱 보러가기</button>
                        <img src="../img/icon_linkB.png" alt="링크 아이콘" />
                      </div>
                    </li>
                  ))
                }
                
              </ul>
              <button className='moreBtn' onClick={() => {revalidator.revalidate()}}>더보기 <img src="../img/icon_refreshB.png" alt="새로고침 아이콘"/></button>
            </div>
          </div>
        </main>
        <footer>
          <div className='btnWrap'>
            <Link to={-1}><button className='off'>이전</button></Link>
            <Link to={`/checklistcustom/3?${searchParams.toString()}`}><button>다음</button></Link>
          </div>
        </footer>
      </div>
  )}

  
export default CheckList2_1