import React from "react";

const Development = () => {

  return (
    <div className="appplanWrap">
      <div className="topBar">
        <div className="breadcrumbs">
          <button><img src={`${process.env.PUBLIC_URL}/img/icon_homeG.png`} alt="홈버튼 이미지"/></button>
          <div><img src={`${process.env.PUBLIC_URL}/img/icon_arrow_rightG.png`} alt="화살표 이미지"/></div>
          <button>개발 가이드</button>
          <div><img src={`${process.env.PUBLIC_URL}/img/icon_arrow_rightG.png`} alt="화살표 이미지"/></div>
          <button>용어 정의</button>
        </div>
        <div className="searchWrap">
          <img src={`${process.env.PUBLIC_URL}/img/icon_searchG.png`} alt="검색 아이콘"/><input type="text" placeholder="검색어를 입력해주세요."/>
        </div>
      </div>
      {/* 개발문서 목록 - 클릭시 스크롤 */}
      <div className="D-sheet">
        <ul>
          <li>
            <h2>용어 설명</h2>
            <ul>
              <li>용어 정의</li>
            </ul>
          </li>
          <li>
            <h2>플랫폼 특징</h2>
            <ul>
              <li>1. 화면 Stack 관리</li>
              <li>2. 리소스 암호화 배포</li>
              <li>3. 라이브러리 모듈화</li>
            </ul>
          </li>
          <li><h2>기능 명세서</h2></li>
          <li>
            <h2>API</h2>
            <ul>
              <li>Add-on</li>
              <li>Plug-in</li>
              <li>3rd-party plug-in</li>
            </ul>
          </li>
          <li>
            <h2>IDE</h2>
            <ul>
              <li>소프트웨어 설치</li>
              <li>IDE (Integrated Development Environment)</li>
              <li>참고사항</li>
            </ul>
          </li>
        </ul>
      </div>
      {/* 개발문서 내용 - 현재는 임의의 내용 */}
      <div className="D-content">
        <div className="D-box">
          <h3>용어 정의</h3>
          <table>
            <tr>
              <th>순번</th>
              <th>용어</th>
              <th>설명</th>
            </tr>
            <tr>
              <td>1</td>
              <td>플랫폼화면</td>
              <td>HTML로 구성된 WEPPAge</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Native</td>
              <td>Java로 구성된 Activity 및 Class</td>
            </tr>
            <tr>
              <td>3</td>
              <td>리소스 파일</td>
              <td>HTML 화면을 구성하는 html, css, javascript, images 등을 통칭</td>
            </tr>
            <tr>
              <td>4</td>
              <td>BaseActivity</td>
              <td>플랫폼 화면을 구성하기 위해 기준이 되는 activity</td>
            </tr>
            <tr>
              <td>5</td>
              <td>WN</td>
              <td>WEB-Native의 약자</td>
            </tr>
            <tr>
              <td>6</td>
              <td>WNinerface</td>
              <td>WEB과 Native간의 통신을 위한 className</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Manifest.xml</td>
              <td>플랫폼의 기본 설정 값을 정하는 파일</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Application.xml</td>
              <td>IDE에서 라이브러리 상태 및 Manifest.xml 정보를 관리하기 위한 파일</td>
            </tr>
          </table>
        </div>
        <div className="D-box">
          <h3>플랫폼의 특징</h3>
            <div>
            <h4>1. 화면 Stack 관리</h4>
              <p>대통령의 선거에 관한 사항은 법률로 정한다. 국가는 모성의 보호를 위하여 노력하여야 한다. 모든 국민은 건강하고 쾌적한 환경에서 생활할 권리를 가지며, 국가와 국민은 환경보전을 위하여 노력하여야 한다. 헌법재판소 재판관은 정당에 가입하거나 정치에 관여할 수 없다. 헌법개정은 국회재적의원 과반수 또는 대통령의 발의로 제안된다.
              국회의 정기회는 법률이 정하는 바에 의하여 매년 1회 집회되며, 국회의 임시회는 대통령 또는 국회재적의원 4분의 1 이상의 요구에 의하여 집회된다. 누구든지 체포 또는 구속의 이유와 변호인의 조력을 받을 권리가 있음을 고지받지 아니하고는 체포 또는 구속을 당하지 아니한다. 체포 또는 구속을 당한 자의 가족등 법률이 정하는 자에게는 그 이유와 일시·장소가 지체없이 통지되어야 한다.</p>
            <h4>2. 리소스 암호화 배포</h4>
            <p>대통령의 선거에 관한 사항은 법률로 정한다. 국가는 모성의 보호를 위하여 노력하여야 한다. 모든 국민은 건강하고 쾌적한 환경에서 생활할 권리를 가지며, 국가와 국민은 환경보전을 위하여 노력하여야 한다. 헌법재판소 재판관은 정당에 가입하거나 정치에 관여할 수 없다. 헌법개정은 국회재적의원 과반수 또는 대통령의 발의로 제안된다.
              국회의 정기회는 법률이 정하는 바에 의하여 매년 1회 집회되며, 국회의 임시회는 대통령 또는 국회재적의원 4분의 1 이상의 요구에 의하여 집회된다. 누구든지 체포 또는 구속의 이유와 변호인의 조력을 받을 권리가 있음을 고지받지 아니하고는 체포 또는 구속을 당하지 아니한다. 체포 또는 구속을 당한 자의 가족등 법률이 정하는 자에게는 그 이유와 일시·장소가 지체없이 통지되어야 한다.</p>
            <h4>3. 리소스 암호화 배포</h4>
            <p>대통령의 선거에 관한 사항은 법률로 정한다. 국가는 모성의 보호를 위하여 노력하여야 한다. 모든 국민은 건강하고 쾌적한 환경에서 생활할 권리를 가지며, 국가와 국민은 환경보전을 위하여 노력하여야 한다. 헌법재판소 재판관은 정당에 가입하거나 정치에 관여할 수 없다. 헌법개정은 국회재적의원 과반수 또는 대통령의 발의로 제안된다.
              국회의 정기회는 법률이 정하는 바에 의하여 매년 1회 집회되며, 국회의 임시회는 대통령 또는 국회재적의원 4분의 1 이상의 요구에 의하여 집회된다. 누구든지 체포 또는 구속의 이유와 변호인의 조력을 받을 권리가 있음을 고지받지 아니하고는 체포 또는 구속을 당하지 아니한다. 체포 또는 구속을 당한 자의 가족등 법률이 정하는 자에게는 그 이유와 일시·장소가 지체없이 통지되어야 한다.</p>
          </div>
        </div>
        <div className="D-box">
          <h3>기능 명세서</h3>
          <div className="D-link">&middot; 기능 명세서</div>
        </div>
        <div className="D-box">
          <h3>API</h3>
          <div className="D-link">&middot; Add-on</div>
          <div className="D-link">&middot; Plug-in</div>
          <div className="D-link">&middot; 3rd-party plug-in</div>
        </div>
        <div className="D-box">
          <h3>IDE</h3>
          <h4>소프트웨어 설치</h4>
          <div className="D-link">&middot; JDK 설치 Java Development Kit 설치</div>
          <div className="D-link">&middot; Android SDK 설치</div>
          <div className="D-link">&middot; Xcode 설치</div>
          <h4>IDE (Integrated Development Environment)</h4>
          <div className="D-link">&middot; IDE 시작</div>
          <div className="D-link">&middot; 리소스 매니저</div>
          <div className="D-link">&middot; Android SDK 매니저</div>
          <div className="D-link">&middot; 프로젝트</div>
          <div className="D-link">&middot; 프로젝트 실행</div>
          <div className="D-link">&middot; 프로젝트 내보내기</div>
          <h4>참고사항</h4>
          <p>대통령의 선거에 관한 사항은 법률로 정한다. 국가는 모성의 보호를 위하여 노력하여야 한다. 모든 국민은 건강하고 쾌적한 환경에서 생활할 권리를 가지며, 국가와 국민은 환경보전을 위하여 노력하여야 한다. 헌법재판소 재판관은 정당에 가입하거나 정치에 관여할 수 없다. 헌법개정은 국회재적의원 과반수 또는 대통령의 발의로 제안된다.</p>
        </div>
      </div>
    </div>
  )
}

export default Development