import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick';
import { RemoveNextArrow, RemovePrevArrow } from '../atom/removeSlickArrow';

const Tab1 = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <RemoveNextArrow />,
        prevArrow: <RemovePrevArrow />
    };

    let rankSliderRef = useRef(null);
    const next = () => {
        rankSliderRef.slickNext();
    };
    const previous = () => {
        rankSliderRef.slickPrev();
    };

    const rankSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <RemoveNextArrow />,
        prevArrow: <RemovePrevArrow />
    };

    const rateSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        nextArrow: <RemoveNextArrow />,
        prevArrow: <RemovePrevArrow />
    };

    return (
        <div className="tab__content-wrapper" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="600">
            <div id="tab1" className="tab__content active">
                <div className="AdWrap">
                    <Slider {...settings}>
                        <div className="AdBox AdBox01">
                            <div className="fBox">AD</div>
                            <div className="AdBg">
                                <img src="../img/AdBg01.png" alt="광고이미지" />
                                <div></div>
                            </div>
                            <div className="AdCon">
                                <div className="AdTxt">
                                    <h2>새로운 기술을 배우고 취미를 키우세요!</h2>
                                    <p>
                                        여러분을 위한 완벽한 학습 플랫폼, 원데이 클래스 앱을
                                        소개합니다. 이제 여러분은 집에서도 쉽게 배우실 수
                                        있습니다.
                                    </p>
                                </div>
                                <div className="AdApp">
                                    <div className="sBox">
                                        <div className="AdAppImg">
                                            <img src="../img/appicon.svg" alt="앱 아이콘" />
                                        </div>
                                        <div>
                                            <h3>M클래스</h3>
                                            <p>mbass inc.</p>
                                        </div>
                                    </div>
                                    <button>설치하기</button>
                                </div>
                            </div>
                        </div>
                        <div className="AdBox AdBox02">
                            <div className="fBox">AD</div>
                            <div className="AdBg">
                                <img src="../img/AdBg02.png" alt="광고이미지" />
                                <div></div>
                            </div>
                            <div className="AdCon">
                                <div className="AdTxt">
                                    <h2>
                                        프리마켓 커뮤니티 앱을 통해 지역 커뮤니티와 연결되세요!
                                    </h2>
                                    <p>
                                        우리의 프리마켓 커뮤니티 앱은 지역 사람들과 손쉽게
                                        연결되고 중고물품을 거래할 수 있는 편리한 플랫폼입니다.
                                        이제 당신이 필요로 하는 물건을 얻거나 더 이상 필요 없...
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="AdBox AdBox01">
                            <div className="fBox">AD</div>
                            <div className="AdBg">
                                <img src="../img/AdBg01.png" alt="광고이미지" />
                                <div></div>
                            </div>
                            <div className="AdCon">
                                <div className="AdTxt">
                                    <h2>새로운 기술을 배우고 취미를 키우세요!</h2>
                                    <p>
                                        여러분을 위한 완벽한 학습 플랫폼, 원데이 클래스 앱을
                                        소개합니다. 이제 여러분은 집에서도 쉽게 배우실 수
                                        있습니다.
                                    </p>
                                </div>
                                <div className="AdApp">
                                    <div className="sBox">
                                        <div className="AdAppImg">
                                            <img src="../img/appicon.svg" alt="앱 아이콘" />
                                        </div>
                                        <div>
                                            <h3>M클래스</h3>
                                            <p>mbass inc.</p>
                                        </div>
                                    </div>
                                    <button>설치하기</button>
                                </div>
                            </div>
                        </div>
                        <div className="AdBox AdBox02">
                            <div className="fBox">AD</div>
                            <div className="AdBg">
                                <img src="../img/AdBg02.png" alt="광고이미지" />
                                <div></div>
                            </div>
                            <div className="AdCon">
                                <div className="AdTxt">
                                    <h2>
                                        프리마켓 커뮤니티 앱을 통해 지역 커뮤니티와 연결되세요!
                                    </h2>
                                    <p>
                                        우리의 프리마켓 커뮤니티 앱은 지역 사람들과 손쉽게
                                        연결되고 중고물품을 거래할 수 있는 편리한 플랫폼입니다.
                                        이제 당신이 필요로 하는 물건을 얻거나 더 이상 필요 없...
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
                <div className="rankWrap">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center gap10">
                            <img src="../img/icon_ranking.png" alt="랭킹 아이콘" />
                            <h2>인기 차트 TOP</h2>
                        </div>
                        <button>더보기 {">"}</button>
                    </div>
                    <div className="indicaotr d-flex" data-aos="fade-up" data-aos-duration="2000">
                        <span className="prev cursor" onClick={previous}></span>
                        <span className="next cursor" onClick={next}></span>
                    </div>
                    <div className="listWrap listWrap01">
                        <Slider 
                            ref={slider => {
                                rankSliderRef = slider;
                            }}
                            {...rankSettings}>
                            <div className='listWrap'>
                                <div className="listCon">
                                    <div className="d-flex align-items-center gap20">
                                        <span>1</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>2</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>3</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>4</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>5</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>6</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>7</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>8</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>9</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- 10~18위 --> */}
                            <div className='listWrap'>
                                <div className="listCon">
                                    <div className="d-flex align-items-center gap20">
                                        <span>10</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>11</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>12</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>13</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>14</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>15</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>16</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>17</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap20">
                                        <span>18</span>
                                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                                        <div>
                                            <h3>앱 이름</h3>
                                            <p>카테고리</p>
                                            <div className="d-flex star">
                                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className="newWrap">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center gap10">
                            <img src="../img/icon_new.png" alt="신규 아이콘" />
                            <h2>신규 App List</h2>
                        </div>
                        <button>더보기 {">"}</button>
                    </div>
                    <div className="listWrap listWrap02">
                        <Slider {...rateSettings}>
                            <div className="listCon">
                                <div className="newimg">
                                    <img src="../img/appicon.svg" alt="앱 아이콘 이미지" />
                                </div>
                                <h3>앱 이름</h3>
                                <p>카테고리</p>
                                <div className="d-flex star">
                                    <img src="../img/icon_star.svg" alt="별점 아이콘" />
                                    <p>4.8</p>
                                </div>
                            </div>
                            <div className="listCon">
                                <div className="newimg">
                                    <img src="../img/appicon.svg" alt="앱 아이콘 이미지" />
                                </div>
                                <h3>앱 이름</h3>
                                <p>카테고리</p>
                                <div className="d-flex star">
                                    <img src="../img/icon_star.svg" alt="별점 아이콘" />
                                    <p>4.8</p>
                                </div>
                            </div>
                            <div className="listCon">
                                <div className="newimg">
                                    <img src="../img/appicon.svg" alt="앱 아이콘 이미지" />
                                </div>
                                <h3>앱 이름</h3>
                                <p>카테고리</p>
                                <div className="d-flex star">
                                    <img src="../img/icon_star.svg" alt="별점 아이콘" />
                                    <p>4.8</p>
                                </div>
                            </div>
                            <div className="listCon">
                                <div className="newimg">
                                    <img src="../img/appicon.svg" alt="앱 아이콘 이미지" />
                                </div>
                                <h3>앱 이름</h3>
                                <p>카테고리</p>
                                <div className="d-flex star">
                                    <img src="../img/icon_star.svg" alt="별점 아이콘" />
                                    <p>4.8</p>
                                </div>
                            </div>                <div className="listCon">
                                <div className="newimg">
                                    <img src="../img/appicon.svg" alt="앱 아이콘 이미지" />
                                </div>
                                <h3>앱 이름</h3>
                                <p>카테고리</p>
                                <div className="d-flex star">
                                    <img src="../img/icon_star.svg" alt="별점 아이콘" />
                                    <p>4.8</p>
                                </div>
                            </div>
                            <div className="listCon">
                                <div className="newimg">
                                    <img src="../img/appicon.svg" alt="앱 아이콘 이미지" />
                                </div>
                                <h3>앱 이름</h3>
                                <p>카테고리</p>
                                <div className="d-flex star">
                                    <img src="../img/icon_star.svg" alt="별점 아이콘" />
                                    <p>4.8</p>
                                </div>
                            </div>
                            <div className="listCon">
                                <div className="newimg">
                                    <img src="../img/appicon.svg" alt="앱 아이콘 이미지" />
                                </div>
                                <h3>앱 이름</h3>
                                <p>카테고리</p>
                                <div className="d-flex star">
                                    <img src="../img/icon_star.svg" alt="별점 아이콘" />
                                    <p>4.8</p>
                                </div>
                            </div>
                            <div className="listCon">
                                <div className="newimg">
                                    <img src="../img/appicon.svg" alt="앱 아이콘 이미지" />
                                </div>
                                <h3>앱 이름</h3>
                                <p>카테고리</p>
                                <div className="d-flex star">
                                    <img src="../img/icon_star.svg" alt="별점 아이콘" />
                                    <p>4.8</p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Tab2 = () => {
    return (
        <div id="tab2" className="tab__content active">
            <ul className="appList">
                <li>
                    <div className="d-flex align-items-center gap20">
                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                        <div>
                            <h3>앱 이름</h3>
                            <p>카테고리</p>
                            <div className="d-flex star">
                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="d-flex align-items-center gap20">
                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                        <div>
                            <h3>앱 이름</h3>
                            <p>카테고리</p>
                            <div className="d-flex star">
                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="d-flex align-items-center gap20">
                        <img src="../img/appicon.svg" alt="앱아이콘 이미지" />
                        <div>
                            <h3>앱 이름</h3>
                            <p>카테고리</p>
                            <div className="d-flex star">
                                <img src="../img/icon_star.svg" alt="별점 아이콘" />4.8
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            {/* <div className="pageWrap">
            <ul>
              <li><img src="../img/icon_arrow_leftW.png" alt="페이지 왼쪽 화살표 이미지"></li>
              <li className="on">1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li><img src="../img/icon_arrow_rightW.png" alt="페이지 오른쪽 화살표 이미지"></li>
            </ul>
          </div> */}
        </div>
    )
}

const Tab3 = () => {
    return (
        <div id="tab3" className="tab__content active">
            <div className="nodata">생성된 앱이 없습니다.</div>
        </div>
    )
}

const Tab4 = () => {
    return (
        <div id="tab4" className="tab__content active">
            <div className="nodata">생성된 앱이 없습니다.</div>
        </div>
    )
}

const Tab5 = () => {
    return (
        <div id="tab5" className="tab__content active">
            <div className="nodata">생성된 앱이 없습니다.</div>
        </div>
    )
}

const renderTabs = (currTab) => {
    switch (currTab) {
        case 0:
            return <Tab1 />
        case 1:
            return <Tab2 />
        case 2:
            return <Tab3 />
        case 3:
            return <Tab4 />
        case 4:
            return <Tab5 />
        default:
            return <Tab1 />
    }
}

const AppList = () => {
    const [currTab, setCurrTab] = useState(0)
    const tabList = [
        {
            name: "전체",
            herf: "",
        },
        {
            name: "커뮤니티 유형",
            herf: "",
        },
        {
            name: "결재 유형",
            herf: "",
        },
        {
            name: "홍보 유형",
            herf: "",
        },
        {
            name: "예약 유형",
            herf: "",
        },
    ]

    return (
        <main className='bg2'>
            <div className="width14 height10">
                <div className="d-flex align-items-center justify-content-between">
                    <ul
                        className="tab"
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        data-aos-delay="100">
                        {
                            tabList.map((tab, i) => (
                                <li
                                    key={i}
                                    className={`tab__item ${i === currTab ? 'active' : ''}`}
                                    onClick={() => { setCurrTab(i) }}
                                >
                                    <span>{tab.name}</span>
                                </li>
                            ))
                        }
                    </ul>
                    <div
                        className="searchWrap"
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        data-aos-delay="400"
                    >
                        <select name="" id="">
                            <option value="name">앱 이름</option>
                            <option value="contents">내용</option>
                        </select>
                        <input type="text" placeholder="검색어를 입력해주세요." /><button>
                            <img src="../img/icon_search.png" alt="검색아이콘" />
                        </button>
                    </div>
                </div>
                {renderTabs(currTab)}
            </div>
        </main>
    )
}

export default AppList