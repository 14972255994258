import React from "react";

const Bookcover = ({ appName }) => {
  return (
    <main>
      <div className="pdfWrap"> 
        <div className="cover">
          <h1><span>{ appName }</span><br/>앱 개발 프로젝트 기획서</h1>
          <p># 앱 개발이 어려운 분들을 위한 전문 솔루션</p>
        </div>
        <div className="copy">Copyright &copy; 2024 mBaaS all right reserved</div>
      </div>
    </main>
  )

}
export default Bookcover