import React, { useEffect, useRef } from 'react'
import { useToastPopup } from '../../../hooks/useToastPopup'

const ToastPopup = ({ id, message, type }) => {
    const timeRef = useRef(null);
    const toastHook = useToastPopup();

    useEffect(() => {
        timeRef.current = setTimeout(
            () => {
                toastHook.removeToastPopup(id);
            }, 3000)

        return () => clearTimeout(timeRef.current);
    }, [])

    return (
        <>
            {/* 토스트팝업 - 성공 success / 경고 warning / 에러 error */}
            <div className={`toastPop on ${type}`} role='alert'>
                <p>{ message }</p>
            </div>
        </>
    )
}

export default ToastPopup