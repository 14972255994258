import React from 'react'
import { Link } from 'react-router-dom'

const AppCreate = () => {
  return (
    <div className='checkListReWrap'>
      <main className='checkListCon'>
        <div className='wrap d-flex align-items-center'>
          <div className='centerCon complete'>
            <h2>앱 생성이 진행중입니다!</h2>
            <p>마이페이지에서 QR 코드를 카메라로 촬영하여 앱을 다운로드 받은 후 해당 앱을 테스트해 보실 수 있습니다!<br />
            (현재는 안드로이드 기반 OS에서만 테스트가 가능합니다.)</p>
            <div className='btnWrap'>
              <Link to={"/info"}><button className='off'>마이페이지 이동</button></Link>
              {/* <button>튜토리얼 진행하기</button> */}
            </div>
          </div>
        </div>
      </main>
    </div>
  )}

  
export default AppCreate