import React from "react";

const PDFScreenflow = () => {

  return (
    <main>
      <div className="pdfWrap"> 
      <div className="pdfHead">
          <h1>화면흐름도 (Screen Flow)</h1>
          <div>페이지 번호 : <span>2</span></div>
        </div>
        <div className="pdfCon">
          <div className="screenflowImg">
            <img src="../img/screenflow_church.png" alt="교회앱 화면흐름도 이미지" />
          </div>
        </div>
        <div className="copy">Copyright &copy; 2024 mBaaS all right reserved</div>
      </div>
    </main>
  )

}
export default PDFScreenflow