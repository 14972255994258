import React from "react";

const PDFHistory = () => {

  return (
    <main>
      <div className="pdfWrap"> 
      <div className="pdfHead">
          <h1>문서이력 (History)</h1>
          <div>페이지 번호 : <span>1</span></div>
        </div>
        <div className="pdfCon">
          <table className="history">
            <thead>
              <th>버전</th>
              <th>변경일자</th>
              <th>내용</th>
              <th>비고</th>
            </thead>
            <tbody>
              <tr>
                <td>v 0.1</td>
                <td>YYYY.MM.DD</td>
                <td>최초 작성</td>
                <td></td>
              </tr>
              <tr>
                <td>v 0.2</td>
                <td>YYYY.MM.DD</td>
                <td>중간 저장</td>
                <td></td>
              </tr>
              <tr>
                <td>v 0.3</td>
                <td>YYYY.MM.DD</td>
                <td>중간 저장</td>
                <td></td>
              </tr>
              <tr>
                <td>v 1.0</td>
                <td>YYYY.MM.DD</td>
                <td>제출하기</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="copy">Copyright &copy; 2024 mBaaS all right reserved</div>
      </div>
    </main>
  )

}
export default PDFHistory