import React from 'react'

const QrCodeDownloadPopup = ({ isOpen, setIsOpen }) => {
    return (
        <div className="popupWrap" style={{display: isOpen ? "block" : "none"}}>
            <div className="popupBox">
                <div className="popTit d-flex justify-content-between align-items-center">
                    <h2>앱 다운로드는 어떻게 하나요?</h2>
                    <div className="closeBtn" onClick={() => {setIsOpen(false)}}><img src="../../img/icon_closeB.png" alt="닫기 버튼"/></div>
                </div>
                <div>
                    앱 다운로드 QR 코드는 2차원 매트릭스 형태로 이루어진 정보 표시 방법으로 <span>카메라 앱 혹은 네이버의 QR코드 인식카메라를 통해 이미지를 촬영하면 앱 다운로드 링크</span>를 받으실 수 있습니다.<br /><br />
                    <h4>Tip. 사용방법</h4>
                    1. 카메라앱 혹은 네이버앱의 QR 바코드을 실행합니다. <br />
                    2. 카메라로 QR 코드를 촬영해주세요. <br />
                    3. 상단에 뜨는 링크를 클릭해주세요. <br />
                    4. 링크를 클릭하면 .apk 파일을 다운로드 후 설치해주세요. <br />
                    - 구글 스토어나 앱 스토어에 정식적으로 등록되지 않은 apk 파일 다운로드시 안전하지 않은 앱 혹은 출처를 알 수 없는 앱이라는 경고창이 뜹니다.<br />
                    <span>- 테스트 중의 앱은 스토어에 등록되지 않은 상태임으로 해당 경고창을 볼 수 있습니다.</span> <br /><br />
                    <img src="../../img/appdownload.png" alt="QR 코드로 앱 다운로드하는 방법 이미지"/>
                </div>
            </div>
        </div>
    )
}

export default QrCodeDownloadPopup