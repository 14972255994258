import React, { useEffect, useState } from "react";
import { Form, useActionData, useLocation, useOutletContext } from "react-router-dom";
import { savePlanQuery } from "../../../queries/planQueries";
import { makeParamsFromFormData, randomNumber } from "../../../util/util";
import { POST_METHOD } from "../../../const/const";
import { useToastPopup } from "../../../hooks/useToastPopup";

export const planAction =
  (queryClient) => async ({ request }) => {
    const param = makeParamsFromFormData(await request.formData());
    const newParam = {
      appCode: param.appCode,
      appProposalListDto: [
        param
      ]
    }
    const json = await queryClient.fetchQuery(savePlanQuery(newParam));

    if(json.result === "SUCCESS") {
      return randomNumber();
    } else {
      return "fail";
    }
  }

const Plan = () => {
  // planLayout에 있는 Outlet을 통해 Props전달
  const { data, appCode } = useOutletContext();
  const location = useLocation();
  const [modi, setModi] = useState(data.modificationRequirements ?? "")
  const toastHook = useToastPopup();
  let actionResult = useActionData();

  useEffect(() => {
    console.log(modi)
  }, [modi])

  useEffect(() => {
    setModi(data.modificationRequirements ?? "")
  }, [location.pathname]);

  useEffect(() => {
    if(!actionResult) return;

    if(actionResult !== "success") {
      toastHook.addToastPopup("수정사항이 저장되었습니다.", "success");
    } else {
      toastHook.addToastPopup("수정사항에 문제가 발생했습니다.", "error");
    }
  }, [actionResult])

  return (
    <>
      <Form method={POST_METHOD}>
        {
          Object.entries(data).map(([key, value]) => (
            key !== "modificationRequirements" ? <input key={key} type="hidden" name={key} value={value}/> : ""
          ))
        }
        <input type="hidden" name="appCode" value={appCode}/>
        <div className="bottom">
          <button>현재 페이지 저장하기</button>
        </div>
        <div className="tab_content-wrapper"> {/* tab contents */}
          <div id="tab1" className="tab_content active"> {/* tab1 */}
            <div className="d-flex gap30">
              <div>
                <div className="tit"><h3>{data.appProposalName}</h3></div>
                <div className="appImg">
                  <img src={data.appProposalImageUrl} alt="앱 이미지" />
                </div>
              </div>
              <div>
                <div className="tit d-flex align-items-center justify-content-between">
                  <h3>화면 및 기능 설명</h3>
                </div>
                <div className="sbox">{data.appProposalContent}</div>
              </div>
              <div>
                <ul className="tit d-flex align-items-center gap10"> {/* tab menu */}
                  <li id="con1" className="tab_item2 on"> {/* tab2-1 */}
                    <a href="#con2-1">
                      <button onClick={(e) => {e.preventDefault();}}><h3>수정 요청사항</h3></button>
                    </a>
                  </li>
                  <li id="con2" className="tab_item2"> {/* tab2-2 */}
                    <a href="#con2-2">
                      <button onClick={(e) => {e.preventDefault()}} className="d-flex align-items-center tooltip">
                        <h3>앱코드 생성</h3>
                        <img src={`${process.env.PUBLIC_URL}/img/icon_infoW.png`} alt="설명 아이콘" />
                        <div className="tooltiptext tooltip-bottom">
                          <h2>앱코드 생성이란?</h2>
                          <p>입력하신 수정사항(프롬프트)를 통해 앱 코드가 어떻게 진행되는지 확인하실 수 있으며, 추후 자동화를 위해 러닝을 위한 자료가 됩니다.</p>
                        </div>
                      </button>
                    </a>
                  </li>
                </ul>
                <div> {/* tab content */}
                  <div id="con2-1" className="tab_content2 active">
                    <textarea 
                      className="sbox" 
                      type="text" 
                      value={modi}
                      onChange={(e) => {setModi(e.target.value)}}
                      // defaultValue={data.modificationRequirements ?? ""}
                      name="modificationRequirements"
                      placeholder="이미지의 화면에서 수정하고 싶은 내용을 상세히 작성해주세요. &#13;&#10;&#13;&#10; ex&#41; 게시판 상세로 들어가는게 아니라 FAQ 같은 토글 방식으로 클릭시 하단에 공지사항을 볼 수 있게해주세요.버튼 배경 컬러를 #303030 으로 수정해 주세요. "></textarea>
                  </div>
                  <div id="con2-2" className="tab_content2">
                    <div className="appcodeWrap">
                      <div className="top d-flex align-items-center justify-content-between">
                        <div>
                          <button onClick={(e) => {e.preventDefault()}}>코드 생성하기</button>
                          <span>프레임워크 : Fluuter</span>
                        </div>
                        <button className="copyBtn"><img src="img/icon_copy.png" alt="복사하기 아이콘" />Copy Code</button>
                      </div>
                      <textarea className="appcode" placeholder="수정 요청사항에 수정하고 싶은 내용을 작성하고 ‘코드 생성하기’를 눌러주세요"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}

export default Plan