import React from "react";

const ServiceTerm = () => {
  return (
    <main className="bg1">
      <section>
        <div class="width14">
          <div class="sectTit">
            <h2 data-aos="fade-up" data-aos-duration="2000">
              AiApp 서비스 이용약관
            </h2>
          </div>

          <div
            class="boxWrap3"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-delay="300"
          >
            <p>본 개인정보처리방침의 목차는 아래와 같습니다.</p>
            <br />
            관계법령이 요구하는 개인정보처리방침의 필수 사항과 Ai.app 자체적으로
            이용자 개인정보 보호에 있어 중요하게 판단하는 내용을 포함하였습니다.
            <br />
            <br />
            <br />
            <h4>제1장 총칙</h4>
            <br />
            제1조(목적)
            <br />
            제2조(정의)
            <br />
            제3조(약관의 명시)
            <br />
            제4조(약관의 동의)
            <br />
            제5조(약관의 변경)
            <br />
            제6조(약관의 해석)
            <br />
            <br />
            <h4>제2장 이용계약의 체결 및 약관 동의</h4>
            <br />
            제7조(이용신청 및 방법)
            <br />
            제8조(이용신청의 승낙과 거절)
            <br />
            <br />
            <h4>제3장 계약 당사자의 의무</h4>
            <br />
            제9조(Ai.app의 의무)
            <br />
            제10조(앱 창작자의 의무)
            <br />
            <br />
            <h4>제4장 서비스의 이용</h4>
            <br />
            제11조(서비스의 제공 및 변경)
            <br />
            제12조(서비스 이용 요금)
            <br />
            제13조(이용요금의 청구와 지급)
            <br />
            제14조(이용요금의 정산 및 반환)
            <br />
            제15조(서비스 이용의 제한 및 정지)
            <br />
            제16조(서비스 제공의 중단)
            <br />
            <br />
            <h4>제5장 서비스의 이용기간 및 종료</h4>
            <br />
            제17조(서비스 이용기간 자동갱신)
            <br />
            제18조(앱 창작자의 서비스 해제 및 해지)
            <br />
            제19조(Ai.app의 서비스 해제 및 해지)
            <br />
            <br />
            <h4>제6장 Ai.app의 면책 등</h4>
            <br />
            제20조(Ai.app의 면책)
            <br />
            제21조(앱 창작자에 대한 통지)
            <br />
            제22조(양도 등의 제한)
            <br />
            제23조(준거법)
            <br />
            <br />
            <h3>제1장 총칙</h3>
            <br />
            <br />
            <h4>제1조(목적)</h4>
            <br />이 앱 창작자 이용약관(이하 “본 약관”)은 주식회사 엠바스(이하
            “Ai.app”)가 제공하는 클라우드 기반 앱 개발 플랫폼 솔루션 “Ai.app” 및
            부가서비스(이하 “본 서비스”)의 이용과 관련하여, Ai.app과 본 서비스를
            이용하고자 하는 사업자(이하 “앱 창작자”) 간의 계약 관계에서 발생하는
            권리와 의무, 그 밖에 필요한 기본적인 사항을 규정함을 목적으로
            합니다.
            <br />
            <br />
            <h4>제2조(정의)</h4>
            <br />본 약관에서 사용하는 용어의 정의는 아래와 같습니다.
            <br />① “본 서비스”라 함은 Ai.app이 제공하는 클라우드 기반의 앱 개발
            플랫폼 솔루션을 말합니다.
            <br />② “Ai.app”이라 함은 본 서비스를 제공하는 사업자로서, 본
            약관에서는 주식회사 엠바스를 지칭합니다.
            <br />③ “앱 창작자”라 함은 본 약관 상의 이용신청 절차에 따라
            Ai.app과 유료 혹은 무료 서비스 이용계약을 체결하고 본 서비스를
            이용하는 예비창업자, 개인사업자, 혹은 법인사업자를 말합니다.
            <br />④ “유료 서비스”라 함은 Ai.app이 제공하는 부가서비스 중 앱
            창작자의 필요한 기능에 따라 이용기간 단위로 요금을 지불한 경우
            이용할 수 있는 서비스를 의미합니다.
            <br />⑤ “정기결제”라 함은 “유료 서비스”를 일정 시기 및 일정 조건
            하에서 반복적으로 거래하기 위하여 미리 등록한 결제수단으로 특정일에
            자동으로 결제하는 결제 형태를 말합니다.
            <br />⑥ “정기결제형 유료 서비스”라 함은 유료 서비스 중 정기결제
            형태로 결제가 이루어지고 이용기간이 자동으로 갱신되는 서비스를
            말합니다.
            <br />⑦ "서비스 이용계약"이라 함은 유료 서비스 이용을 위하여
            Ai.app과 앱 창작자 간 상호 동의하여 체결하는 개별 계약을 말합니다.
            <br />⑧ “최종이용자”라 함은 회원가입을 완료한 앱 창작자가 본 서비스
            내에 개설한 앱에 초대하여 회원가입 및 합류를 완료하고 본 서비스를
            이용하는 개인을 말합니다.
            <br />⑨ “앱 창작자 데이터”라 함은 앱 창작자 및 최종이용자가 Ai.app의
            정보통신자원에 제출, 기록, 업로드 등의 형식으로 저장하는 정보로서 앱
            창작자가 소유 또는 관리하는 정보를 말합니다.
            <br />⑩ "최종이용자 정보"라 함은 이름, 이메일 주소, 전화번호 등의
            개인정보 등 본 서비스를 이용하는 특정 최종이용자를 식별할 수 있는
            정보를 말합니다.
            <br />⑪ “결제정보"라 함은 유료 서비스를 사용하는 앱 창작자의 서비스
            이용요금 지급과 관련하여 결제에 관련된 정보로, 결제 수단, 결제 내역
            등을 말합니다.
            <br />⑫ "개별약관"이라 함은 본 서비스에 관하여 본 약관과는 별도로
            ‘약관’, 가이드라인', ‘정책’, ‘방침’ 등의 명칭으로 Ai.app이 배포 또는
            게시한 문서를 말합니다.
            <br />
            <br />
            <h4>제3조(약관의 명시)</h4>
            <br />① ㈜엠바스는 앱 창작자가 본 약관, 개별약관의 내용과 ㈜엠바스의
            상호, 회사소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 쉽게 알
            수 있도록 Ai.app이 운영하는 웹사이트 내의 적절한 장소에 게시합니다.
            <br />② ㈜엠바스는 앱 창작자가 Ai.app과 이 약관의 내용에 관하여 질의
            및 응답을 할 수 있도록 적절한 절차를 마련하여야 합니다.
            <br />
            <br />
            <h4>제4조(약관의 동의)</h4>
            <br />① 앱 창작자는 본 약관의 규정에 따라 본 서비스를 이용해야
            합니다. 앱 창작자는 회원가입시 본 약관과 개별약관에 대해 유효한
            동의를 했을 경우에 한하여 본 서비스를 이용할 수 있습니다. 앱
            창작자가 회원가입 후 본 약관의 내용을 확인하고 본 서비스를 실제로
            이용하거나 개발 앱의 최종 가입자가 실제로 이용하도록 하는 경우에도
            본 약관에 동의하는 것으로 간주됩니다.
            <br />② Ai.app은 본 약관 이외에 별도의 이용약관 및 정책을 둘 수
            있으며, 해당 내용이 본 약관과 상충할 경우에는 개별약관이 우선
            적용되며, 다만 개별약관이 규율하지 않는 부분에 대해서는 본 약관이
            적용됩니다.
            <br />
            <br />
            <h4>제 5조(약관의 변경)</h4>
            <br />① Ai.app은 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진
            및 정보보호 등에 관한 법률」 등 관련 법령을 위배하지 않는 범위에서
            본 약관을 개정할 수 있습니다.
            <br />② Ai.app이 본 약관을 개정할 경우에는 적용일자 및 개정사유를
            명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자
            7일 전부터 적용일자 전일까지 공지합니다. 다만, 앱 창작자에게 불리한
            약관의 개정의 경우에는 적용일자 30일 전에 공지하며, 이와 별도로
            개정약관의 내용을 앱 창작자가 등록한 이메일 또는 서비스 내 기능을
            통해 개별적으로 통지한다.
            <br />③ 앱 창작자가 전항에 따라 개정약관을 공지 또는 통지한 후에 앱
            창작자가 30일 기간 내에 명시적으로 거부의 의사표시를 하지 않은 경우
            약관의 개정사항에 대해 동의한 것으로 간주하며, 변경된 약관에 대한
            정보를 알지 못하여 발생하는 앱 창작자의 피해에 대해 Ai.app는 책임을
            지지 않습니다.
            <br />
            <br />
            <h4>제6조(약관의 해석)</h4>
            <br />본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는
            「클라우드컴퓨팅 발전 및 이용자 보호에 관한 법률」, 「개인정보
            보호법」, 「신용정보의 이용 및 보호에 관한 법률」, 「약관의 규제에
            관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」과
            관계 법령 또는 상관습에 따릅니다.
            <br />
            <br />
            <h3>제2장 이용계약의 체결 및 약관 동의</h3>
            <br />
            <br />
            <h4>제7조(이용신청 및 방법)</h4>
            <br />① 앱 창작자는 Ai.app”이 정한 이용신청 양식에 따라 본 서비스
            제공에 필요한 필수 정보를 기입한 후 본 약관의 내용에 대하여
            동의한다는 의사표시를 하여 본 서비스의 이용을 신청합니다. Ai.app은
            이용신청에 필요한 개인정보의 항목과 그 처리목적을 앱 창작자가 확인할
            수 있도록 개인정보 처리방침에 안내합니다.
            <br />② 앱 창작자는 제1항의 신청을 할 때에 본인의 실명 및 실제
            정보를 기재하여야 합니다. 앱 창작자가 타인 또는 타사의 명의를
            도용하거나 거짓 정보를 기재한 경우에는 본 약관에서 정한 권리를
            주장할 수 없고, Ai.app은 이를 이유로 본 서비스 이용계약을 해제하거나
            해지할 수 있습니다.
            <br />③ 14세 미만의 아동은 본 서비스를 이용할 수 없으며 14세 미만의
            아동이 아닌 미성년자가 이용할 경우에는 법정대리인의 동의를 얻어야
            하고, 구체적인 동의 절차는 Ai.app이 제공하는 방법에 따르며 이에 따라
            발생하는 문제에 대하여 Ai.app은 책임지지 않습니다.
            <br />④ 유료 서비스를 이용하고자 하는 앱 창작자는 유료 서비스 요금제
            및 결제 방법을 선택하고 결제정보를 입력하거나 개별 계약을
            체결함으로써 유료 서비스의 이용을 신청할 수 있습니다.
            <br />
            <br />
            <h4>제8조(이용신청의 승낙과 거절)</h4>
            <br />① 서비스 이용계약은 앱 창작자가 Ai.app에게 이용신청을 하고,
            Ai.app의 승낙의 통지가 앱 창작자에게 도달한 때에 성립합다. 다만, 앱
            창작자가 본 서비스 이용을 위하여 Ai.app과 본 14조 약관과 별도의
            개별계약을 체결한 경우, 계약의 성립시기는 별도의 서면·전자 계약서가
            양측에 의해 날인되어 양측 모두 원본 혹은 사본을 교부받은 때로
            합니다.
            <br />② Ai.app은 원칙적으로 앱 창작작자의 이용신청을 승낙합니다. 단,
            다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙하지 않을
            수 있습니다.
            <br />
            1. 제7조(이용신청 및 방법) 제1항에 위반하여 이용을 신청한 경우
            <br />
            2. 앱 창작자가 이용요금을 납부하지 않은 경우
            <br />
            3. 앱 창작자가 Ai.app과 체결한 계약의 중대한 내용을 위반한 사실이
            있는 경우
            <br />
            4. 14세 미만의 아동으로 확인된 경우
            <br />
            5. 14세미만의 아동이 아닌 미성년자가 법정대리인의 동의를 받지
            않았거나 동의를 받은 사실을 확인할 수 없는 경우
            <br />
            6. 타인·타사의 신용카드, 유·무선 전화, 은행 계좌 등을 무단으로
            이용하거나 도용하여 서비스 이용요금을 결제하는 경우
            <br />
            7. 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「저작권법」,
            「개인정보 보호법」 및 그 밖의 관계 법령에서 금지하는 위법행위를 할
            목적으로 이용신청을 하는 경우
            <br />
            8. 앱 창작자가 이 계약에 의하여 이전에 앱 창작자의 자격을 상실한
            사실이 있는 경우
            <br />
            9. 그 밖에 제1호에서 제7호까지에 준하는 사유로서 승낙하는 것이
            상당히 부적절하다고 판단되는 경우
            <br />③ Ai.app은 다음 각 호의 어느 하나에 해당하는 경우에는 그
            사유가 해소될 때까지 승낙을 유보할 수 있다.
            <br />그 밖에 제1호 또는 제2호에 준하는 사유로서 이용신청의 승낙이
            곤란한 경우
            <br />④ 앱 창작자는 이용신청 시 기재한 필수정보가 변경되었을 경우
            이메일, 고객센터 등을 통하여 그 내용을 Ai.app에게 지체 없이 알려야
            하며, 그 필수정보가 개인 정보에 해당하는 경우 본 서비스 내 계정 설정
            페이지를 통하여 직접 수정하여야 합니다.
            <br />
            <br />
            <h3>제3장 계약 당사자의 의무</h3>
            <br />
            <br />
            <h4>제9조(Ai.app의 의무)</h4>
            <br />① Ai.app은 「클라우드컴퓨팅 발전 및 이용자 보호에 관한 법률」
            등 관련 법령을 준수하고, 앱 창작자가 서비스를 원활히 이용하고
            최종이용자의 개인정보를 보호할 수 있도록 정보보호에 관한 기준을
            갖추어야 합니다.
            <br />② Ai.app은 안정적인 서비스 제공을 위하여 정기적인 운영 점검을
            실시할 수 있고, 이를 사전에 앱 창작자에게 통지하여야 합니다.
            <br />③ Ai.app은 장애로 인하여 정상적인 서비스가 어려운 경우에 이를
            신속하게 수리 및 복구하고, 신속한 처리가 곤란한 경우에는 그 사유와
            일정을 앱 창작자에게 통지하여야 합니다.
            <br />④ Ai.app은 적절한 수준의 보안을 제공하여야 하며, 개인정보의
            유출 또는 제3자로부터의 권리 침해를 방지할 의무가 있습니다.
            <br />⑤ Ai.app은 앱 창작자가 본 서비스의 이용현황 및 대금내역을 알기
            쉽게 확인할 수 있도록 조치하여야 합니다.
            <br />
            <br />
            <h4>제10조(앱 창작자의 의무)</h4>
            <br />① 앱 창작자는 서비스를 이용하는 과정에서 본 약관 및 개별약관,
            저작권법 등 관련 법령을 위반하거나 선량한 풍속, 기타 사회질서에
            반하는 행위를 하여서는 아니 됩니다.
            <br />② 유료 서비스를 사용하는 앱 창작자는 이용계약에서 정한 날까지
            요금을 납부하여야 하고, 연락처, 요금결제 수단 등 거래에 필요한
            정보가 변경된 때에는 그 사실을 Ai.app에게 지체 없이 알려야 합니다.
            <br />③ Ai.app은 아이디와 비밀번호 등 서비스 접속정보에 대한
            관리책임이 있으며, 앱 창작자 혹은 최종이용자의 주의의무 위반으로
            인한 최종이용자 정보의 도용에 대해서는 Ai.app이 책임을 지지 않는다.
            <br />④ 앱 창작자는 이 계약의 규정, 이용안내 및 서비스와 관련하여
            Ai.app으로부터 통지받은 제반사항을 확인하고, 합의된 사항을
            준수하여야 합니다
            <br />
            <br />
            <h3>제4장 서비스의 이용</h3>
            <br />
            <br />
            <h4>제11조(서비스의 제공 및 변경)</h4>
            <br />① Ai.app은 앱 창작자에게 이용계약에 따른 서비스를 제공하여야
            하며, 그 내용 또는 이행수준은 이용신청시 앱 창작자가 선택한 요금제가
            제공하는 서비스 내용에 따릅니다.
            <br />② Ai.app은 서비스의 내용 또는 그 이행수준 변경 시그 내용이 앱
            창작자에게 불리하거나 중요한 경우에는 앱 창작자의 동의를 얻어야
            합니다.
            <br />③ Ai.app은 서비스의 원활한 사용을 위해 앱 창작자에게 제품,
            신규 기능, 활용 방법 등에 대한 정보성 안내를 이메일 등으로 제공할 수
            있습니다.
            <br />④ Ai.app과 앱 창작자는 서로 간의 문의나 요청에 대응하기 위해
            이를 처리하는 담당부서 및 담당자의 이름과 연락처를 정하여 알려주어야
            합니다.
            <br />
            담당부서 : 마케팅 팀<br />
            담당자 : 김소원
            <br />
            이메일 및 번호 : &lt;mbaas.help@gmail.com&ZeroWidthSpace;&gt;,
            &lt;010-7640-4707&gt; &lt;FAX : 050-4466-0300&gt;
            <br />
            <br />
            <h4>제12조(서비스 이용 요금)</h4>
            <br />① 앱 창작자는 유료 서비스 이용 신청시 선택한 부가서비스에 따라
            매 이용 기간의 이용료를 지급하여야 한다. Ai.app은 요금제의 종류,
            단가, 과금 방식, 정기 결제 방식 등 이용요금의 세부 내역을 홈페이지에
            게시한다.
            <br />② 유료 서비스에는 「콘텐츠산업진흥법」,
            「전자상거래등에서의소비자보호에관한법률」등에서 규정하는 청약철회가
            불가능한 서비스가 포함되어 있으므로, 별도의 계약서에 명시되지 않은
            한 앱 창작자의 청약철회권이 제한되며, 앱 창작자의 일방적인 계정 탈퇴
            또는 프로그램 삭제 등에도 불구하고 이용요금 지급의무는 소멸하지
            않는다.
            <br />③ 앱 창작자는 서비스 이용요금에 관한 모든 세금에 대한 책임이
            있다.
            <br />④ Ai.app은 본 서비스 제공을 위하여 앱 창작자와 별도의
            개별계약을 체결할 수 있고, 별도의 개별계약이 다르게 명시하지 않는
            한, 이용계약의 종료일에 상관없이 Ai.app의 가격정책 변동에 따라
            요금이 인상될 시 즉시 다음 청구 기간에 반영한다.
            <br />⑤ 서비스 사용료는 템플릿 기반 앱 생성 후 3개월 까지는
            무료이며, 3개월 이후부터 사용료가 청구된다.
            <br />
            <br />
            <h4>제13조(이용요금의 청구와 지급)</h4>
            <br />① Ai.app은 과금 시점을 기준으로 이용기간 동안 발생할 예정인
            이용요금을 청구하고 지급청구서 또는 전자세금계산서를 발송하여야
            한다.
            <br />② 앱 창작자는 지급청구서 혹은 전자세금계산서의 내용에 이의가
            없으면 기재된 지급기일까지 청구된 요금을 지급하여야 한다. 다만 앱
            창작자의 책임 없는 사유로 서비스를 이용하지 못한 경우에는 그 기간
            동안의 이용요금 지급의무를 면한다
            <br />③ 앱 창작자는 청구된 이용요금에 이의가 있으면 청구서가 도달한
            날로부터 4일 이내에 이의를 신청할 수 있고, Ai.app은 이의신청을
            접수한 날로부터 3일 이내에 그 처리결과를 앱 창작자에게 통지하여야
            한다.
            <br />④ 앱 창작자는 지급과 관련하여 앱 창작자가 입력한 정보가
            정확한지 여부를 확인해야 하며, 입력 정보와 관련하여 발생한 책임과
            불이익은 앱 창작자가 전적으로 부담한다.
            <br />⑤ Ai.app과 고의 및 중대한 과실이 있거나 Ai.app이 인정한 사유에
            의한 경우를 제외하고 이용요금 미납으로 인해 발생하는 모든 문제에
            대한 책임은 앱 창작자에게 있다.
            <br />
            <br />
            제13조의 2(결제수단)
            <br />① 유료 서비스에서 사용할 수 있는 이용요금 결제수단은 다음 각
            호와 같다.
            <br />
            1. 카드결제
            <br />
            2. 현금결제
            <br />② 전항에도 불구하고, 정기결제형 유료서비스의 이용을 신청하는
            앱 창작자가 사용할 수 있는 이용요금 결제수단은 신용카드를 이용한
            결제로 제한된다. 앱 창작자는 정기결제를 위하여 서비스에
            결제수단(신용카드) 및 결제 정보를 입력하고 이를 저장할 수 있으며,
            저장된 결제수단 및 결제 정보는 정기결제만을 위한 목적으로 사용된다.
            <br />③ 앱 창작자는 타인의 결제수단을 임의로 사용할 수 없다. 타인의
            결제수단을 임의 사용함으로써 발생하는 Ai.app, 결제수단의 적법한
            소유자, 기타 해당 결제와 관련된 제3자의 손실이나 손해에 대한 책임은
            전부 앱 창작자에게 있다.
            <br />④ 유료서비스 이용을 위한 결제를 위하여 앱 창작자가 입력한
            정보가 부정확하여 발생한 문제에 대한 책임과 불이익은 앱 창작자가
            전적으로 부담하여야 한다.
            <br />⑤ 앱 창작자는 유료서비스 이용요금 결제 시 정당하고, 적법한
            사용권한을 가지고 있는 결제수단을 사용하여야 하며, Ai.app은 그
            여부를 확인할 수 있으며, 앱 창작자가 사용한 결제 수단의 적법성 등에
            대한 확인이 완료될 때까지 거래 진행을 중지하거나 해당 거래를 취소할
            수 있다.
            <br />
            <br />
            제13조의 3(정기결제 및 정기결제형 유료서비스의 제공)
            <br />① 앱 창작자는 정기결제형 유료서비스를 이용하기 위하여 전조
            제2항에 따라 해당 서비스에 결제수단 및 결제 정보를 입력하고
            저장하여야 하며, 그에 따라 이용요금이 정기결제일(정기결제형
            유료서비스의 최초 결제일 또는 앱 창작자가 임의로 지정한 날짜를
            의미하며, 이하 동일하다)에 자동으로 결제된다. 단, 특정 월에
            정기결제일에 해당하는 날짜가 존재하지 않을 경우 해당 월에 한하여
            말일에 결제가 진행된다.
            <br />② Ai.app은 정기결제가 이루어지면 회원에게 결제 내용을 제13조에
            따른 방법으로 고지한다.
            <br />③ 등록된 결제수단의 유효기간 만료 등 여하의 사유로
            정기결제일에 이용요금의 결제가 정상적으로 이루어지지 않는 경우
            “정기결제 유료서비스”의 이용이 중단될 수 있으며, 이러한 상황이
            지속되는 경우 채무이행의 거절로 보고 Ai.app이 “유료 서비스”
            이용계약을 해지할 수 있다.
            <br />
            <br />
            <h4>제14조(이용요금의 정산 및 반환)</h4>
            <br />① Ai.app은 앱 창작자가 이용요금을 과·오납한 때에는 이를
            반환하거나 다음 달 이용금액에서 정산하여야 하고, 앱 창작자가
            이용요금을 체납한 때에는 최초 납입기일 다음날부터 완납시까지
            지체일당 체납금액의 (1,000)분의 (3)을 곱하여 가산금을 징수할 수
            있다.
            <br />② 앱 창작자가 Ai.app의 귀책 사유로 인한 서비스의 중대한 장애로
            인하여 서비스를 사용할 수 없는 경우 이미 요금이 납부된 상황일 시
            Ai.app에게 장애 발생 시점부터 장애해결 후 정상 이용이 가능해진
            시점까지 이용요금의 반환을 청구할 수 있다.
            <br />
            <br />
            <h4>제15조(서비스 이용의 제한 및 정지)</h4>
            <br />① Ai.app은 다음 각 호의 어느 하나에 해당하는 경우에 서비스
            이용을 제한 혹은 정지할 수 있으며, 그 사유가 해소되면 지체 없이
            서비스 제공을 재개하여야 한다.
            <br />
            1. 앱 창작자가 정당한 사유 없이 이용요금을 연체하여 체납금 및
            가산금의 이행을 최고받은 후 14일 이내에 이를 납입하지 않는 경우
            <br />
            2. 앱 창작자 혹은 최종이용자가 제3자에게 서비스를 임의로 제공하는
            경우
            <br />
            3. 앱 창작자 혹은 최종이용자가 시스템 운영이나 네트워크 보안 등에
            심각한 장애 혹은 전자적 침해행위로 데이터의 손상, 서버정지 등을
            초래하거나 그밖에 이 계약의 규정에 위반하거나 할 우려가 있는 행위를
            한 경우
            <br />
            4. 기타 관련 법령에 위반하거나 Ai.app의 업무를 방해하는 행위를 하는
            경우
            <br />② Ai.app은 제1항 1호에 따른 서비스를 정지하기 전 (14)일까지 그
            사실을 앱 창작자에게 통지하고 이의신청의 기회를 주어야 한다. 다만,
            앱 창작자의 책임 있는 사유로 통지를 할 수 없는 때에는 그러하지
            아니하다.
            <br />③ Ai.app은 제1항 2, 3, 4호에 따른 서비스 이용 제한 혹은 정지는
            사전 통지 없이 진행할 수 있으며, Ai.app은 진행 후 그 사실을 앱
            창작에게 지체 없이 통지하여야 한다.
            <br />④ Ai.app이 제1항 각 호에 따라 서비스를 정지한 경우에는 특별한
            사유가 없으면 앱 창작자가 그 기간 동안의 이용요금을 납부하여야 한다.
            <br />
            <br />
            <h4>제16조(서비스 제공의 중단)</h4>
            <br />① Ai.app은 다음 각 호의 어느 하나에 해당하는 경우에 서비스
            제공을 중단할 수 있으며, 그 사유가 해소되면 지체 없이 서비스 제공을
            재개하여야 한다.
            <br />
            1. 서비스 개선을 위한 시스템 개선, 설비의 증설·보수·점검, 시설의
            관리 및 운용 등의 사유로 부득이하게 서비스를 제공할 수 없는 경우
            <br />
            2. 해킹 등 전자적 침해 사고나 통신사고 등 예상하지 못한 서비스의
            불안정성에 대응하기 위하여 필요한 경우
            <br />
            3. 천재지변, 정전, 서비스 설비의 장애 등으로 인하여 정상적인 서비스
            제공이 불가능한 경우
            <br />② Ai.app은 제1항에 따라 서비스를 중단할 수 있으나, 중단 후에는
            지체 없이 그 사실을 앱 창작자에게 통지하여야 한다.
            <br />③ 제2항에 따른 통지에는 중단기간이 포함되어야 하고, Ai.app이
            그 기간을 초과한 경우에는 이용요금에서 초과기간에 대한 금액을
            공제한다.
            <br />④ 앱 창작자가 제1항 각호에 정한 사유의 발생에 대하여 책임이
            없는 경우에는 중단기간 동안의 이용요금에 대한 납부의무를 면하거나
            중단기간만큼 이용기간을 추가 부여받을 수 있다.
            <br />
            <br />
            <h3>제5장 서비스의 이용기간 및 종료</h3>
            <br />
            <br />
            <h4>제17조(서비스 이용기간 자동갱신)</h4>
            <br />① 본 부가서비스의 이용 기간은 앱 창작자가 Ai.app과 개별계약
            체결 시 선택한 요금제 및 이용 기간에 따른다.
            <br />
            <br />
            <h4>제18조(앱 창작자의 서비스 해제 및 해지)</h4>
            <br />① 앱 창작자는 다음 각 호의 어느 하나에 해당하는 사유가 있는
            경우에는 해당 서비스를 처음 제공받은 날부터 3월 이내 또는 그 사실을
            알았거나 알 수 있었던 날부터 30일 이내에 이 계약을 해제할 수 있다.
            <br />
            1. 이 계약에서 약정한 서비스가 제공되지 않는 경우
            <br />
            2. 제공되는 서비스가 표시 · 광고 등과 상이하거나 현저한 차이가 있는
            경우
            <br />
            3. 그밖에 서비스의 결함으로 정상적인 이용이 불가능하거나 현저히
            곤란한 경우
            <br />② 앱창작자는 다음 각 호의 어느 하나에 해당하는 경우에 계약을
            해지할 수 있다.
            <br />
            1. Ai.app이 서비스 제공 중에 파산 등의 사유로 계약상의 의무를 이행할
            수 없거나 그 의무의 이행이 현저히 곤란하게 된 경우
            <br />
            2. Ai.app이 약정한 서비스계약의 내용에 따른 서비스 제공을 다하지
            않는 경우
            <br />③ 제1항과 제2항에 따라 계약을 해지하고자 하는 때에는
            Ai.app에게 해지 예정일 (30)일 전까지 그 사유를 통지하고 이의 신청의
            기회를 주어야 한다. 다만 Ai.app의 책임 있는 사유로 통지를 할 수 없는
            때에는 사전 통지와 이의신청의 기회 제공을 면한다.
            <br />④ 앱 창작자가 본 조에 따라 유료 서비스 이용계약을 해제 또는
            해지하는 경우 Ai.app은 앱 창작자에게 해지예정일로부터 남은 기간
            동안의 이용요금을 일할계산하여 환불한다.
            <br />
            <br />
            <h4>제19조(Ai.app의 서비스 해제 및 해지)</h4>
            <br />① Ai.app은 다음 각 호의 어느 하나에 해당하는 경우에 계약을
            해제할 수 있다.
            <br />
            1. Ai.app이 서비스를 개시하여도 앱 창작자가 계약의 목적을 달성할 수
            없는 경우
            <br />
            2. 계약체결 후 서비스가 제공되기 전에 앱 창작자 파산 등의 사유로
            계약상의 의무를 이행할 수 없거나 그 의무의 이행이 현저히 곤란하게 된
            경우
            <br />② Ai.app은 다음 각 호의 어느 하나에 해당하는 경우에 계약을
            해지할 수 있다.
            <br />
            1. 앱 창작자가 제10조(앱 창작자의 의무)에서 정한 앱 창작자의 의무를
            위반한 경우 혹은 다음 각 목의 어느 하나에 해당하는 경우
            <br />- 가. 앱 창작자가 서비스 이용을 정지당한 후 월 이용요금을
            기준으로 (3)회 이상 이용요금의 지급을 연체한 경우
            <br />- 나. 앱 창작자가 Ai.app의 동의 없이 계약상의 권리 및 의무를
            제3자에게 처분한 경우
            <br />
            2. 제14조(서비스 이용의 제한 및 정지)에 따라 서비스의 이용이 제한된
            앱 창작자가 상당한 기간 동안 해당 사유를 해소하지 않는 경우
            <br />
            3. 사업의 종료에 따라 서비스를 종료하는 경우
            <br />③ Ai.app이 제2항에 따라 계약을 해지하고자 하는 때에는 앱
            창작자에게 (30)일 전까지 그 사유를 통지하고 이의신청의 기회를 주어야
            한다. 다만, 앱 창작자의 책임 있는 사유로 통지를 할 수 없는 때에는
            사전통지와 이의신청의 기회제공을 면한다.
            <br />④ Ai.app은 앱 창작자가 고의 또는 중대한 과실로 Ai.app에게
            손해를 입힌 경우에는 사전 통지 없이 계약을 해지할 수 있으며,
            Ai.app은 해지 후 그 사실을 앱 창작자에게 지체 없이 통지하여야 한다.
            <br />⑤ 제2항 제3호 및 제4항에 따른 계약 해지는 앱 창작자에 대한
            손해배상의 청구에 영향을 미치지 아니한다.
            <br />⑥ Ai.app이 계약을 해지하는 경우에는 앱 창작자에게 서면,
            전자우편 또는 이에 준하는 방법으로 다음 각 호의 사항을 통지하여야
            한다.
            <br />
            1. 해지사유
            <br />
            2. 해지일
            <br />
            3. 환급비용
            <br />
            <br />
            <h3>제7장 Ai.app의 면책 등</h3>
            <br />
            <h4>제20조(Ai.app의 면책)</h4>
            <br />① Ai.app은 본 서비스를 “있는 그대로 (As Is)” 제공하며 앱
            창작자가 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에
            대하여 책임을 지지 않는다.
            <br />② Ai.app은 Ai.app의 고의 및 중대한 과실이 없는 한 다음 각
            호에서 정의한 손해에 대하여 어떠한 책임도 부담하지 않는다.
            <br />
            1. 무료체험서비스, 테스트, 시범 운영 서비스 등 Ai.app에게 비용을
            지불하지 않은 서비스의 이용으로 인한 손해
            <br />
            2. 최종이용자 간, 앱 창작자와 최종이용자 간, 최종이용자와 제3자 간,
            앱 창작자와 제3자 간에 발생한 분쟁으로 인한 손해
            <br />
            3. 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해
            <br />
            4. Ai.app의 통제범위를 벗어난 외부 네트워크 및 장비 등으로 인하여
            발생한 손해
            <br />
            5. Ai.app이 제 17 조 제 1 항의 사유로 서비스를 제한·중단하여 발생한
            손해
            <br />
            6. 앱 창작자 및 최종사용자의 귀책사유로 인하여 발생한 손해
            <br />
            7. 제 3자가 서비스의 접속 및 전송을 방해하거나 서비스를 중단시켜
            발생한 손해
            <br />
            8. 제 3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해
            <br />
            9. 제 3자에 의한 앱 창작자 및 최종이용자의 계정 또는 Ai.app 서버에
            대한 승인되지 않은 접속 및 이용을 원인으로 발생한 손해
            <br />
            10. 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해
            <br />
            11. 앱 창작자의 고의 또는 과실이 없는 사유로 인해 발생한 손해
            <br />③ 관련 법령에 의하여 허용되는 최대한의 범위 내에서, Ai.app,
            Ai.app 및 그 제휴사의 임원, 이사, 파트너, 직원, 대리인 및 고문
            (통칭하여 “Ai.app 측”)은 비록 그러한 손실 또는 손해의 가능성에 앞서
            조언을 하였더라도 본 서비스의 이용과 관련한 상실이익, 상실수입,
            상실예금 기타 간접적, 징벌적, 예외적 손해에 대하여 어떠한 방식으로도
            책임을 부담하지 않는다.
            <br />
            <br />
            <h4>제21조(앱 창작자에 대한 통지)</h4>
            <br />① Ai.app은 다음 각 호의 어느 하나에 해당하는 사유가 발생한
            경우에는 앱 창작자가 미리 지정한 전화 또는 휴대전화로 문자메시지
            또는 우편(전자우편 포함)의 발신, 서비스 접속화면 게시 등의 방법으로
            앱 창작자에게 알려야 한다.
            <br />
            1. 침해사고
            <br />
            2. 최종이용자 정보의 유출
            <br />
            3. 서비스의 중단
            <br />
            4. 서비스의 종료
            <br />
            5. 그밖에 앱 창작자의 서비스 이용에 중대한 영향을 미치는 사항
            <br />② Ai.app은 제1항 각 호 중 어느 하나에 해당하는 사유가 발생한
            경우에는 그 사실을 지체 없이 앱 창작자에게 알려야 한다. 다만, 다음
            각 호의 경우는 예외로 한다.
            <br />
            1. (14)일 전에 사전 예고를 하고 서비스를 중단한 경우
            <br />
            2. 30일 전에 서비스를 변경하거나 종료하도록 한 경우
            <br />
            3. 30일 전에 사업을 폐지하거나 종료하도록 한 경우
            <br />③ Ai.app은 제1항 제1호에서 제3호까지의 사유가 발생한 경우에
            지체 없이 다음 각 호의 사항을 해당 앱 창작자에게 알려야 한다. 다만,
            제2호의 발생 원인을 바로 알기 어려운 경우에는 나머지 사항을 먼저
            알리고, 발생 원인이 확인되면 이를 지체 없이 해당 앱 창작자에게
            알려야 한다.
            <br />
            1. 발생내용
            <br />
            2. 발생원인
            <br />
            3. Ai.app의 피해 확산 방지 조치 현황
            <br />
            4. 앱 창작자의 피해예방 또는 확산방지방법
            <br />
            5. 담당부서 및 연락처
            <br />
            담당부서 : 마케팅 팀 <br />
            담당자 : 김소원
            <br />
            이메일 및 번호 : &lt;mbaas.help@gmail.com&ZeroWidthSpace;&gt;,
            &lt;010-7640-4707&gt; &lt;FAX : 050-4466-0300&gt;
            <br />
            <h4>제22조(양도 등의 제한)</h4>
            Ai.app와 앱 창작자는 이 계약에 따른 권리와 의무의 전부 또는 일부를
            상대방의 사전 동의 없이 제3자에게 양도 또는 담보로 제공할 수 없다.
            앱 창작자가 최고관리자 권한을 다른 사내 담당자에게 양도하는 것은
            계약의 권리와 의무 양도와 관계 없다.
            <br />
            <h4>제23조(준거법)</h4>이 계약의 성립, 효력, 해석 및 이행과
            관련하여서는 대한민국법을 적용한다.
          </div>
        </div>
      </section>
    </main>
  );
};

export default ServiceTerm;
