import React from 'react'
import { Link } from 'react-router-dom'

/**
 * 
 * @returns 해당 화면에 대한 서버는 추후에 개발 예정.
 */
const CheckList = () => {
  return (
      <div className='checkListReWrap'>
        <main className='checkListCon'>
          <div className='wrap'>
            <div className='leftCon'>
              <h4 className='comment'>모바일 환경보단 PC에서 만드시는걸 추천드립니다.</h4>
              <div className='sbox'>
                <h3 className='tit'>AiApp을 알게된 경로가 있으신가요?</h3>
                <select>
                  <option value="">선택</option>
                  <option value="">인터넷 검색</option>
                  <option value="">뉴스</option>
                  <option value="">지인 추천</option>
                  <option value="">SNS</option>
                  <option value="">이메일 광고</option>
                  <option value="">세미나</option>
                  <option value="">기타</option>
                </select>
              </div>
              <div className='sbox'>
                <h3 className='tit'>앱 개발에 비용이 얼마 정도가 적정이라 생각하십니까?</h3>
                <select>
                  <option value="">선택</option>
                  <option value="">50~100만원 대</option>
                  <option value="">100~200만원 대</option>
                  <option value="">200~500만원 대</option>
                  <option value="">500~1000만원 대</option>
                  <option value="">1000~2000만원 대</option>
                  <option value="">2000만원 이상</option>
                  <option value="">잘 모르겠다</option>
                </select>
              </div>
              <div className='sbox'>
                <h3 className='tit'>개발 경험이 있으신가요?</h3>
                <div className='d-flex justify-content-start'>
                  <input type="radio" id="yes" name='develop'/><label htmlFor="yes">네</label>
                  <input type="radio" id="no" name='develop'/><label htmlFor="no">아니오</label>
                </div>
              </div>
            </div>
            <div className='rightCon'></div>
          </div>
        </main>
        <footer>
          <div className='btnWrap except'>
              {/* <button className='off'>이전</button> */}
              <Link to={"1"}><button>다음</button></Link>
          </div>
        </footer>
      </div>
  )}

  
export default CheckList